import React from "react";
import { Grid } from "@material-ui/core";
import RestaurantActions from "../../components/Widgets/RestaurantActions";
import UsersActions from "../../components/Widgets/UsersActions";
import { useGetRestaurantsMap } from "../../hooks/useGetRestaurants";
import { useGetUsersMap } from "../../hooks/useGetUsers";
import SharedPoolStats from "../../components/Widgets/SharedPoolStats";
import OverallFunnelsStats from "../../components/Widgets/OverallFunnelsStats";
import RollingMonthlyActiveUsers from "../../components/Widgets/ActiveUsersRestaurants";

function Kpis() {
    const userMap = useGetUsersMap();
    const restaurantMap = useGetRestaurantsMap(true);
    const [value, setValue] = React.useState(0);

    return (
        <div style={{ margin: 20 }}>
            <Grid container spacing={3}>
                <RollingMonthlyActiveUsers restaurants={Object.values(restaurantMap)} />
                <Grid item md={12} xs={12}>
                    <h1>Total stats:</h1>
                </Grid>
                <OverallFunnelsStats />
                <Grid item md={12} xs={12}>
                    <h1>Shared pool stats:</h1>
                </Grid>
                <SharedPoolStats />
            </Grid>
            <div style={{ margin: 40 }} />
            <Grid item md={12} xs={12}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                    }}>
                    <div
                        onClick={() => setValue(0)}
                        style={value === 0 ? styles.activeTab : styles.tab}>
                        Restaurants ({Object.keys(restaurantMap)?.length})
                    </div>
                    <div
                        onClick={() => setValue(1)}
                        style={value === 1 ? styles.activeTab : styles.tab}>
                        Users ({Object.keys(userMap)?.length})
                    </div>
                </div>
            </Grid>
            <Grid item md={12} xs={12}>
                <div style={value === 0 ? {} : { display: "none" }}>
                    <RestaurantActions restaurantMap={restaurantMap} />
                </div>
                <div style={value === 1 ? {} : { display: "none" }}>
                    <UsersActions userMap={userMap} />
                </div>
            </Grid>
        </div>
    );
}

export default Kpis;

const styles = {
    tab: {
        cursor: "pointer",
        padding: 10,
        display: "flex",
        width: "50%",
        justifyContent: "center",
    },
    activeTab: {
        cursor: "pointer",
        padding: 10,
        display: "flex",
        width: "50%",
        justifyContent: "center",
        borderBottom: "2px solid red",
    },
};
