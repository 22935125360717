import React from "react";
import LineChart from "./LineChart";
import {
    useGetMonthlyChatMessagesSentReport,
    useGetMonthlyChatMessagesReceivedReport,
} from "../../hooks/useGetApplicantsReport";

export default function ChatMessagesReport() {
    const { messagesReceived } = useGetMonthlyChatMessagesReceivedReport();
    const { loading, messagesSent } = useGetMonthlyChatMessagesSentReport();

    if (loading) return null;
    let messagesReceivedByMonth = {};
    messagesReceived &&
        messagesReceived.map(row => {
            messagesReceivedByMonth[`${row.year}/${row.month}`] = row;
        });
    let chartData = [["Month", "Total Messages", "Messages Sent", "Messages Received"]];
    let totalMessagesCount = 0,
        totalMessagesSent = 0,
        totalMessagesReceived = 0;
    messagesSent &&
        messagesSent.map(row => {
            const axisKey = `${row.year}/${row.month}`;
            totalMessagesSent = totalMessagesSent + row.totalMessages;
            const messagesSentCount = row.totalMessages || 0;
            const messagesReceivedCount =
                messagesReceivedByMonth[axisKey]?.totalMessages || 0;
            totalMessagesReceived = totalMessagesReceived + messagesReceivedCount;
            const totalMessages = messagesReceivedCount + messagesSentCount;
            totalMessagesCount = totalMessagesCount + totalMessages;
            chartData.push([
                axisKey,
                totalMessages,
                messagesSentCount,
                messagesReceivedCount,
            ]);
        });
    return (
        <LineChart
            data={chartData}
            title={`Total Messages Exchanged till date are ${totalMessagesCount}`}
            subtitle={`Messags exchanged are both sent/received`}
        />
    );
}
