import React from "react";
import CountUp from "react-countup";
import { Color } from "../../enums/Colors";
import { Grid } from "@material-ui/core";
import {
    useGetRejectedFunnelsByAppliedDate,
    useGetRejectedFunnelsLastMonth,
} from "../../hooks/useGetRejectedByAppliedDate";
import { useGetRejectedByAppliedDateTime } from "../../hooks/useGetRejectedByAppliedDateTime";
import { useGetSharedPoolIntroductions } from "../../hooks/useGetSharedPoolIntroductions";
import { useGetSharedPoolApplications } from "../../hooks/useGetApplications";
import KpiCard from "./KpiCard";

export default function SharedPoolStats() {
    const { loading, rejectedFunnels } = useGetRejectedFunnelsByAppliedDate();
    const { rejected } = useGetRejectedFunnelsLastMonth();
    const { averageInDays } = useGetRejectedByAppliedDateTime();
    const { sharedPoolIntroductions } = useGetSharedPoolIntroductions();
    const { sharedPoolApplications } = useGetSharedPoolApplications();
    const acceptedIntroductions = sharedPoolIntroductions.filter(
        introduction => introduction?.communicationState === "CONTACTED_ACCEPTED",
    );

    if (loading) {
        return (
            <>
                <Grid item md={3} xs={12}>
                    <KpiCard title="Loading..." color={Color.veryLightBorder} value={0} />
                </Grid>
                <Grid item md={3} xs={12}>
                    <KpiCard title="Loading..." color={Color.veryLightBorder} value={0} />
                </Grid>
                <Grid item md={3} xs={12}>
                    <KpiCard title="Loading..." color={Color.veryLightBorder} value={0} />
                </Grid>
                <Grid item md={3} xs={12}>
                    <KpiCard title="Loading..." color={Color.veryLightBorder} value={0} />
                </Grid>
            </>
        );
    }
    const contactRequestAcceptedPercentage =
        (acceptedIntroductions.length / sharedPoolIntroductions.length) * 100;
    const activeSharedPoolApplications = sharedPoolApplications.filter(
        application => application?.isActive === "true",
    );
    const rejectedPercentage = (rejectedFunnels / rejected) * 100;
    const sharedPoolConsentPercentage =
        (activeSharedPoolApplications.length / rejectedFunnels) * 100;

    return (
        <>
            <Grid item md={3} xs={12}>
                <KpiCard
                    title="Rejected Applicants"
                    footer="Applicants rejected in the last 30 days"
                    value={rejected}
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <KpiCard
                    title="Rejected Applicants"
                    color={Color.danger}
                    footer="Applicants rejected that were in system for less than 30 days"
                    value={rejectedFunnels}
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <KpiCard
                    title="Shared Pool Introductions"
                    color={Color.cucumber}
                    footer="Applicants contacted through the pool"
                    value={sharedPoolIntroductions.length || 0}
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <KpiCard
                    title="Shared Pool Applicants"
                    color={Color.vanilla}
                    footer="Applicants currently active in the shared pool"
                    value={activeSharedPoolApplications?.length || 0}
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <KpiCard
                    title="Average Rejection Time (days)"
                    footer="Average rejection time for applicants in last 30 days"
                    value={averageInDays || 0}
                    withPrecision
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <KpiCard
                    title="Rejected Applicants"
                    color={Color.danger}
                    footer="% Applicants rejected that were in system for less than 30 days"
                    value={rejectedPercentage}
                    percentageValue
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <KpiCard
                    title="Accepted Introductions"
                    color={Color.cucumber}
                    footer="% Applicants that accept contact requests"
                    value={contactRequestAcceptedPercentage || 0}
                    percentageValue
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <KpiCard
                    title="Shared Pool Consent"
                    color={Color.vanilla}
                    footer="% Applicants that consented for shared pool"
                    value={sharedPoolConsentPercentage || 0}
                    percentageValue
                />
            </Grid>
        </>
    );
}
