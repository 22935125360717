import { useEffect, useState } from "react";
import { firestore } from "../firebase/configure";
import keyBy from "lodash.keyby";
import { updateStorage } from "../helpers/localStorage";
import { FirestoreCollections } from "../enums/FirebaseCollections";
// import { ApplicationTypeValue } from "./useGetApplications";

/**
 * Retrieves all the funnels
 */
export function useGetAllFunnels() {
    const [funnels, setFunnels] = useState<any | null>([]);
    useEffect(() => {
        const unsubscribe = firestore.collection(FirestoreCollections.FUNNELS).onSnapshot(
            query => {
                if (!query.empty && query.docs.length > 0) {
                    const result = query.docs.map(doc => {
                        return {
                            id: doc.id,
                            ...doc.data(),
                        };
                    });
                    setFunnels(result);
                }
            },
            err => {
                console.log(err);
            },
        );
        return () => unsubscribe();
    }, []);
    return funnels;
}

// Ignore APPLIED/ REVIEWED/ REJECTED state funnels for now
export function useGetFunnels(startAt = new Date()) {
    const [funnels, setFunnels] = useState<any | null>([]);
    useEffect(() => {
        const unsubscribe = firestore
            .collection(FirestoreCollections.FUNNELS)
            .where("state", "in", [
                "INTERVIEW_PENDING",
                "INTERVIEW_CONFIRMATION",
                "INTERVIEWED",
                "TRIAL_PENDING",
                "TRIAL_CONFIRMATION",
                "TRIALED",
                "CONTRACT_PENDING",
                "HIRED",
            ])
            .orderBy("updateTime", "desc")
            .startAfter(startAt)
            .limit(100)
            .onSnapshot(
                query => {
                    if (!query.empty && query.docs.length > 0) {
                        const result = query.docs.map(doc => {
                            const {
                                userMeta,
                                createTime,
                                updateTime,
                                interviewerId,
                                restaurantId,
                                applicantId,
                                state,
                            } = doc.data();
                            return {
                                id: doc.id,
                                firstName: userMeta.firstName,
                                lastName: userMeta.lastName,
                                image: userMeta.image,
                                position: userMeta.position,
                                positionAdmin: userMeta.positionAdmin,
                                positionBoh: userMeta.positionBoh,
                                positionFoh: userMeta.positionFoh,
                                positionInternDuration: userMeta.positionInternDuration,
                                workHours: userMeta.workHours,
                                interviewerId,
                                applicantId,
                                state,
                                updateTime,
                                createTime,
                                restaurantId,
                            };
                        });
                        const funnelMap = keyBy(
                            [...funnels, ...result].map(({ id }) => ({
                                id,
                            })),
                            "id",
                        );
                        updateStorage("funnelMap", funnelMap);
                        setFunnels([...funnels, ...result]);
                    }
                },
                err => {
                    console.log(err);
                },
            );
        return () => unsubscribe();
    }, [startAt]);

    return funnels;
}

/**
 * Retrieves all funnels for a restaurant
 */
export function useGetFunnelsByRestaurantId(
    restaurantId: string | null,
): With.Id<Contracts.DBFunnelShape>[] {
    const [funnels, setFunnels] = useState<any | null>([]);
    useEffect(() => {
        if (!restaurantId) return () => {};
        const unsubscribe = firestore
            .collection(FirestoreCollections.FUNNELS)
            .where("restaurantId", "==", restaurantId)
            .orderBy("updateTime", "desc")
            .onSnapshot(
                query => {
                    if (!query.empty && query.docs.length > 0) {
                        const result = query.docs.map(doc => {
                            return {
                                id: doc.id,
                                ...doc.data(),
                            };
                        });
                        setFunnels(result);
                    }
                },
                err => {
                    console.log(err);
                },
            );
        return () => unsubscribe();
    }, [restaurantId]);
    return funnels;
}

/**
 * Retrieves all shared pool funnels for a restaurant
 */
export function useGetSharedPoolFunnelsByRestaurantId(
    restaurantId: string | null,
): With.Id<Contracts.DBFunnelShape>[] {
    const [funnels, setFunnels] = useState<any | null>([]);
    useEffect(() => {
        if (!restaurantId) return () => {};
        const unsubscribe = firestore
            .collection(FirestoreCollections.FUNNELS)
            .where("restaurantId", "==", restaurantId)
            .where("isOriginSharedPool", "==", true)
            .orderBy("updateTime", "desc")
            .onSnapshot(
                query => {
                    if (!query.empty && query.docs.length > 0) {
                        const result = query.docs.map(doc => {
                            return {
                                id: doc.id,
                                ...doc.data(),
                            };
                        });
                        setFunnels(result);
                    }
                },
                err => {
                    console.log(err);
                },
            );
        return () => unsubscribe();
    }, [restaurantId]);
    return funnels;
}
