import { useEffect, useState } from "react";
import { firestore } from "../firebase/configure";
import { FirestoreCollections } from "../enums/FirebaseCollections";
import { Positions } from "../types/Positions";

/**
 *
 * Added the firebase logic like this untill
 * we have a better way to abstract it with sagas for similar
 * Just to keep it outside of the component
 *
 */

export function usePositions(): {
    isLoading: boolean;
    positions: With.Id<Positions>[];
    allOptions: string[];
} {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [positions, setPositions] = useState<any | null>([]);

    useEffect(() => {
        let unsubscribe: any = null;
        unsubscribe = firestore.collection(FirestoreCollections.POSITIONS).onSnapshot(
            query => {
                !isLoading && setLoading(true);
                const result = query.docs.map(doc => {
                    return {
                        id: doc.id,
                        ...(doc.data() as Positions[]),
                    };
                });
                setPositions(result);
                setLoading(false);
            },
            err => {
                console.log(err);
                setLoading(false);
            },
        );

        return () => unsubscribe?.();
    }, []);
    let options: string[] = [];
    positions.forEach((i: { id: string }) => {
        Object.keys(i)
            .filter(key => key !== "id" && key !== "sharedPoolPosition")
            .forEach(key => {
                i[key].forEach((p: string) => {
                    options.push(p);
                });
            });
    });
    const sortedOptions = options.sort((a, b) => (a > b ? 1 : -1));

    return { isLoading, positions, allOptions: sortedOptions };
}
