import { keyBy } from "lodash";
import { useEffect, useState } from "react";
import { fetchData } from "../helpers/dataLoading";

export function useGetApplicationsByRestaurantReport(): any | {} {
    const [applicationsByRestaurant, setData] = useState({});
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("applicantsPrRestaurant");
        setData(keyBy(data, "restaurant_id"));
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, applicationsByRestaurant };
}
export function useGetMonthlyApplicationsReport(): any | {} {
    const [data, setData] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("monthlyApplicationsReport");
        setData(data);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, data };
}

export function useGetMonthlyRejectedApplicantsReport(): any | {} {
    const [data, setData] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("monthlyRejectedApplicationsReport");
        setData(data);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, data };
}

export function useGetMonthlyContactedApplicantsReport(): any | {} {
    const [data, setData] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("monthlyContactedApplicationsReport");
        setData(data);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, data };
}

export function useGetMonthlyChatMessagesSentReport(): any | {} {
    const [messagesSent, setData] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("monthlyChatMessagesSentReport");
        setData(data);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, messagesSent };
}

export function useGetMonthlyChatMessagesReceivedReport(): any | {} {
    const [messagesReceived, setData] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("monthlyChatMessagesReceivedReport");
        setData(data);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, messagesReceived };
}
