import * as firebase from "firebase";

import "firebase/firestore";
import config from "../config/annaformConfig";

const configuration = {
    apiKey: config.FIREBASE_APP_API_KEY,
    authDomain: config.FIREBASE_APP_AUTH_DOMAIN,
    databaseURL: config.FIREBASE_APP_DATABASE_URL,
    messagingSenderId: config.FIREBASE_APP_MESSAGING_SENDER_ID,
    projectId: config.FIREBASE_APP_PROJECT_ID,
    storageBucket: config.FIREBASE_APP_STORAGE_BUCKET,
};

const annaformFirebase = firebase.initializeApp(configuration, "annaform");

export const firestore = annaformFirebase.firestore();
