import {
    Button,
    createStyles,
    FormControl,
    FormControlLabel,
    FormLabel,
    makeStyles,
    Radio,
    RadioGroup,
    Slider,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { Color } from "../../enums/Colors";
import {
    RestaurantPriceLevel,
    RestaurantServiceStyle,
    RestaurantType,
} from "../../types/fixedTypes";

interface RestaurantLabelsProps {
    readonly labels?: Contracts.DBRestaurantLabels & { type: RestaurantType };
    /**
     * If the component should display only form controls (with type and save button hidden)
     */
    readonly onlyFormControls?: boolean;
    /**
     * Triggered if any value of the form controls changes
     */
    readonly onChanged?: (labels: Partial<Contracts.DBRestaurantLabels>) => void;
    /**
     * Triggered if all values of the form controls are filled
     */
    readonly onCompleted?: (labels: Contracts.DBRestaurantLabels) => void;
    /**
     * Triggered when a user presses on the save button with all form controls filled correctly
     */
    readonly onSave?: (labels: Contracts.DBRestaurantLabels) => void;
}

export default function RestaurantLabels(props: RestaurantLabelsProps) {
    const { labels, onlyFormControls, onChanged, onCompleted, onSave } = props;

    const classes = useStyles();

    const [serviceStyle, setServiceStyle] = useState<RestaurantServiceStyle | undefined>(
        labels?.serviceStyle,
    );

    const [priceLevel, setPriceLevel] = useState<RestaurantPriceLevel | undefined>(
        labels?.priceLevel,
    );

    const [qualityLevel, setQualityLevel] = useState<
        Contracts.DBRestaurantLabels["qualityLevel"]
    >(labels?.qualityLevel ?? 1);

    const result = useMemo(
        () => ({
            serviceStyle,
            priceLevel,
            qualityLevel,
        }),
        [serviceStyle, priceLevel, qualityLevel],
    );

    useEffect(() => {
        onChanged?.(result);

        if (result.serviceStyle && result.priceLevel && result.qualityLevel) {
            onCompleted?.(result as Contracts.DBRestaurantLabels);
        }
    }, [result]);

    return (
        <div style={{ margin: 16, display: "flex", flexDirection: "column" }}>
            {!onlyFormControls && (
                <>
                    <p>
                        <b>Type</b>: {labels?.type ?? "❓"}
                    </p>
                    {!labels && (
                        <p style={{ color: Color.danger }}>
                            This restaurant is not categorized. Assign labels to
                            categorize the restaurant.
                        </p>
                    )}
                    <div className={classes.divider} />
                </>
            )}

            <FormControl>
                <FormLabel>Service style</FormLabel>
                <RadioGroup
                    aria-label="service style"
                    name="serviceStyle"
                    value={serviceStyle}
                    onChange={e =>
                        setServiceStyle(e.target.value as RestaurantServiceStyle)
                    }>
                    {Object.keys(RestaurantServiceStyle).map(style => (
                        <FormControlLabel
                            key={`serviceStyle-${style}`}
                            value={style}
                            control={<Radio color={"primary"} />}
                            label={RestaurantServiceStyle[style]}
                        />
                    ))}
                </RadioGroup>
            </FormControl>

            <div className={classes.divider} />

            <FormControl>
                <FormLabel>Price level</FormLabel>
                <RadioGroup
                    aria-label="price level"
                    name="priveLevel"
                    value={priceLevel}
                    onChange={e => setPriceLevel(e.target.value as RestaurantPriceLevel)}>
                    {Object.keys(RestaurantPriceLevel).map(level => (
                        <FormControlLabel
                            key={`priceLevel-${level}`}
                            value={level}
                            control={<Radio color={"primary"} />}
                            label={RestaurantPriceLevel[level]}
                        />
                    ))}
                </RadioGroup>
            </FormControl>

            <div className={classes.divider} />

            <FormControl>
                <FormLabel>Quality level</FormLabel>
                <Slider
                    min={1}
                    max={5}
                    defaultValue={qualityLevel}
                    onChange={(_, level) =>
                        setQualityLevel(
                            level as Contracts.DBRestaurantLabels["qualityLevel"],
                        )
                    }
                    step={1}
                    valueLabelDisplay="auto"
                    marks={qualityLevelMarks}
                    className={classes.slider}
                />
            </FormControl>

            {!onlyFormControls && (
                <Button
                    size="small"
                    variant="contained"
                    color={"primary"}
                    className={classes.button}
                    disabled={!(serviceStyle && priceLevel && qualityLevel)}
                    onClick={() => onSave?.(result as Contracts.DBRestaurantLabels)}>
                    Save
                </Button>
            )}
        </div>
    );
}

const qualityLevelMarks = [...Array(5).keys()].map(q => ({ value: q + 1, label: q + 1 }));

const useStyles = makeStyles(() =>
    createStyles({
        divider: {
            margin: 8,
        },
        slider: {
            width: "90%",
            margin: "0 auto",
        },
        button: {
            marginTop: 48,
        },
    }),
);
