import React from "react";
import { useGetUserApplicantSubscribed } from "../../hooks/useGetUserApplicantSubscribed";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";

export default function Applicants({ userId }) {
    const { applicants } = useGetUserApplicantSubscribed({ userId });
    return (
        <div>
            <div>
                Applicants taken action on <b>{applicants.length}</b>
            </div>
            {applicants?.map(({ funnel_id }) => (
                <ListItem button key={funnel_id}>
                    <Link to={`/applicants/${funnel_id}`}>
                        <ListItemText primary={funnel_id} />
                    </Link>
                </ListItem>
            ))}
        </div>
    );
}
