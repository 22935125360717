import React from "react";
import { useGetMonthlyActiveSessions } from "../../hooks/useGetActiveSessionsReport";
import LineChart from "./LineChart";

export default function ActiveSessionsReport() {
    const { loading, data } = useGetMonthlyActiveSessions();

    if (loading) return null;
    let chartData = [["Month", "Anna has been opened"]];
    let maxActiveSessions = 0;
    let maxActiveSessionsDate = "";
    data &&
        data.map(row => {
            chartData.push([`${row.year}/${row.month}`, row.activeSessions]);
            if (row.activeSessions > maxActiveSessions) {
                maxActiveSessions = row.activeSessions;
                maxActiveSessionsDate = `${row.year}/${row.month}`;
            }
        });
    return (
        <LineChart
            data={chartData}
            title={`Highest active sessions are ${maxActiveSessions} on month ${maxActiveSessionsDate}`}
            subtitle="Session is defined as App opened and then closed"
        />
    );
}
