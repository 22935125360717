export enum Color {
    white = "#fff",
    primary = "#4c9999",
    cucumber = "#0b7375",
    secondary = "rgb(76,153,153)",
    backgroundPrimary = "#d5e5ff",
    background = "#f2f2f2",
    border = "#f2f2f2",
    body = "#4f4f4f",
    muted = "#c1c1c1",
    light = "#ffffff",
    tabIconDefault = "#ccc",
    danger = "#ee6464",
    // OldSchool
    veryLightBorder = "rgba(102, 102, 102, 0.05)",
    systemMessageBg = "rgba(237, 237, 237, 0.2)",

    vanilla = "#839090",
    baseSalt = "#FFFFFF",
    baseSeaSalt = "#EDEDED",
    basePepper = "#242828",
    // basePepperAlpha = (alpha: number) => `rgba(36,40,40,${alpha})`,
    primaryAnanas = "#FFB432",
    secondaryCucumber = "#0B7375",
    secondaryMint = "#deecec",
    secondaryMintAlpha = "rgba(222,236,236, 0.2)",
    secondaryBasil = "#4c9999",
    secondaryCream = "#F8FBFB",
}
