import React, { useState } from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import {
    fade,
    makeStyles,
    withStyles,
    Theme,
    createStyles,
} from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem, { TreeItemProps } from "@material-ui/lab/TreeItem";
import Collapse from "@material-ui/core/Collapse";
import { useSpring, animated } from "react-spring/web.cjs"; // web.cjs is required for IE 11 support
import { TransitionProps } from "@material-ui/core/transitions";
import Button from "@material-ui/core/Button";
import FormDialog from "./Dialog";
import { Intl } from "../../enums/Intl";
import { supportedLanguages } from "../../helpers/languages";
import Alias from "./AliasButton";
import AddCircleOutlineTwoToneIcon from "@material-ui/icons/AddCircleOutlineTwoTone";

function MinusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function CloseSquare(props: SvgIconProps) {
    return (
        <SvgIcon
            className="close"
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}

function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
        from: { opacity: 0, transform: "translate3d(20px,0,0)" },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

const StyledTreeItem = withStyles((theme: Theme) =>
    createStyles({
        iconContainer: {
            "& .close": {
                opacity: 0.3,
            },
        },
        group: {
            marginLeft: 7,
            paddingLeft: 18,
            borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
        },
    }),
)((props: TreeItemProps) => (
    <TreeItem {...props} TransitionComponent={TransitionComponent} />
));

const useStyles = makeStyles(
    createStyles({
        root: {
            height: 264,
            flexGrow: 1,
            maxWidth: 400,
        },
    }),
);

export enum ModalType {
    ADD_POSITION = "ADD_POSITION",
    ADD_LANGUAGE = "ADD_LANGUAGE",
    ADD_ALIAS = "ADD_ALIAS",
    EDIT_ALIAS = "EDIT_ALIAS",
}

function sortAlphabetically(a: string, b: string) {
    return a > b ? 1 : -1;
}
type Language = { [key in Intl]: string[] };

interface Position extends Language {
    id: string;
}

export default function PositionsTree({ positions }: any) {
    const classes = useStyles();
    const [modal, setModal] = useState<{ type: ModalType | null; context: any }>({
        type: null,
        context: null,
    });
    const handleOpenAddPosition = () => {
        setModal({ type: ModalType.ADD_POSITION, context: null });
    };
    const handleOpenAddLanguage = (positionId: string, languages: Intl[]) => {
        setModal({ type: ModalType.ADD_LANGUAGE, context: { positionId, languages } });
    };

    const handleOpenAddAlias = (
        positionId: string,
        language: Intl,
        languages: Intl[],
    ) => {
        setModal({
            type: ModalType.ADD_ALIAS,
            context: { positionId, language, languages },
        });
    };
    const handleClose = () => {
        setModal({ type: null, context: null });
    };
    return (
        <>
            <TreeView
                className={classes.root}
                defaultExpanded={["1"]}
                defaultCollapseIcon={<MinusSquare />}
                defaultExpandIcon={<PlusSquare />}
                defaultEndIcon={<CloseSquare />}>
                {positions
                    .sort((a: Position, b: Position) => sortAlphabetically(a.id, b.id))
                    .map((position: Position) => {
                        const languages = Object.keys(position).filter(
                            key => key !== "id" && key !== "sharedPoolPosition",
                        );
                        return (
                            <StyledTreeItem nodeId={position.id} label={position.id}>
                                {languages.sort(sortAlphabetically).map(key => (
                                    <StyledTreeItem
                                        nodeId={`${position.id}-${key}`}
                                        label={key}>
                                        {position[key].map((alias: string) => (
                                            <Alias
                                                alias={alias}
                                                positionId={position.id}
                                                language={key as Intl}
                                                aliases={position[key]}
                                            />
                                        ))}
                                        <Button
                                            variant="outlined"
                                            onClick={() =>
                                                handleOpenAddAlias(
                                                    position.id,
                                                    key as Intl,
                                                    position[key],
                                                )
                                            }>
                                            Add alias
                                        </Button>
                                    </StyledTreeItem>
                                ))}
                                {supportedLanguages.filter(l => !languages.includes(l.id))
                                    .length > 0 && (
                                    <Button
                                        variant="outlined"
                                        onClick={() =>
                                            handleOpenAddLanguage(
                                                position.id,
                                                languages as Intl[],
                                            )
                                        }>
                                        Add language
                                    </Button>
                                )}
                            </StyledTreeItem>
                        );
                    })}
                <Button variant="outlined" onClick={handleOpenAddPosition}>
                    Add position id
                </Button>
            </TreeView>
            <FormDialog modal={modal} handleClose={handleClose} />
        </>
    );
}
