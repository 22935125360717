import { useEffect, useState } from "react";
import { fetchData } from "../helpers/dataLoading";

export function useGetRejectedApplicationsReport(): any | {} {
    const [rejectedByRestaurant, setData] = useState<{}>({});
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("rejectionsWithReasonPrRestaurant");
        if (data) {
            let rejectedReport = {};
            data.map(row => {
                rejectedReport[row.restaurant_id] = {
                    rejectedMeta: {
                        [row.meta_data_reject_reason || "N/A"]: row.applicants,
                        ...(rejectedReport[row.restaurant_id]?.rejectedMeta || {}),
                    },
                    count:
                        (rejectedReport[row.restaurant_id]?.count || 0) + row.applicants,
                };
            });
            setData(rejectedReport);
        } else {
            setData({});
        }
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, rejectedByRestaurant };
}
