import React, { useState, useEffect } from "react";
import { useGetSharedPoolFunnelsByRestaurantId } from "../../hooks/useGetFunnels";
import { auth } from "../../firebase/configure";
import SignInScreen from "../SignIn2";
import Card from "../Card";
import { FormControl, TextField } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core";
import ActivityFeed from "./ActivityFeed";
import { useGetRestaurants } from "../../hooks/useGetRestaurants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { keyBy } from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        margin: 0,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function SharedPoolChatContainer() {
    const [isAuth, setisAuth] = useState(false);
    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                setisAuth(true);
            } else {
                setisAuth(false);
            }
        });
        return () => {};
    }, []);
    if (!isAuth) {
        return <SignInScreen />;
    }
    return <SharedPoolChats />;
}

function SharedPoolChats() {
    const classes = useStyles();
    const restaurants = useGetRestaurants();
    const restaurantMap = keyBy(restaurants, "id");
    const [
        selectedRestaurant,
        setSelectedRestaurant,
    ] = useState<Contracts.DBRestaurantShape | null>(null);
    const funnels = useGetSharedPoolFunnelsByRestaurantId(selectedRestaurant?.id || null);
    const [selectedFunnel, setSelectedFunnel] = useState<any>({});

    const handleChange = (_: any, newValue: Contracts.DBRestaurantShape | null) => {
        setSelectedRestaurant(newValue);
    };
    const userFiltered = restaurants
        .filter(r => r.id !== selectedRestaurant?.id)
        .filter(restaurant => restaurant.title);

    return (
        <div>
            <div style={{ display: "flex" }}>
                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <Autocomplete
                        id="restaurants-select"
                        options={userFiltered}
                        value={selectedRestaurant}
                        getOptionLabel={(option: any) => option.title}
                        onChange={handleChange}
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                label={"Pick restaurant"}
                                variant="outlined"
                            />
                        )}
                    />
                </FormControl>
            </div>

            {selectedRestaurant && (
                <div style={{ display: "flex" }}>
                    <div style={{ flexDirection: "row", width: "25%" }}>
                        <div style={{ height: "90vh", overflowY: "scroll" }}>
                            {funnels &&
                                funnels.map((funnel: any) => {
                                    const restaurantTitle =
                                        restaurantMap[funnel.restaurantId] &&
                                        restaurantMap[funnel.restaurantId].title;
                                    return (
                                        <div style={{ position: "relative" }}>
                                            <Card
                                                key={funnel.id}
                                                firstName={funnel.userMeta?.firstName}
                                                lastName={funnel.userMeta?.lastName}
                                                position={funnel.userMeta?.position}
                                                restaurantTitle={restaurantTitle}
                                                image={funnel.userMeta?.image}
                                                updateTime={funnel.updateTime}
                                                state={funnel.state}
                                                applicantId={funnel.applicantId}
                                                interviewerId={funnel.interviewerId}
                                                onClick={() => {
                                                    setSelectedFunnel(funnel);
                                                }}
                                                isSelected={
                                                    selectedFunnel.id === funnel.id
                                                }
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    <div
                        style={{
                            flexGrow: 1,
                            width: "70%",
                            maxHeight: "100vh",
                            display: "flex",
                        }}>
                        {selectedFunnel && selectedFunnel.id ? (
                            <ActivityFeed
                                funnelId={selectedFunnel.id}
                                funnel={selectedFunnel}
                                showTimeline={false}
                                setTaggedFunnelMessages={() => {}} // TODO do we need this?
                            />
                        ) : (
                            <div
                                style={{
                                    fontSize: 60,
                                    fontWeight: "bold",
                                    color: "#d8d8d8",
                                }}>
                                Pick a chat!
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
