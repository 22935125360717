import { useEffect, useState } from "react";
import { fetchData } from "../helpers/dataLoading";

async function getApplications() {
    return await fetchData("applicantsPrRestaurant");
}
async function getHires() {
    return await fetchData("hiresPrRestaurant");
}
async function getInterviewInvites() {
    return await fetchData("interviewInvitesPrRestaurant");
}
async function getInterviewsScheduled() {
    return await fetchData("interviewsScheduledPrRestaurant");
}
async function getMessagesReceived() {
    return await fetchData("messagesReceivedPrRestaurant");
}
async function getMessagesSent() {
    return await fetchData("messagesSentPrRestaurant");
}
async function getRejections() {
    return await fetchData("rejectionsPrRestaurant");
}
async function getTrialInvites() {
    return await fetchData("trialInvitesPrRestaurant");
}
async function getTrialsScheduled() {
    return await fetchData("trialsScheduledPrRestaurant");
}

export function useGetRestauntStats(): any | {} {
    const [stats, setStates] = useState<{}>({});
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const [
            applications,
            hires,
            interviewInvites,
            interviewsScheduled,
            messagesReceived,
            messagesSent,
            rejections,
            trialInvites,
            trialsScheduled,
        ] = await Promise.all([
            getApplications(),
            getHires(),
            getInterviewInvites(),
            getInterviewsScheduled(),
            getMessagesReceived(),
            getMessagesSent(),
            getRejections(),
            getTrialInvites(),
            getTrialsScheduled(),
        ]);
        console.log({ applications });

        setStates({
            applications,
            hires,
            interviewInvites,
            interviewsScheduled,
            messagesReceived,
            messagesSent,
            rejections,
            trialInvites,
            trialsScheduled,
        });

        setLoading(false);
    };
    useEffect(() => {
        if (loading) {
            loadData();
        }
        return () => {};
    }, []);

    return { loading, stats };
}
