import {
    RestaurantPriceLevel,
    RestaurantServiceStyle,
    RestaurantType,
} from "../types/fixedTypes";

const restaurantTypeForLabels = {
    [RestaurantServiceStyle.Table]: {
        [RestaurantPriceLevel.High]: RestaurantType.D,
        [RestaurantPriceLevel.Medium]: RestaurantType.B,
        [RestaurantPriceLevel.Low]: RestaurantType.C,
    },
    [RestaurantServiceStyle.Counter]: {
        [RestaurantPriceLevel.High]: RestaurantType.A,
        [RestaurantPriceLevel.Medium]: RestaurantType.C,
        [RestaurantPriceLevel.Low]: RestaurantType.C,
    },
    [RestaurantServiceStyle.Both]: {
        [RestaurantPriceLevel.High]: RestaurantType.D,
        [RestaurantPriceLevel.Medium]: RestaurantType.B,
        [RestaurantPriceLevel.Low]: RestaurantType.C,
    },
};

export function getRestaurantTypeForLabels(labels: Contracts.DBRestaurantLabels) {
    try {
        const { serviceStyle, priceLevel } = labels;
        return restaurantTypeForLabels[serviceStyle][priceLevel];
    } catch (error) {
        console.error(`Failed to get restaurant type for labels ${labels}. ${error}`);
        return RestaurantType.Unknown;
    }
}
