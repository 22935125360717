import { RESTAURANT_TYPES } from "./enums/RestaurantTypes";
type restaurants = {
    [key: string]: any;
};
export const restaurantMap: restaurants = {
    n7SenJuuI7eIRwpK0Ip3: {
        title: "California Kitchen",
        type: RESTAURANT_TYPES.FAST_FOOD,
        quality: 4,
        restaurantId: "n7SenJuuI7eIRwpK0Ip3",
    },
    ZbDCkOqAxxlxhKa34BC9: {
        title: "Bar' Vin",
        type: RESTAURANT_TYPES.FINE_DINING,
        quality: 4,
        restaurantId: "ZbDCkOqAxxlxhKa34BC9",
    },
    dPn5NYdP5OMFTWo2WQiB: {
        title: "Slurp",
        type: RESTAURANT_TYPES.FAST_CASUAL,
        quality: 5,
        restaurantId: "dPn5NYdP5OMFTWo2WQiB",
    },
    pzGFVverggF3V4c6eeGC: {
        title: "Gasoline Grill",
        type: RESTAURANT_TYPES.FAST_FOOD,
        quality: 4,
        restaurantId: "pzGFVverggF3V4c6eeGC",
    },
    "0QYnhGxiPVKBDMmkms3j": {
        title: "Amass",
        type: RESTAURANT_TYPES.FINE_DINING,
        quality: 5,
        restaurantId: "0QYnhGxiPVKBDMmkms3j",
    },
    "4FKwDGNZTulHuA7vioWn": {
        title: "Mielcke & Hurtigkarl",
        type: RESTAURANT_TYPES.FINE_DINING,
        quality: 5,
        restaurantId: "4FKwDGNZTulHuA7vioWn",
    },
    "6De2GHNcUUBQlpKvVQrN": {
        title: "La Banchina",
        type: RESTAURANT_TYPES.FAST_CASUAL,
        quality: 4,
        restaurantId: "6De2GHNcUUBQlpKvVQrN",
    },
    Wb8DeQ2rnjDbX0Hwd44V: {
        title: "Broaden & Build",
        type: RESTAURANT_TYPES.BAR_FOOD,
        quality: 5,
        restaurantId: "Wb8DeQ2rnjDbX0Hwd44V",
    },
    Sp5v9gARBR82YXeJNqRE: {
        title: "Iluka",
        type: RESTAURANT_TYPES.FINE_DINING,
        quality: 5,
        restaurantId: "Sp5v9gARBR82YXeJNqRE",
    },
    hWDZaMB3JaKPL0NmbZYc: {
        title: "Hart Bageri",
        type: RESTAURANT_TYPES.BAKERY,
        quality: 4,
        restaurantId: "hWDZaMB3JaKPL0NmbZYc",
    },
};
