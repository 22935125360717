import React from "react";
import { Typography, Button, Box } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import LogicField from "./LogicField";

function Editor({ selectedNode, allFields, onFieldUpdate }) {
    const { data: initialData, logic: initialLogic } = selectedNode;

    const { control, handleSubmit } = useForm({
        defaultValues: {
            logic: initialLogic || { type: "field", ref: initialData.ref, actions: [] },
        },
    });

    const onSubmit = formData => onFieldUpdate(initialData.ref, formData);
    const onError = (errors, e) => console.log(errors, e);

    const submitForm = () => {
        handleSubmit(onSubmit, onError)();
    };

    return (
        <div
            onClick={e => e.stopPropagation()}
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: 16,
            }}>
            <Typography variant="h5" gutterBottom>
                Edit {initialData?.ref} Logic
            </Typography>

            <Box mt={4} mb={4} width="100%">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    Logic
                </Typography>

                <LogicField
                    control={control}
                    allFields={allFields}
                    initialFieldData={initialData}
                />
            </Box>

            <Button variant="contained" color="primary" onClick={submitForm}>
                UPDATE
            </Button>
        </div>
    );
}

export default Editor;
