import React, { useState, useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { auth } from "../../firebase/configure";
import SignInScreen from "../../components/SignIn2";
import { useGetSharedPoolIntroductions } from "../../hooks/useGetSharedPoolIntroductions";
import { Color } from "../../enums/Colors";
import EditRestaurantConfig from "../../components/Restaurants/EditRestaurantConfig";
import { Check } from "@material-ui/icons";
import { User } from "../../types/User";
import UsersList from "../../components/Users/UsersList";
import { ExcludedUsers } from "../../config/filter";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            padding: 10,
        },
        gridRoot: {
            flexGrow: 1,
        },
        cardRoot: {
            minWidth: 275,
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
        badgeRoot: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        heading: {
            fontSize: theme.typography.pxToRem(20),
            fontWeight: theme.typography.fontWeightBold,
        },
        table: {
            minWidth: 300,
        },
        containerItem: {
            display: "inline-block",
            "vertical-align": "top",
        },
    }),
);

export default function SharedTalentPoolOverview({
    restaurants,
    applicationsByRestaurant,
    rejectedByRestaurant,
    sharedPoolApplications,
    users,
}) {
    const [isAuth, setisAuth] = useState(false);
    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                setisAuth(true);
            } else {
                setisAuth(false);
            }
        });
        return () => {};
    }, []);
    if (!isAuth) {
        return <SignInScreen />;
    }
    return (
        <TalentPool
            restaurants={restaurants}
            applicationsByRestaurant={applicationsByRestaurant}
            rejectedByRestaurant={rejectedByRestaurant}
            sharedPoolApplications={sharedPoolApplications}
            users={users}
        />
    );
}

function Row({
    //@ts-ignore
    classes,
    index,
    restaurant,
    metrics,
    users,
}) {
    const [expanded, setExpanded] = useState<boolean>(false);
    const isSharedPoolEnabled = restaurant?.config?.sharedPoolEnabled;
    const isActive = restaurant?.isActive;
    return (
        <TableRow
            style={{
                backgroundColor: isActive ? Color.baseSeaSalt : Color.light,
            }}
            key={restaurant.id}>
            <TableCell component="th" scope="row">
                {index}
            </TableCell>
            <TableCell component="th" scope="row">
                {restaurant.title} {restaurant?.groupId ? "(G)" : ""}
                {restaurant?.isActive && <Check />}
            </TableCell>
            <TableCell component="th" scope="row">
                <UsersList restaurant={restaurant} users={users} />
            </TableCell>
            <TableCell component="th" scope="row">
                <span>
                    {!expanded &&
                        (restaurant?.groupName ||
                            restaurant?.categories?.join(",") ||
                            "Unassigned")}
                </span>
            </TableCell>
            <TableCell component="th" scope="row">
                {metrics?.activeSharedPoolApplications?.length || 0}
            </TableCell>
            <TableCell component="th" scope="row">
                {metrics?.sharedPoolIntroductions?.length || 0}
            </TableCell>
            <TableCell
                style={{
                    backgroundColor: isSharedPoolEnabled
                        ? Color.secondaryBasil
                        : isActive
                        ? Color.baseSeaSalt
                        : Color.light,
                }}
                component="th"
                scope="row">
                {isSharedPoolEnabled ? "Yes" : "No"}
            </TableCell>
            <TableCell align="right">
                <EditRestaurantConfig restaurant={restaurant} />
            </TableCell>
        </TableRow>
    );
}

function TalentPool({
    restaurants,
    applicationsByRestaurant,
    rejectedByRestaurant,
    sharedPoolApplications,
    users,
}: {
    restaurants: Contracts.DBRestaurantShape[];
    applicationsByRestaurant: any;
    rejectedByRestaurant: any;
    sharedPoolApplications: Contracts.DBSharedPoolApplication[];
    users: {
        [userId: string]: User;
    };
}) {
    const classes = useStyles();
    const { sharedPoolIntroductions } = useGetSharedPoolIntroductions();
    let metricsByRestaurant = {};
    restaurants
        .sort((x, y) => (x?.title > y?.title ? 1 : -1))
        .map(restaurant => {
            metricsByRestaurant[restaurant.id] = {
                restaurant,
                rejectedApplications: rejectedByRestaurant[restaurant.id],
                applications: applicationsByRestaurant[restaurant.id],
                sharedPoolApplications: [],
                activeSharedPoolApplications: [],
                sharedPoolIntroductions: [],
            };
        });
    const activeRestaurants = restaurants.filter(restaurant => restaurant.isActive);
    let activeUsers: string[] = [];
    activeRestaurants.map(restaurant => {
        activeUsers = [
            ...activeUsers,
            ...Object.keys(restaurant?.members).filter(
                userId => !ExcludedUsers.includes(userId),
            ),
        ];
    });
    activeUsers = [...new Set(activeUsers)];
    sharedPoolApplications.map(application => {
        application?.restaurantIds?.map(restaurantId => {
            const strippedRestaurantId = restaurantId.replaceAll('"', "");
            metricsByRestaurant[strippedRestaurantId]?.sharedPoolApplications.push(
                application,
            );
            if (application?.isActive === "true")
                metricsByRestaurant[
                    strippedRestaurantId
                ]?.activeSharedPoolApplications.push(application);
        });
    });
    sharedPoolIntroductions.map(introduction => {
        metricsByRestaurant[introduction.restaurantId]?.sharedPoolIntroductions.push(
            introduction,
        );
    });
    return (
        <div className={classes.root}>
            <Paper>
                <h4>Shared Talent Pool Overview</h4>
                <h5>{`${restaurants?.length || 0} Total Restaurants`}</h5>
                <h5>{`${activeRestaurants?.length || 0} Active Restaurants`}</h5>
                <h5>
                    <div className={classes.containerItem}>
                        {`${activeUsers?.length || 0} Active Users (Excluding Anna team)`}{" "}
                    </div>
                    <div className={classes.containerItem}>
                        <UsersList members={activeUsers} users={users} />
                    </div>
                </h5>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Restaurant (G - Group)</TableCell>
                            <TableCell>Users</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Active Applications</TableCell>
                            <TableCell>Applicants Contacted</TableCell>
                            <TableCell>Shared Pool Enabled</TableCell>
                            <TableCell align="right">Update</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {restaurants.map(
                            (restaurant: Contracts.DBRestaurantShape, index: number) => {
                                return (
                                    <Row
                                        classes={classes}
                                        index={index + 1}
                                        restaurant={restaurant}
                                        metrics={metricsByRestaurant[restaurant.id]}
                                        users={users}
                                    />
                                );
                            },
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    );
}
