import React from "react";
import { useGetMonthlyContactedApplicantsReport } from "../../hooks/useGetApplicantsReport";
import LineChart from "./LineChart";

export default function ContactedApplicantsReport() {
    const { loading, data } = useGetMonthlyContactedApplicantsReport();

    if (loading) return null;
    let chartData = [["Month", "Contacted Applicants"]];
    let total = 0;
    data &&
        data.map(row => {
            total = total + row.applicants;
            chartData.push([`${row.year}/${row.month}`, row.applicants]);
        });
    return (
        <LineChart
            data={chartData}
            title={`Total Applicants Contacted till date are ${total}`}
            subtitle="Contacted means user has sent either a message/interview request/hired"
        />
    );
}
