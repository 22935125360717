import { useEffect, useState } from "react";
import { fetchData } from "../helpers/dataLoading";

export function useGetUniqueApplicantsByRestaurant(): any | {} {
    const [uniqueApplicants, setApplicants] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("uniqueApplicantsByRestaurant");
        setApplicants(data);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, uniqueApplicants };
}

export function useGetDuplicateApplicantsByRestaurant(): any | {} {
    const [duplicateApplicants, setApplicants] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("duplicateApplicantsByRestaurant");
        setApplicants(data);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, duplicateApplicants };
}
