import React from "react";
import Button from "@material-ui/core/Button";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { firestore } from "../../firebase/configure";
import { FirestoreCollections } from "../../enums/FirebaseCollections";

export default function Alias({ alias, positionId, language, aliases }) {
    const handleDeleteAlias = async () => {
        const result = window.confirm(`Are you sure you want to delete: ${alias}`);
        if (result) {
            await firestore
                .collection(FirestoreCollections.POSITIONS)
                .doc(positionId)
                .set(
                    { [language]: aliases.filter((a: string) => a !== alias) },
                    { merge: true },
                );
        }
    };
    return (
        <div>
            {alias}
            <Button onClick={handleDeleteAlias}>
                <DeleteForeverIcon style={{ color: "red" }} />
            </Button>
        </div>
    );
}
