import { useEffect, useState } from "react";
import { fetchData } from "../helpers/dataLoading";

export function useGetApplicantJourney({ funnelId }: any): any | {} {
    console.log(funnelId);
    const [events, setApplicants] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const loadData = async () => {
        const data = await fetchData("applicantJourney", {
            funnelId,
        });
        console.log({ useGetApplicantJourney: data });

        setApplicants(
            data?.map(({ event, original_timestamp }) => ({
                event,
                timestamp: original_timestamp.value,
            })),
        );
        setLoading(false);
    };
    useEffect(() => {
        if (!funnelId) return;
        loadData();
        return () => {};
    }, []);

    return { loading, events };
}
