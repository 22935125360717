import React from "react";
import { Drawer } from "@material-ui/core";
import ReactFlow, {
    MiniMap,
    Controls,
    Background,
    isNode,
    Position,
    Node,
} from "react-flow-renderer";
import dagre from "dagre";
import * as uuid from "uuid";

import formJson from "./formJson";
import BasicNode from "./BasicNode";
import FieldDataEditor from "../FieldDataEditor";
import FieldLogicEditor from "../FieldLogicEditor";
import { FieldActionType, FieldRef, FieldType } from "./fixedTypes";

const CELL_HEIGHT = 50;
const CELL_WIDTH = 200;
const WELCOME_NODE = "welcome-node";

const formatField = (fieldConfig, formConfig) => ({
    id: fieldConfig.id || uuid.v4(),
    data: {
        node: fieldConfig,
        logic: formConfig.logic.find(l => l.ref === fieldConfig.ref),
    },
    position: { x: 0, y: 0 },
    type: "basic",
    style: {
        height: CELL_HEIGHT,
        width: CELL_WIDTH,
        padding: 0,
    },
    draggable: false,
    connectable: false,
});

const formatEdge = (from, to) => ({
    id: `${from}-${to}`,
    source: from,
    target: to,
    // type: "smoothstep",
    arrowHeadType: "arrowclosed",
    weight: 999,
    // animated: true,
});

const getFieldsAndEdges = formConfig => {
    const fields = formConfig.fields.map(field => formatField(field, formConfig));
    const edges = formConfig.fields.reduce((acc, field, fieldIndex) => {
        const fieldLogic = formConfig.logic.find(logicObj => logicObj.ref === field.ref);
        const jumpActions = (fieldLogic?.actions || []).filter(
            actionObj =>
                actionObj.action === FieldActionType.jump &&
                actionObj.details.to.type === "field",
        );

        if (jumpActions.length) {
            return [
                ...acc,
                ...jumpActions.map(action => ({
                    ...formatEdge(
                        field.id,
                        formConfig.fields.find(
                            field => field.ref === action.details.to.value,
                        ).id,
                    ),
                    weight: action.condition.op === "always" ? 999 : 1,
                    condition: action.condition.op,
                })),
            ];
        }

        const nextField = formConfig.fields[fieldIndex + 1];

        if (nextField) {
            return [...acc, formatEdge(field.id, nextField.id)];
        }

        return acc;
    }, []);

    const uniqueEdges = Object.values(
        edges.reduce((acc, edge, edgeIndex) => ({ ...acc, [edge.id]: edgeIndex }), {}),
    ).map(edgeIndex => edges[edgeIndex as number]);

    return [...fields, ...uniqueEdges];
};

const onLoad = reactFlowInstance => {
    console.log("flow loaded:", reactFlowInstance);
    reactFlowInstance.fitView();
};

const getLayoutedElements = elements => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    dagreGraph.setGraph({ rankdir: "TB" });

    elements.forEach(el => {
        if (isNode(el)) {
            dagreGraph.setNode(el.id, { width: CELL_WIDTH, height: CELL_HEIGHT });
        } else {
            dagreGraph.setEdge(el.source, el.target);
        }
    });

    dagre.layout(dagreGraph);

    return elements.map(el => {
        if (isNode(el)) {
            const nodeWithPosition = dagreGraph.node(el.id);
            el.targetPosition = Position.Top;
            el.sourcePosition = Position.Bottom;

            // unfortunately we need this little hack to pass a slighltiy different position
            // to notify react flow about the change. More over we are shifting the dagre node position
            // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
            el.position = {
                x: nodeWithPosition.x - CELL_WIDTH / 2 + Math.random() / 1000,
                y: nodeWithPosition.y - CELL_HEIGHT / 2,
            };
        }

        return el;
    });
};

function updateConditionRefs(condition, fromRef, toRef) {
    if (condition.op === "always") {
        return condition;
    }

    if (condition.op === "is" && condition.vars.length === 2) {
        condition.vars = condition.vars.reduce((vars, v) => {
            if (v.type === "field" && v.value === fromRef) {
                if (toRef === undefined) {
                    return vars;
                }
                v.value = toRef;
            }

            return [...vars, v];
        }, []);

        return condition;
    }

    if (condition.op === "and" && condition.vars.length) {
        return condition.vars.map(c => updateConditionRefs(c, fromRef, toRef));
    }

    if (condition.op === "or" && condition.vars.length) {
        return condition.vars.map(c => updateConditionRefs(c, fromRef, toRef));
    }

    return condition;
}

function updateLogicRefs(logic, fromRef, toRef) {
    return logic.map(fieldLogic => ({
        ...fieldLogic,
        ref: fieldLogic.ref === fromRef ? toRef : fieldLogic.ref,
        actions: fieldLogic.actions.reduce((actions, actionObj) => {
            if (
                actionObj.details.to.type === "field" &&
                actionObj.details.to.value === fromRef
            ) {
                if (toRef === undefined) {
                    return actions;
                }
                actionObj.details.to.value = toRef;
            }

            actionObj.condition = updateConditionRefs(
                actionObj.condition,
                fromRef,
                toRef,
            );

            return [...actions, actionObj];
        }, []),
    }));
}

function checkIfStaticNode(node) {
    return node?.id === WELCOME_NODE;
}

function FormEditor({ formConfig = formJson, updateFunc }) {
    const [selectedNode, setSelectedNode] = React.useState<any>(null);
    const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>();
    const [isLogicEditorOpen, setIsLogicEditorOpen] = React.useState<boolean>(false);

    const [formData, setFormData] = React.useState<any>({
        welcomeNode: {
            id: WELCOME_NODE,
            ref: WELCOME_NODE,
            title:
                //@ts-ignore
                formConfig?.welcome_screen?.title ||
                "Hey and Welcome. Looking forward to your application!",
            //@ts-ignore
            description: formConfig?.welcome_screen?.description,
            //@ts-ignore
            logo: formConfig.welcome_screen?.logo || formConfig.logo,
            type: "welcome",
        },
        fields: formConfig.fields,
        logic: formConfig.logic,
    });
    const { welcomeNode, fields, logic } = formData;

    const layoutedElements = React.useMemo(() => {
        let elements = getFieldsAndEdges({ fields, logic });

        const welcomeNodeField = formatField(welcomeNode, formConfig);
        const welcomeEdge = formatEdge(WELCOME_NODE, elements[0].id);
        elements = [welcomeNodeField, welcomeEdge, ...elements];

        return getLayoutedElements(elements);
    }, [formData]);

    const addNode = fromNode => {
        setFormData(prevData => {
            const newFieldId = uuid.v1();
            const newField = {
                id: newFieldId,
                ref: newFieldId as FieldRef,
                type: FieldType.shortText,
                title: "",
                validations: {
                    required: false,
                },
                properties: {},
            };

            const prevFields = prevData.fields;
            const fromNodeIndex = prevFields.findIndex(f => f.id === fromNode.id);

            const nextFields = [
                ...prevFields.slice(0, fromNodeIndex + 1),
                newField,
                ...prevFields.slice(fromNodeIndex + 1),
            ];

            let nextLogic = prevData.logic;
            const fromNodeLogic = nextLogic.find(logic => logic.ref === fromNode.ref);

            if (fromNodeLogic) {
                const fromNodeDefaultNextField = fromNodeLogic.actions.find(
                    l => l.condition.op === "always",
                ).details.to.value;
                nextLogic = [
                    ...nextLogic,
                    {
                        type: "field",
                        ref: newFieldId,
                        actions: [
                            {
                                action: "jump",
                                details: {
                                    to: {
                                        type: "field",
                                        value: fromNodeDefaultNextField,
                                    },
                                },
                                condition: {
                                    op: "always",
                                    vars: [],
                                },
                            },
                        ],
                    },
                ];
            }

            return {
                ...prevData,
                fields: nextFields,
                logic: nextLogic,
            };
        });
    };

    const deleteNode = fromNode => {
        setFormData(prevData => {
            const nextFields = prevData.fields.filter(f => f.ref !== fromNode.ref);

            let nextLogic = prevData.logic.filter(l => l.ref !== fromNode.ref);
            const fromNodeLogic = prevData.logic.find(
                logic => logic.ref === fromNode.ref,
            );

            const fieldIndex = prevData.fields.findIndex(f => f.ref === fromNode.ref);
            let nextFieldRef = prevData.fields[fieldIndex + 1]?.ref;

            if (fromNodeLogic) {
                nextFieldRef = fromNodeLogic.actions.find(
                    l => l.condition.op === "always",
                )?.details?.to?.value;
            }
            nextLogic = updateLogicRefs(nextLogic, fromNode.ref, nextFieldRef);

            // is not last question
            if (nextFieldRef) {
                //give logic to previous field if there is none to avoid issues
                const previousNonLogicNode = layoutedElements
                    .filter(el => el.target === fromNode.id)
                    .map(edge => prevData.fields.find(f => f.id === edge.source))
                    .filter(
                        node =>
                            node &&
                            prevData.logic.findIndex(l => l.ref === node.ref) === -1,
                    )[0];

                if (previousNonLogicNode) {
                    nextLogic = [
                        ...nextLogic,
                        {
                            type: "field",
                            ref: previousNonLogicNode.ref,
                            actions: [
                                {
                                    action: "jump",
                                    details: {
                                        to: {
                                            type: "field",
                                            value: nextFieldRef,
                                        },
                                    },
                                    condition: {
                                        op: "always",
                                        vars: [],
                                    },
                                },
                            ],
                        },
                    ];
                }
            }

            return {
                ...prevData,
                fields: nextFields,
                logic: nextLogic,
            };
        });
    };

    const updateWelcomeNode = newData => {
        setFormData({
            ...formData,
            welcomeNode: {
                ...welcomeNode,
                ...newData,
            },
        });
    };

    const updateNode = (ref, newData) => {
        if (ref === WELCOME_NODE) {
            updateWelcomeNode(newData);
            setIsEditorOpen(false);
            return;
        }
        const newFieldData = newData;

        const fieldsCopy = [...fields];
        const updatedFieldIndex = fieldsCopy.findIndex(f => f.ref === ref);

        fieldsCopy[updatedFieldIndex] = {
            ...fieldsCopy[updatedFieldIndex],
            ...newFieldData,
        };

        if (ref !== newFieldData.ref) {
            setFormData({
                ...formData,
                fields: fieldsCopy,
                logic: updateLogicRefs(logic, ref, newFieldData.ref),
            });
        } else {
            setFormData({
                ...formData,
                fields: fieldsCopy,
            });
        }

        setIsEditorOpen(false);
    };

    const updateNodeLogic = (ref, newData) => {
        const { logic: newLogic } = newData;

        const nextLogic = [...logic];

        const logicIndex = nextLogic.findIndex(l => l.ref === ref);

        if (logicIndex !== -1) {
            nextLogic[logicIndex] = newLogic;
        } else {
            nextLogic.push(newLogic);
        }

        setFormData({
            ...formData,
            logic: nextLogic,
        });

        setIsLogicEditorOpen(false);
    };

    const selectNode = (_, node) => {
        const nodeData = node.data.node;
        setSelectedNode({ id: node.id, data: nodeData });
        setIsEditorOpen(true);
    };

    const handleLogicClick = (nodeData, nodeLogic) => {
        setSelectedNode({ data: nodeData, logic: nodeLogic });
        setIsLogicEditorOpen(true);
    };

    const publishForm = () => {
        updateFunc(
            {
                ...formConfig,
                fields,
                logic,
                welcome_screen: {
                    title: welcomeNode.title,
                    logo: welcomeNode.logo,
                    description: welcomeNode.description,
                },
            },
            () => {
                alert("success");
            },
            () => {
                alert("failed");
            },
        );
    };

    return (
        <div
            style={{
                position: "relative",
                width: "100%",
                height: "calc(100vh - 64px)",
                overflow: "hidden",
            }}>
            <ReactFlow
                nodeTypes={{
                    basic: params => (
                        <BasicNode
                            {...params}
                            isStaticNode={checkIfStaticNode(params)}
                            onAddClick={addNode}
                            onDeleteClick={deleteNode}
                            onLogicClick={handleLogicClick}
                        />
                    ),
                }}
                elements={layoutedElements}
                onLoad={onLoad}
                snapToGrid={true}
                snapGrid={[15, 15]}
                onElementClick={selectNode}>
                <MiniMap nodeColor={"lightgray"} />

                <Controls />

                <Background color="#aaa" gap={16} />
            </ReactFlow>

            <Drawer
                PaperProps={{ style: { width: 500 } }}
                anchor="left"
                open={isLogicEditorOpen}
                onClose={() => setIsLogicEditorOpen(false)}>
                <FieldLogicEditor
                    selectedNode={selectedNode}
                    allFields={fields}
                    onFieldUpdate={updateNodeLogic}
                />
            </Drawer>

            <Drawer
                PaperProps={{ style: { width: 500 } }}
                anchor="right"
                open={isEditorOpen}
                onClose={() => setIsEditorOpen(false)}>
                <FieldDataEditor
                    selectedNode={selectedNode}
                    isStaticNode={checkIfStaticNode(selectedNode)}
                    onFieldUpdate={updateNode}
                />
            </Drawer>

            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    position: "absolute",
                    top: 16,
                    left: 16,
                    right: 16,
                    height: 40,
                    zIndex: 10,
                }}>
                <h3 style={{ margin: 0, fontWeight: "bold" }}>{formConfig.title}</h3>

                <div style={{ flex: 1 }} />

                <a
                    href={`https://annaform-staging.web.app/${formConfig.id
                        .split("_")
                        .join("/")}`}
                    target="_blank"
                    style={{
                        padding: "4px 16px",
                        backgroundColor: "white",
                        color: "rgb(38, 38, 39)",
                        borderRadius: 8,
                        border: "1px solid rgb(38, 38, 39)",
                        marginRight: 16,
                    }}>
                    Open in annaform
                </a>

                <button
                    style={{
                        height: "100%",
                        padding: "4px 16px",
                        backgroundColor: "rgb(38, 38, 39)",
                        color: "white",
                        borderRadius: 8,
                        border: "none",
                    }}
                    onClick={() => publishForm()}>
                    Publish
                </button>
            </div>
        </div>
    );
}

export default FormEditor;
