import { useEffect, useState } from "react";
import { firestore } from "../firebase/configure";
import { FirestoreCollections } from "../enums/FirebaseCollections";
import { Positions } from "../types/Positions";

/**
 *
 * Added the firebase logic like this untill
 * we have a better way to abstract it with sagas for similar
 * Just to keep it outside of the component
 *
 */

export function useRestaurantPositions(
    restaurantId: string,
): {
    isLoading: boolean;
    restaurantPositions: With.Id<Positions>[];
    updatePositions: (positions: Positions[]) => void;
} {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [restaurantPositions, setRestaurantPositions] = useState<any | null>([]);
    const updatePositions = (positions: Positions[]) => {
        return firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .doc(restaurantId)
            .collection(FirestoreCollections.RESTAURANT_POSITIONS)
            .add({
                createTime: new Date(),
                list: positions,
            });
    };
    useEffect(() => {
        let unsubscribe: any = null;
        if (restaurantId) {
            unsubscribe = firestore
                .collection(FirestoreCollections.RESTAURANTS)
                .doc(restaurantId)
                .collection(FirestoreCollections.RESTAURANT_POSITIONS)
                .orderBy("createTime", "desc")
                .limit(1)
                .onSnapshot(
                    query => {
                        !isLoading && setLoading(true);
                        const [result] = query.docs.map(doc => {
                            return {
                                id: doc.id,
                                ...(doc.data() as { list: Positions[] }),
                            };
                        });
                        setRestaurantPositions(result.list);
                        setLoading(false);
                    },
                    err => {
                        console.log(err);
                        setLoading(false);
                    },
                );
        }
        return () => unsubscribe?.();
    }, []);

    return { isLoading, restaurantPositions, updatePositions };
}
