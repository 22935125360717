import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default function HowToTypeformHiring() {
    const classes = useStyles();

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography className={classes.heading}>
                    How do I setup the typeform webhook for hiring?
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    1.{" "}
                    <a
                        href="https://admin.typeform.com/accounts/01D8JWV7XRJSSBCKYSDBWB3RY1/workspaces/8YpMpM"
                        target="_blank">
                        Go to typeform (you might to sign in and then follow the link)
                    </a>
                    <br />
                    2. Anna - v.01
                    <br />
                    3. Click on the form you are setting up
                    <br />
                    4. Go to the "Connect" tab
                    <br />
                    5. Click add "WEBHOOKS" tab
                    <br />
                    6. Click add webhook (it should be the only option when it is not
                    already setup)
                    <br />
                    7. Take this url: (paste it in)
                    https://europe-west1-helloanna-ai.cloudfunctions.net/api/webhooks/typeform/hire
                    <br />
                    8. Click the "switch" to turn the webook on.
                    <br />
                    9. Done
                    <br />
                    10. Get the url to the form in the top "SHARE" tab
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    }),
);
