import React from "react";
import { useGetMonthlyActiveUsersReport } from "../../hooks/useGetActiveUsersReport";
import LineChart from "./LineChart";

export default function ActiveUsersReportMonthly() {
    const { loading, data } = useGetMonthlyActiveUsersReport();

    if (loading) return null;
    let chartData = [["Day", "Monthly Active Users"]];
    let maxActiveUsers = 0;
    let maxActiveUsersDate = "";
    data.map(row => {
        chartData.push([`${row.year}/${row.month}`, row.activeUsers]);
        if (row.activeUsers > maxActiveUsers) {
            maxActiveUsers = row.activeUsers;
            maxActiveUsersDate = `${row.year}/${row.month}`;
        }
    });
    return (
        <LineChart
            data={chartData}
            title={`Active users per month`}
            subtitle={`Highest users per month is ${maxActiveUsers} on ${maxActiveUsersDate}`}
        />
    );
}
