import { useEffect, useState } from "react";
import { FirestoreCollections } from "../enums/FirebaseCollections";
import { firestore } from "../firebase/configure";
import { fetchData } from "../helpers/dataLoading";

/**
 * Retrieves all applications for a restaurant
 */
export function useGetApplicationsByRestaurantIds(
    restaurantIds: string[],
    appliedDateAfter: Date,
): { loading: boolean; data: Contracts.DBUserViewedApplication[] } {
    const [data, setData] = useState<Contracts.DBUserViewedApplication[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        if (restaurantIds.length > 0 && appliedDateAfter) {
            const data = await fetchData("sharedPoolApplicationsByRestaurantIds", {
                restaurantIds,
                appliedDateAfter,
            });
            setData(data);
        }
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, [restaurantIds, appliedDateAfter]);

    return { loading, data };
}

export function useGetSharedPoolApplication(
    sharedPoolApplicationId: string,
): { loading: boolean; sharedPoolApplication: Contracts.DBSharedPoolApplication } {
    const [sharedPoolApplication, setData] = useState<any | null>({});
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        const unsubscribe = firestore
            .collection(FirestoreCollections.SHARED_POOL_APPLICATIONS)
            .doc(sharedPoolApplicationId)
            .onSnapshot(
                doc => {
                    const result = {
                        id: doc.id,
                        ...doc.data(),
                    };
                    setData(result);
                    setLoading(false);
                },
                err => {
                    console.log(err);
                },
            );
        return () => unsubscribe();
    }, []);

    return { loading, sharedPoolApplication };
}
