import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
    ListItemSecondaryAction,
    Popover,
    ListItemAvatar,
    Avatar,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import ChooseRestaurant from "./ChooseRestaurant";
import { updateSharedPoolApplicationRestaurants } from "../../firebase/firestore";
import { keyBy } from "lodash";
import { useGetSharedPoolApplication } from "../../hooks/useGetSharedPoolApplications";

interface Props {
    sharedPoolApplicationId: string;
    restaurants: Contracts.DBRestaurantShape[];
    setUpdatedRestaurantIds: (restaurantIds) => void;
}

function Item({
    id,
    title,
    logo,
    removeRestaurant,
}: {
    id: string;
    title: string;
    logo: string;
    removeRestaurant: (id: string) => void;
}) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const popId = open ? `simple-popover-${id}` : undefined;
    return (
        <ListItem button>
            <ListItemAvatar>
                <Avatar alt="Remy Sharp" src={logo} />
            </ListItemAvatar>
            <ListItemText primary={title} />
            <ListItemSecondaryAction>
                <Button aria-describedby={popId} onClick={handleClick}>
                    <DeleteIcon />
                </Button>
            </ListItemSecondaryAction>
            <Popover
                id={popId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}>
                <div style={{ margin: 20 }}>
                    <p>Are you sure you want to remove {title}?</p>
                    <Button
                        aria-describedby={id}
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                            removeRestaurant(id);
                            handleClose();
                        }}>
                        Yes
                    </Button>
                </div>
            </Popover>
        </ListItem>
    );
}

export default function EditSharedPoolApplicant({
    sharedPoolApplicationId,
    restaurants,
    setUpdatedRestaurantIds,
}: Props) {
    const classes = useStyles();
    const { loading, sharedPoolApplication } = useGetSharedPoolApplication(
        sharedPoolApplicationId,
    );
    if (loading) return <>Loading...</>;
    const restaurantsById = keyBy(restaurants, "id");
    const applicantRestaurants = sharedPoolApplication?.restaurantIds?.map(
        restaurantId => restaurantsById[restaurantId],
    );

    const removeRestaurant = async (restaurantId: string) => {
        const filteredRestaurants = applicantRestaurants.filter(
            restaurant => restaurant.id !== restaurantId,
        );
        const restaurantIds = filteredRestaurants.map(restaurant => restaurant.id);
        const done = await updateSharedPoolApplicationRestaurants({
            sharedPoolApplicationId,
            restaurantIds,
        });
        if (!done) return alert("Failed to remove restaurant for shared pool applicant");
        else setUpdatedRestaurantIds(restaurantIds);
    };

    const addRestaurant = async (restaurantId: string): Promise<boolean> => {
        let restaurantIds = applicantRestaurants.map(restaurant => restaurant.id);
        restaurantIds.push(restaurantId);
        applicantRestaurants.push(restaurantsById[restaurantId]);
        const done = await updateSharedPoolApplicationRestaurants({
            sharedPoolApplicationId,
            restaurantIds,
        });
        if (!done) alert("Failed to add restaurant for shared pool applicant");
        else setUpdatedRestaurantIds(restaurantIds);
        return Boolean(done);
    };
    return (
        <div>
            <List component="nav" aria-label="secondary mailbox folders">
                <ChooseRestaurant
                    label={`Add restaurant for applicant`}
                    restaurants={restaurants}
                    filter={applicantRestaurants.map(({ id }) => id)}
                    onSelectRestaurant={addRestaurant}
                />

                {applicantRestaurants?.length === 0 && <div>No restaurants</div>}
                {applicantRestaurants.map(({ id, title, logo }) => {
                    return (
                        <Item
                            key={id}
                            id={id}
                            title={title}
                            logo={logo}
                            removeRestaurant={() => removeRestaurant(id)}
                        />
                    );
                })}
            </List>
        </div>
    );
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            margin: 20,
            // maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
    }),
);
