import React from "react";
import { useGetMonthlyApplicationsReport } from "../../hooks/useGetApplicantsReport";
import LineChart from "./LineChart";

export default function ApplicantsReport() {
    const { loading, data } = useGetMonthlyApplicationsReport();

    if (loading) return null;
    let chartData = [["Month", "Applicants"]];
    let total = 0;
    data &&
        data.map(row => {
            total = total + row.applicants;
            chartData.push([`${row.year}/${row.month}`, row.applicants]);
        });
    return (
        <LineChart
            data={chartData}
            title={`Total Applicants till date are ${total}`}
            subtitle="Grouped by Month"
        />
    );
}
