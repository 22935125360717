import { useEffect, useState } from "react";
import { firestore } from "../firebase/configure";
import keyBy from "lodash.keyby";
import { FirestoreCollections } from "../enums/FirebaseCollections";
import { User } from "../types/User";

/**
 *
 * Added the firebase logic like this untill
 * we have a better way to abstract it with sagas for similar
 * Just to keep it outside of the component
 *
 */

export function useGetUsers(): User[] {
    const [users, setUsers] = useState<User[]>([]);
    useEffect(() => {
        const unsubscribe = firestore
            .collection(FirestoreCollections.USERS)
            .orderBy("name", "asc")
            .onSnapshot(
                query => {
                    const result = query.docs.map(doc => {
                        return {
                            id: doc.id,
                            ...doc.data(),
                        } as User;
                    });
                    setUsers(result);
                },
                err => {
                    console.log(err);
                },
            );
        return () => unsubscribe();
    }, []);

    return users;
}

export function useGetUsersMap() {
    const [users, setUsers] = useState<{ [userId: string]: User }>({});
    useEffect(() => {
        const unsubscribe = firestore
            .collection(FirestoreCollections.USERS)
            .orderBy("name", "asc")
            .onSnapshot(
                query => {
                    if (!query.empty && query.docs.length > 0) {
                        const result = query.docs.map(doc => {
                            return {
                                id: doc.id,
                                ...doc.data(),
                            } as User;
                        });
                        const userMap = keyBy(result, "id");
                        setUsers(userMap);
                    }
                },
                err => {
                    console.log(err);
                },
            );
        return () => unsubscribe();
    }, []);
    return users;
}
