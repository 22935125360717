export enum FirestoreCollections {
    ACTIVITY = "activity",
    ANALYTICS = "analytics",
    APPLICATIONS = "applications",
    RESTAURANTS = "restaurants",
    RESTAURANT_GROUPS = "restaurantGroups",
    SHARED_POOL_APPLICATIONS = "sharedPoolApplications",
    FUNNELS = "funnels",
    USERS = "users",
    RESTAURANT_POSITIONS = "positions",
    RESTAURANT_TRIAL_PREFERENECE = "trialPreferences",
    USER_PREFERENECE = "userPreferences",
    POSITIONS = "positions",
}
