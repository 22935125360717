import React, { useState, useEffect } from "react";
// @ts-ignore
import {
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Divider,
    Tooltip,
    Button,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AnnaHand from "../../assets/anna.jpg";
import { formatDistanceToNow, format } from "date-fns";

// @ts-ignore
import Highlightable from "highlightable";

export default function Message({
    id,
    createTime,
    type,
    authorMetadata,
    funnel,
    funnelId,
    message,
    setTaggedFunnelMessages,
}: any) {
    const date = createTime && createTime.toDate();
    const funnelTagId = funnelId + ":" + id; // TODO Define delimiters in config
    const isAnna = type !== "USER_MESSAGE" || authorMetadata.source === "SYSTEM";
    const isApplicant = authorMetadata.source === "EXTERNAL" ? funnel : "";
    const primary = `${message} ${
        authorMetadata.displayName ? " - (" + authorMetadata.displayName + ")" : ""
    }`;
    const [ranges, setRanges] = useState([] as any);
    const [labels, setLabels] = useState([] as any);
    // const onMouseOverHighlightedWordCallback = (range: any) => {
    //   console.log({ onMouseOverHighlightedWordCallback: range });
    // };
    const onTextHighlightedCallback = (range: any) => {
        console.log({ onTextHighlightedCallback: range });
        setRanges([...ranges, { ...range }]);
    };

    useEffect(() => {
        if (labels.length > 0) setTaggedFunnelMessages(funnelTagId, labels);
    }, [labels]);

    const saveLabelledText = (label: string, range: any) => {
        range["label"] = label;
        console.log("Saving", label, range);
        const newLabels = [...labels, { ...range }];
        setLabels(newLabels);
        // setTaggedFunnelMessages( (prevState:any) => ({
        //   ...prevState,
        //   [funnelTagId] : newLabels,
        // }));
        // setTaggedFunnelMessages(funnelTagId, newLabels);
    };

    const resetHighlight = (range: any) => {
        console.log("resetHightlight");
        setRanges(
            ranges.filter((r: any) => range.start !== r.start && range.end !== r.end),
        );
        setLabels(
            labels.filter((r: any) => range.start !== r.start && range.end !== r.end),
        );
        // console.log("Saved labels", labels);
    };
    const tooltipRenderer = (lettersNode: any, range: any, rangeIndex: any) => {
        const actions = (
            <div>
                <Button
                    variant="contained"
                    onClick={() => saveLabelledText("TRIAL_REQUEST", range)}>
                    Trial Request
                </Button>
                <Button
                    variant="contained"
                    onClick={() => saveLabelledText("TRIAL_CONFIRMATION", range)}>
                    Trial Confirmation
                </Button>
                <Button
                    variant="contained"
                    onClick={() => saveLabelledText("INTERVIEW_REQUEST", range)}>
                    Interview Request
                </Button>
                <Button
                    variant="contained"
                    onClick={() => saveLabelledText("INTERVIEW_CONFIRMATION", range)}>
                    Interview Confirmation
                </Button>
                <Button variant="contained" onClick={() => resetHighlight(range)}>
                    Remove Label
                </Button>
            </div>
        );
        return (
            <Tooltip
                key={`${range.data.id}-${rangeIndex}`}
                title={actions}
                placement="top"
                interactive>
                <span>{lettersNode}</span>
            </Tooltip>
        );
    };
    const customRenderer = (
        currentRenderedNodes: any,
        currentRenderedRange: any,
        currentRenderedIndex: any,
    ) => {
        return tooltipRenderer(
            currentRenderedNodes,
            currentRenderedRange,
            currentRenderedIndex,
        );
    };

    const highlight = (
        <Highlightable
            ranges={ranges}
            enabled={true}
            onTextHighlighted={onTextHighlightedCallback}
            id={funnelId}
            // onMouseOverHighlightedWord={onMouseOverHighlightedWordCallback}
            highlightStyle={{
                backgroundColor: "#ffcc80",
            }}
            rangeRenderer={
                customRenderer
                //   letterGroup.map((Node: any) => (
                //   <Node onMouserEnter={onMouseOverHighlightedWord} />
                // ));
            }
            text={primary}
        />
    );

    // console.log("Saved labels", labels);
    return (
        <>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Tooltip key={id} title={id} placement="top" interactive>
                        <Avatar
                            alt="Remy Sharp"
                            src={
                                isAnna
                                    ? AnnaHand
                                    : isApplicant.image || authorMetadata.image
                            }
                        />
                    </Tooltip>
                </ListItemAvatar>
                <ListItemText
                    primary={<div>{highlight}</div>}
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                // className={classes.inline}
                                color="textPrimary">
                                {isAnna
                                    ? "Anna"
                                    : isApplicant.firstName || authorMetadata.displayName}
                            </Typography>
                            {" - "}
                            {formatDistanceToNow(date, { addSuffix: true })}{" "}
                            {format(date, "- (HH:mm)")}
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
        </>
    );
}
