import { List } from "@material-ui/core";
import React, { useMemo } from "react";
import { MemberItem } from "../../components/RestaurantCard";
import { sortUsersByName } from "../../helpers/sorting";
import { useGetUsersMap } from "../../hooks/useGetUsers";

export default function RestaurantMembers(restaurant: Contracts.DBRestaurantShape) {
    const userMap = useGetUsersMap();

    const members = useMemo(
        () =>
            Object.keys(restaurant.members)
                .map(member => userMap[member])
                .sort(sortUsersByName),
        [restaurant, userMap],
    );

    return (
        <List>
            {members.map((member, index) => (
                <MemberItem key={index} {...member} />
            ))}
        </List>
    );
}
