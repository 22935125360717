import "react-mosaic-component/react-mosaic-component.css";

import React from "react";
import { Mosaic, MosaicWindow } from "react-mosaic-component";
import { useHistory } from "react-router-dom";

import { AppBar, Avatar, Breadcrumbs, Grid, Tooltip } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import { Desktop, Mobile } from "../helpers/Responsive";
import Applicants from "./Widgets/Applicants";
import UserActions from "./Widgets/UserActions";
import UserDetails from "./Widgets/UserDetails";
import UserDevices from "./Widgets/UserDevices";
import UserResponsibilities from "./Widgets/UserResponsibilities";
import UserRestaurantsWidget from "./Widgets/UserRestaurants";

export type ViewId = "a" | "b" | "c" | "new";

export default function UserProfile({
    id,
    name,
    email,
    isAccountSetup,
    phoneNumber,
    profileImage,
    notificationToken,
    timeZone,
}: any) {
    const history = useHistory();
    const ELEMENT_MAP: { [viewId: string]: JSX.Element } = {
        details: (
            <UserDetails
                id={id}
                name={name}
                isAccountSetup={isAccountSetup}
                email={email}
                phoneNumber={phoneNumber}
                notificationToken={notificationToken}
                timeZone={timeZone}
            />
        ),
        devices: <UserDevices userId={id} />,
        responsibilities: <UserResponsibilities userId={id} />,
        actions: <UserActions userId={id} />,
        applicants: <Applicants userId={id} />,
        restaurants: <UserRestaurantsWidget userId={id} />,
    };
    return (
        <>
            <AppBar
                position="static"
                style={{ padding: 10, backgroundColor: "#4c9999", boxShadow: "none" }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        style={{ cursor: "pointer", color: "#f4f4f5" }}
                        onClick={() => history.goBack()}>
                        Overview
                    </Link>
                    <Typography
                        style={{
                            color: "#fff",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}>
                        <Avatar aria-label="recipe" src={profileImage}>
                            U
                        </Avatar>{" "}
                        <h4 style={{ margin: 0, marginLeft: 10 }}>
                            {name || email || phoneNumber}{" "}
                        </h4>
                        <div style={{ marginLeft: 10 }}>
                            <Tooltip
                                title={
                                    isAccountSetup
                                        ? "Account is setup"
                                        : "Account not yet setup"
                                }>
                                {isAccountSetup ? <CheckIcon /> : <CloseIcon />}
                            </Tooltip>
                        </div>
                    </Typography>
                </Breadcrumbs>
                <Desktop>
                    <div style={{ position: "absolute", right: 20 }}>User Id: {id}</div>
                </Desktop>
            </AppBar>
            <Desktop>
                <div id="userGrid">
                    <Mosaic<string>
                        renderTile={(id, path) => (
                            <MosaicWindow<ViewId> path={path} title={id}>
                                {ELEMENT_MAP[id]}
                            </MosaicWindow>
                        )}
                        initialValue={{
                            direction: "row",
                            first: {
                                direction: "column",
                                first: {
                                    direction: "column",
                                    first: "details",
                                    second: "devices",
                                },
                                second: "restaurants",
                            },
                            second: {
                                direction: "column",
                                first: {
                                    direction: "column",
                                    first: "actions",
                                    second: "applicants",
                                },
                                second: "responsibilities",
                            },
                            splitPercentage: 40,
                        }}
                    />
                </div>
            </Desktop>
            <Mobile>
                <div id="userGrid">
                    <div style={{ margin: 20 }} />
                    <Grid container item xs={12}>
                        <Card style={{ width: "100%", overflow: "scroll" }}>
                            <CardContent>{ELEMENT_MAP.details}</CardContent>
                        </Card>
                    </Grid>
                    <div style={{ margin: 20 }} />
                    <Grid container item xs={12}>
                        <Card style={{ width: "100%", overflow: "scroll" }}>
                            <CardContent>{ELEMENT_MAP.devices}</CardContent>
                        </Card>
                    </Grid>
                    <div style={{ margin: 20 }} />
                    <Grid container item xs={12}>
                        <Card style={{ width: "100%", overflow: "scroll" }}>
                            <CardContent>{ELEMENT_MAP.responsibilities}</CardContent>
                        </Card>
                    </Grid>
                    <div style={{ margin: 20 }} />
                    <Grid container item xs={12}>
                        <Card style={{ width: "100%", overflow: "scroll" }}>
                            {ELEMENT_MAP.restaurants}
                        </Card>
                    </Grid>
                    <div style={{ margin: 20 }} />
                    <Grid container item xs={12}>
                        <Card style={{ width: "100%", overflow: "scroll" }}>
                            <CardContent>{ELEMENT_MAP.actions}</CardContent>
                        </Card>
                    </Grid>
                    <div style={{ margin: 20 }} />
                </div>
            </Mobile>
        </>
    );
}
