import { format } from "date-fns";
import { enUS } from "date-fns/locale";

export const locale = { locale: enUS };
export function parsed(date: Date | string) {
    return typeof date === "string" ? new Date(date) : date;
}

export function minutesToHoursAndMinutes(totalMinutes: number) {
    const hours = totalMinutes / 60;
    const resultHours = Math.floor(hours);
    const derivedMinutes = (hours - resultHours) * 60;
    const resultMinutes = Math.round(derivedMinutes);
    return `${resultHours
        .toString()
        .padStart(2, "0")}:${resultMinutes.toString().padStart(2, "0")}`;
}

export function dateToTime(date: Date) {
    return format(date, "HH:mm", locale);
}

export function shortLocalDateFormat(date: Date) {
    return format(parsed(date), "do 'of' LLLL", locale);
}

export function mediumLocalDateFormat(date: Date | string) {
    return format(parsed(date), "iiii, d LLLL", locale);
}

export function mediumLocalDateAndTimeFormat(date: Date) {
    return format(date, "LLLL do',' HH:mm", locale);
}

export function longLocalDateFormat(date: Date) {
    return format(parsed(date), "cccc do MMMM yyyy", locale);
}
