import { useEffect, useState } from "react";
import { fetchData } from "../helpers/dataLoading";

export function useGetUserApplicantSubscribed({ userId }): any | {} {
    console.log(userId);

    const [applicants, setApplicants] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        try {
            const data = await fetchData("userApplicantSubscribed", {
                userId,
            });
            console.log({ data });

            setApplicants(data);
        } catch (error) {
            console.log({ error });
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, applicants };
}
