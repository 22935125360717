import React, { useState, useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { auth } from "../../firebase/configure";
import SignInScreen from "../../components/SignIn2";
import { useGetSharedPoolIntroductions } from "../../hooks/useGetSharedPoolIntroductions";
import { ExcludedUsers } from "../../config/filter";
import ViewRestaurantIntroductions from "../../components/SharedTalentPool/ViewRestaurantIntroductions";
import { TableContainer } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            padding: 10,
        },
        gridRoot: {
            flexGrow: 1,
        },
        cardRoot: {
            minWidth: 275,
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
        badgeRoot: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        heading: {
            fontSize: theme.typography.pxToRem(20),
            fontWeight: theme.typography.fontWeightBold,
        },
        table: {
            minWidth: 300,
        },
        containerItem: {
            display: "inline-block",
            "vertical-align": "top",
        },
    }),
);

export default function SharedPoolIntroductionsOverview({ restaurants }) {
    const [isAuth, setisAuth] = useState(false);
    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                setisAuth(true);
            } else {
                setisAuth(false);
            }
        });
        return () => {};
    }, []);
    if (!isAuth) {
        return <SignInScreen />;
    }
    return <SharedPoolIntroductions restaurants={restaurants} />;
}

function Row({
    //@ts-ignore
    classes,
    index,
    restaurant,
    metrics,
}) {
    const [expanded, setExpanded] = useState<boolean>(false);
    let acceptedRequests: Contracts.DBSharedPoolIntroduction[] = [],
        declinedRequests: Contracts.DBSharedPoolIntroduction[] = [],
        pendingRequests: Contracts.DBSharedPoolIntroduction[] = [],
        noResponseRequests: Contracts.DBSharedPoolIntroduction[] = [];
    metrics?.sharedPoolIntroductions?.map(
        (introduction: Contracts.DBSharedPoolIntroduction) => {
            if (introduction.communicationState === "CONTACTED_PENDING") {
                if (introduction?.isActive === "true") pendingRequests.push(introduction);
                else noResponseRequests.push(introduction);
            } else if (introduction.communicationState === "CONTACTED_DECLINED") {
                declinedRequests.push(introduction);
            } else if (introduction.communicationState === "CONTACTED_ACCEPTED") {
                acceptedRequests.push(introduction);
            }
        },
    );
    return (
        <TableRow key={restaurant.id}>
            <TableCell component="th" scope="row">
                {index}
            </TableCell>
            <TableCell component="th" scope="row">
                {restaurant.title}
            </TableCell>
            <TableCell component="th" scope="row">
                <ViewRestaurantIntroductions
                    restaurant={restaurant}
                    sharedPoolIntroductions={acceptedRequests}
                />
            </TableCell>
            <TableCell component="th" scope="row">
                <ViewRestaurantIntroductions
                    restaurant={restaurant}
                    sharedPoolIntroductions={declinedRequests}
                />
            </TableCell>
            <TableCell component="th" scope="row">
                <ViewRestaurantIntroductions
                    restaurant={restaurant}
                    sharedPoolIntroductions={noResponseRequests}
                />
            </TableCell>
            <TableCell component="th" scope="row">
                <ViewRestaurantIntroductions
                    restaurant={restaurant}
                    sharedPoolIntroductions={pendingRequests}
                />
            </TableCell>
            <TableCell align="right">
                <ViewRestaurantIntroductions
                    restaurant={restaurant}
                    sharedPoolIntroductions={metrics?.sharedPoolIntroductions || []}
                />
            </TableCell>
        </TableRow>
    );
}

function SharedPoolIntroductions({
    restaurants,
}: {
    restaurants: Contracts.DBRestaurantShape[];
}) {
    const classes = useStyles();
    const { sharedPoolIntroductions } = useGetSharedPoolIntroductions();
    let metricsByRestaurant = {};
    restaurants
        .sort((x, y) => (x?.title > y?.title ? 1 : -1))
        .map(restaurant => {
            metricsByRestaurant[restaurant.id] = {
                restaurant,
                sharedPoolIntroductions: [],
            };
        });
    const activeRestaurants = restaurants.filter(restaurant => restaurant.isActive);
    const sharedPoolEnabledRestaurants = restaurants.filter(
        restaurant => restaurant?.config?.sharedPoolEnabled,
    );
    let activeUsers: string[] = [];
    activeRestaurants.map(restaurant => {
        activeUsers = [
            ...activeUsers,
            ...Object.keys(restaurant?.members).filter(
                userId => !ExcludedUsers.includes(userId),
            ),
        ];
    });
    sharedPoolIntroductions.map(introduction => {
        metricsByRestaurant[introduction.restaurantId]?.sharedPoolIntroductions.push(
            introduction,
        );
    });
    let acceptedRequests: Contracts.DBSharedPoolIntroduction[] = [],
        declinedRequests: Contracts.DBSharedPoolIntroduction[] = [],
        pendingRequests: Contracts.DBSharedPoolIntroduction[] = [],
        noResponseRequests: Contracts.DBSharedPoolIntroduction[] = [];
    sharedPoolIntroductions?.map((introduction: Contracts.DBSharedPoolIntroduction) => {
        if (introduction.communicationState === "CONTACTED_PENDING") {
            if (introduction?.isActive === "true") pendingRequests.push(introduction);
            else noResponseRequests.push(introduction);
        } else if (introduction.communicationState === "CONTACTED_DECLINED") {
            declinedRequests.push(introduction);
        } else if (introduction.communicationState === "CONTACTED_ACCEPTED") {
            acceptedRequests.push(introduction);
        }
    });
    return (
        <div className={classes.root}>
            <Paper>
                <h4>Total Introductions Stats</h4>
                <SummaryTable
                    classes={classes}
                    totalRestaurants={sharedPoolEnabledRestaurants?.length || 0}
                    sharedPoolIntroductions={sharedPoolIntroductions}
                    acceptedRequests={acceptedRequests}
                    declinedRequests={declinedRequests}
                    noResponseRequests={noResponseRequests}
                    pendingRequests={pendingRequests}
                />
                <h4>Introductions By Restaurant</h4>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Restaurant</TableCell>
                            <TableCell>Accepted Requests</TableCell>
                            <TableCell>Declined Requests</TableCell>
                            <TableCell>No Response</TableCell>
                            <TableCell>Pending Requests</TableCell>
                            <TableCell align="right">Total Introductions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sharedPoolEnabledRestaurants.map(
                            (restaurant: Contracts.DBRestaurantShape, index: number) => {
                                return (
                                    <Row
                                        key={index + 1}
                                        classes={classes}
                                        index={index + 1}
                                        restaurant={restaurant}
                                        metrics={metricsByRestaurant[restaurant.id]}
                                    />
                                );
                            },
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    );
}

const SummaryTable = ({
    classes,
    totalRestaurants,
    sharedPoolIntroductions,
    acceptedRequests,
    declinedRequests,
    noResponseRequests,
    pendingRequests,
}) => (
    <TableContainer component={Paper}>
        <Table className={classes.table} stickyHeader aria-label="Shared Talent Pool">
            <TableHead>
                <TableRow>
                    <TableCell align="center">
                        Restaurants (Shared pool enabled)
                    </TableCell>
                    <TableCell align="center">Total Introductions</TableCell>
                    <TableCell align="center">Accepted</TableCell>
                    <TableCell align="center">Declined</TableCell>
                    <TableCell align="center">No Response</TableCell>
                    <TableCell align="center">Pending</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell align="center">{totalRestaurants}</TableCell>
                    <TableCell align="center">
                        <ViewRestaurantIntroductions
                            sharedPoolIntroductions={sharedPoolIntroductions}
                        />
                    </TableCell>
                    <TableCell align="center">
                        <ViewRestaurantIntroductions
                            sharedPoolIntroductions={acceptedRequests}
                        />
                    </TableCell>
                    <TableCell align="center">
                        <ViewRestaurantIntroductions
                            sharedPoolIntroductions={declinedRequests}
                        />
                    </TableCell>
                    <TableCell align="center">
                        <ViewRestaurantIntroductions
                            sharedPoolIntroductions={noResponseRequests}
                        />
                    </TableCell>
                    <TableCell align="center">
                        <ViewRestaurantIntroductions
                            sharedPoolIntroductions={pendingRequests}
                        />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>
);
