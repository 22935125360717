import React from "react";
import { useGetWeeklyActiveUsersReport } from "../../hooks/useGetActiveUsersReport";
import LineChart from "./LineChart";

export default function ActiveUsersReportWeekly() {
    const { loading, data } = useGetWeeklyActiveUsersReport();

    if (loading) return null;
    let chartData = [["Day", "Weekly Active Users"]];
    let maxActiveUsers = 0;
    let maxActiveUsersDate = "";
    data.map(row => {
        chartData.push([`${row.year}/${row.week}`, row.activeUsers]);
        if (row.activeUsers > maxActiveUsers) {
            maxActiveUsers = row.activeUsers;
            maxActiveUsersDate = `${row.year}/${row.week}`;
        }
    });
    return (
        <LineChart
            data={chartData}
            title={`Active users per week`}
            subtitle={`Highest users per week is ${maxActiveUsers} on ${maxActiveUsersDate}`}
        />
    );
}
