import firebase from "firebase";
import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import logo from "../assets/hand-of-anna.png";
import { auth } from "../firebase/configure";

// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // signInSuccessUrl: "/signedIn",
    callbacks: {
        signInSuccessWithAuthResult: function () {
            return false;
        },
    },
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            customParameters: {
                hd: "allgravy,com",
            },
        },
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ],
};

class SignInScreen extends React.Component {
    render() {
        return (
            <div style={{ textAlign: "center" }}>
                <img src={logo} className="App-logo" alt="logo" />
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
            </div>
        );
    }
}
export default SignInScreen;
