import React from "react";

export default function Badge() {
  return (
    <div
      style={{
        padding: "6px 10px",
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 2,
        color: "#fff",
        backgroundColor: "#0079FF"
      }}
    >
      New
    </div>
  );
}
