import { useEffect, useState } from "react";
import { fetchData } from "../helpers/dataLoading";

export function useGetRejectedByAppliedDate(): any | {} {
    const [stats, setStates] = useState<{}>({});
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("rejectedByAppliedDate");
        setStates(data[0]);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, stats };
}
export function useGetRejectedFunnelsByAppliedDate(): any | {} {
    const [rejectedFunnels, setStats] = useState<{}>({});
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("rejectedFunnelsByAppliedDate");
        setStats(data[0]?.rejected || 0);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, rejectedFunnels };
}

export function useGetRejectedFunnelsLastMonth(): any | {} {
    const [rejected, setStats] = useState<{}>({});
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("rejectedFunnelsLastMonth");
        setStats(data[0].rejected || 0);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, rejected };
}
