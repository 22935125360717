import { useEffect, useState } from "react";
import { firestore } from "../firebase/configure";
import { FirestoreCollections } from "../enums/FirebaseCollections";
import { annaId } from "../config/filter";

/**
 *
 * Added the firebase logic like this untill
 * we have a better way to abstract it with sagas for similar
 * Just to keep it outside of the component
 *
 */

export function useGetUserRestaurants(
    userId: string,
): With.Id<Contracts.DBRestaurantShape>[] {
    const [restaurants, setRestaurants] = useState<any | null>([]);
    useEffect(() => {
        const unsubscribe = firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .where(`members.${userId}.uid`, "==", userId)
            .onSnapshot(
                query => {
                    const result = query.docs
                        .filter(doc => doc.id !== annaId)
                        .map(doc => {
                            return {
                                id: doc.id,
                                ...doc.data(),
                            };
                        });
                    setRestaurants(result);
                },
                err => {
                    console.log(err);
                },
            );
        return () => unsubscribe();
    }, []);

    return restaurants;
}
