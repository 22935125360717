import { useEffect, useState } from "react";
import { firestore } from "../firebase/configure";
import { FirestoreCollections } from "../enums/FirebaseCollections";

/**
 *
 * Added the firebase logic like this untill
 * we have a better way to abstract it with sagas for similar
 * Just to keep it outside of the component
 *
 */

export function useGetActivityByFunnel(funnelId: string) {
    console.log({ funnelId });

    const [activity, setActivity] = useState<any | null>([]);
    useEffect(() => {
        const unsubscribe = firestore
            .collection(FirestoreCollections.ACTIVITY)
            .where("funnelId", "==", funnelId)
            .orderBy("createTime", "asc")
            .onSnapshot(
                query => {
                    if (!query.empty && query.docs.length > 0) {
                        const result = query.docs.map(doc => {
                            return {
                                id: doc.id,
                                ...doc.data(),
                            };
                        });
                        setActivity(result);
                    }
                },
                err => {
                    console.log(err);
                },
            );
        return () => unsubscribe();
    }, [funnelId]);

    return activity;
}
