import React from "react";
import { useGetAppUsageStatsReport } from "../../hooks/useGetAppUsageStatsReport";
import LineChart from "./LineChart";

export default function AppUsageStatsReport() {
    const { loading, data } = useGetAppUsageStatsReport();

    if (loading) return null;
    let chartData = [["Month", "Total Sessions", "Duration (minutes)"]];
    let totalDurationInMins = 0;
    data &&
        data.map(row => {
            totalDurationInMins =
                totalDurationInMins + Math.floor(row.totalDurationInSeconds / 60);
            chartData.push([
                `${row.year}/${row.month}`,
                row.totalSessions,
                Math.floor(row.totalDurationInSeconds / 60),
            ]);
        });
    return (
        <LineChart
            data={chartData}
            title={`App Usage till date is ${totalDurationInMins} minutes`}
            subtitle="Session duration is defined as time between App opened and closed"
        />
    );
}
