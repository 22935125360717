import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddLanguageForm from "./AddLanguageForm";
import { ModalType } from "./PositionsTree";
import AddAliasForm from "./AddAlias";
import AddPositionForm from "./AddPosition";

export default function FormDialog({ modal, handleClose }) {
    return (
        <div>
            <Dialog
                open={modal.type !== null}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Adding positions</DialogTitle>
                {modal.type === ModalType.ADD_POSITION && (
                    <AddPositionForm handleClose={handleClose} />
                )}
                {modal.type === ModalType.ADD_LANGUAGE && (
                    <AddLanguageForm
                        positionId={modal.context?.positionId}
                        languages={modal.context?.languages}
                        handleClose={handleClose}
                    />
                )}
                {modal.type === ModalType.ADD_ALIAS && (
                    <AddAliasForm
                        positionId={modal.context?.positionId}
                        language={modal.context?.language}
                        handleClose={handleClose}
                        aliases={modal.context?.languages}
                    />
                )}
            </Dialog>
        </div>
    );
}
