import React from "react";

import { useGetFormTemplates } from "../../hooks/useFormTemplate";

function FormList() {
    const { templates } = useGetFormTemplates();

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                margin: 16,
                flexWrap: "wrap",
            }}>
            {templates.map(template => (
                <a
                    href={`forms/${template.id}`}
                    target="_blank"
                    key={template.formId}
                    style={{
                        padding: 20,
                        marginBottom: 16,
                        marginRight: 16,
                        borderRadius: 8,
                        border: "1px solid lightgray",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        color: "#212529",
                        backgroundColor: "rgb(239, 239, 239)",
                    }}>
                    <div>{template.title}</div>

                    <img
                        src={template.logo}
                        style={{ height: 100, width: 100, objectFit: "contain" }}
                    />
                </a>
            ))}
        </div>
    );
}

export default FormList;
