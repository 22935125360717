import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import { red } from "@material-ui/core/colors";
import ApplicantsTable from "./ApplicantsTable";
import { keyBy, uniqBy } from "lodash";
import { FormControl, InputLabel, Select } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 10,
        },
        containerItem: {
            display: "inline-block",
            padding: 5,
        },
        expand: {
            transform: "rotate(0deg)",
            marginLeft: "auto",
            transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: "rotate(180deg)",
        },
        avatar: {
            backgroundColor: red[500],
        },
        formControl: {
            margin: theme.spacing(1),
            // minWidth: 120,
        },
    }),
);

export default function BrowseApplicantsByCategory({
    restaurants,
    applications,
    sharedPoolApplications,
    restaurantsByCategory,
}) {
    const classes = useStyles();
    const [category, setCategory] = React.useState("");
    const [selectedPosition, setSelectedPosition] = React.useState("");
    const [selectedRestaurantId, setSelectedRestaurantId] = React.useState("");
    const [rejectedByRestaurantId, setRejectedByRestaurantId] = React.useState("");
    const handleCategorySelection = categoryName => {
        setCategory(categoryName === category ? "" : categoryName);
        setSelectedRestaurantId("");
        setSelectedPosition("");
        setRejectedByRestaurantId("");
    };
    const handleRestaurantSelection = restaurantId => {
        setSelectedRestaurantId(
            restaurantId === selectedRestaurantId ? "" : restaurantId,
        );
        setSelectedPosition("");
        setRejectedByRestaurantId("");
    };
    const handleRejectedBySelection = event => {
        setRejectedByRestaurantId(event?.target?.value || "");
        setSelectedRestaurantId("");
        setSelectedPosition("");
    };
    const handlePositionSelection = event => {
        setSelectedPosition(event?.target?.value || "");
    };
    const applicationsMap = keyBy(applications, "id");
    const restaurantsMap = keyBy(restaurants, "id");

    const categories: string[] = [
        ...new Set(
            restaurants
                .map(restaurant => restaurant?.categories || [])
                .reduce(function (a, b) {
                    return a.concat(b);
                }, []),
        ),
    ] as string[];

    // Show all active shared pool applications by default
    let activeApplicationsInCategory: Contracts.DBSharedPoolApplication[] = [];
    let filteredRestaurants: Contracts.DBRestaurantShape[] = category
        ? restaurants.filter(restaurant => restaurant?.categories?.includes(category))
        : restaurants;
    activeApplicationsInCategory = (category
        ? sharedPoolApplications.filter(application =>
              application?.categories?.includes(category),
          )
        : sharedPoolApplications
    ).sort((a, b) => (a.appliedTime > b.appliedTime ? -1 : 1));

    const positions = uniqBy(activeApplicationsInCategory, "sharedPoolPosition").map(
        application => application.sharedPoolPosition,
    );
    if (selectedPosition) {
        activeApplicationsInCategory = activeApplicationsInCategory.filter(
            application => application?.sharedPoolPosition === selectedPosition,
        );
    }
    if (rejectedByRestaurantId) {
        activeApplicationsInCategory = activeApplicationsInCategory.filter(
            application => application?.rejectedByRestaurantId === rejectedByRestaurantId,
        );
    }
    if (selectedRestaurantId) {
        activeApplicationsInCategory = activeApplicationsInCategory.filter(application =>
            application?.restaurantIds.includes(selectedRestaurantId),
        );
    }
    return (
        <>
            <div className={classes.container}>
                <div className={classes.containerItem}>
                    <h4>{restaurants?.length || 0} Restaurants</h4>
                    <h4>{categories?.length || 0} Categories</h4>
                    {categories.map(categoryName => {
                        return (
                            <div key={categoryName} className={classes.containerItem}>
                                <Badge
                                    className={classes.containerItem}
                                    badgeContent={
                                        restaurantsByCategory[categoryName]?.length || 0
                                    }
                                    color="secondary">
                                    <Button
                                        variant={
                                            category === categoryName
                                                ? "contained"
                                                : "outlined"
                                        }
                                        color="primary"
                                        onClick={() =>
                                            handleCategorySelection(categoryName)
                                        }>
                                        {categoryName}
                                    </Button>
                                </Badge>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={classes.containerItem}>
                <div className={classes.container}>
                    <h4>
                        {activeApplicationsInCategory?.length || 0} Active applications in
                        the pool{" "}
                        {selectedRestaurantId
                            ? `for ${restaurantsMap[selectedRestaurantId]?.title}`
                            : category
                            ? `for category ${category?.toUpperCase()}`
                            : ``}
                        {rejectedByRestaurantId
                            ? ` rejected by ${restaurantsMap[rejectedByRestaurantId]?.title}`
                            : ``}
                    </h4>
                    {category &&
                        filteredRestaurants.map(restaurant => {
                            return (
                                <div className={classes.containerItem}>
                                    <Button
                                        variant={
                                            selectedRestaurantId === restaurant.id
                                                ? "contained"
                                                : "outlined"
                                        }
                                        color="primary"
                                        onClick={() =>
                                            handleRestaurantSelection(restaurant.id)
                                        }>
                                        {restaurant?.title}
                                    </Button>
                                </div>
                            );
                        })}
                </div>
            </div>
            <div className={classes.container}>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="restaurant-selected">
                        Rejected By Restaurant
                    </InputLabel>
                    <Select
                        native
                        value={rejectedByRestaurantId}
                        onChange={handleRejectedBySelection}
                        inputProps={{
                            name: "restaurant",
                            id: "restaurant-selected",
                        }}>
                        <option aria-label="None" value="" />
                        {filteredRestaurants.map(
                            (restaurant: Contracts.DBRestaurantShape) => (
                                <option key={restaurant.id} value={restaurant.id}>
                                    {restaurant.title}
                                </option>
                            ),
                        )}
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="position-selected">Position</InputLabel>
                    <Select
                        native
                        value={selectedPosition}
                        onChange={handlePositionSelection}
                        inputProps={{
                            name: "position",
                            id: "position-selected",
                        }}>
                        <option aria-label="None" value="" />
                        {positions.map((option: string) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className={classes.container}>
                {activeApplicationsInCategory.length > 0 && (
                    <ApplicantsTable
                        restaurants={restaurants}
                        restaurantsMap={restaurantsMap}
                        applicationsMap={applicationsMap}
                        sharedPoolApplications={activeApplicationsInCategory}
                    />
                )}
            </div>
        </>
    );
}
