import React, { FormEvent, useEffect } from "react";
import { FormGroup, Grid, TextField, OutlinedInput, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles, Theme, createStyles, MenuItem } from "@material-ui/core";
import RestaurantPositions from "../../components/Onboarding/RestaurantPositions";
import { Positions } from "../../types/Positions";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import HowToTypeform from "../../components/Onboarding/HowToTypeform";
import { addRestaurant, RestaurantCreationError } from "../../firebase/firestore";
import HowToTypeformHiring from "../../components/Onboarding/HowToTypeformHiring";
import RestaurantLabels from "./RestaurantLabels";
import { getRestaurantTypeForLabels } from "../../helpers/restaurant";
import { useHistory } from "react-router-dom";
import joinCls from "../../helpers/styles";
import { AnalyticsType } from "../../types/fixedTypes";
import { useGetRestaurantGroups } from "../../hooks/useGetRestaurantGroups";
import { RequiredProperty } from "../../types/utils";
import SharedTalentPoolKeywords, {
    getSelectedKeywords,
    Keywords,
} from "./SharedTalentPoolKeywords";
import { Checkbox } from "@material-ui/core";
import { useRestaurantLogo } from "../../hooks/useRestaurantLogo";
import TimezoneSelect, { i18nTimezones } from "react-timezone-select";

interface State {
    title: string;
    logo: File | null;
    logoUrl?: string;
    applicationForm: string;
    applicationFormId: string;
    hireForm: string;
    hireFormId: string;
    address: string;
    website: string;
    instagram: string;
    isTrialPaid: number;
    positions: Positions[];
    labels: Contracts.DBRestaurantLabels | null;
    groupId: string;
    motivationKeywords: Keywords;
    selfIntroKeywords: Keywords;
    workExperienceKeywords: Keywords;
    timeZone: string;
}

function validateState(values: State, fieldsToIgnore: (keyof State)[]) {
    return Object.entries(values)
        .filter(([key, _value]) => !fieldsToIgnore.includes(key as keyof State))
        .map(([key, value]) => {
            if (typeof value === "string" && value === "") {
                return key;
            } else if (Array.isArray(value) && value.length === 0) {
                return key;
            } else if (value === undefined || value === null) {
                return key;
            }
            return null;
        })
        .filter(v => v);
}

const initialState: State = {
    title: "",
    logo: null,
    applicationForm: "",
    applicationFormId: "",
    hireForm: "",
    hireFormId: "",
    address: "",
    website: "",
    instagram: "",
    isTrialPaid: 0,
    positions: [],
    labels: null,
    groupId: "",
    motivationKeywords: {
        your: true,
        "your restaurant": true,
    },
    selfIntroKeywords: {
        your: true,
        "your restaurant": true,
    },
    timeZone: "Europe/Copenhagen",
    workExperienceKeywords: {},
};

function RestaurantForm() {
    const history = useHistory();
    const classes = useStyles();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [values, setValues] = React.useState<State>(initialState);

    const restaurantGroups = useGetRestaurantGroups();

    const { picker, upload } = useRestaurantLogo();

    useEffect(() => {
        if (picker.logo) {
            setValues(values => ({
                ...values,
                logo: picker.logo!.file,
                logoUrl: picker.logo!.url,
            }));
        }
    }, [picker.logo]);

    const handleChange = (prop: keyof State) => (
        event: React.ChangeEvent<{ value: any }>,
    ) => {
        setValues({ ...values, [prop as any]: event.target.value });
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        const isInvalid = validateState(values, ["groupId", "website", "instagram"]);

        if (isInvalid.length > 0) {
            setIsLoading(false);
            return alert("Please fill out: " + isInvalid.join(", "));
        }
        const {
            title,
            logo,
            applicationForm,
            applicationFormId,
            hireForm,
            hireFormId,
            address,
            website,
            instagram,
            isTrialPaid,
            labels,
            groupId,
            motivationKeywords,
            selfIntroKeywords,
            workExperienceKeywords,
            timeZone,
        } = values as RequiredProperty<State>;

        const uploadLogoResult = await upload(logo);

        if (uploadLogoResult.isSuccess) {
            const baseRestaurantData = {
                title,
                logo: uploadLogoResult.url,
                typeformUrl: applicationForm,
                formId: applicationFormId,
                hireForm,
                hireFormId,
                isTrialPaid: !!isTrialPaid,
                timeZoneOffset: -120,
                timeZone,
                location: {
                    address,
                },
                members: {},
                preferences: {
                    trialStartTime: "10:00",
                    trialInfo: "",
                    trialEndTime: "20:00",
                },
                website,
                instagram,
                labels: {
                    ...labels,
                    type: getRestaurantTypeForLabels(labels),
                },
            };

            const restaurantData = groupId
                ? { ...baseRestaurantData, groupId }
                : baseRestaurantData;

            const result = await addRestaurant(restaurantData, values.positions, {
                motivation: getSelectedKeywords(motivationKeywords),
                selfIntro: getSelectedKeywords(selfIntroKeywords),
                workExperience: getSelectedKeywords(workExperienceKeywords),
            });

            if (result.success) {
                alert("Remember to add the webhook in typeform :)");
                history.replace("/restaurants");
            } else {
                alert(
                    `Failed to create restaurant. ${
                        (result as RestaurantCreationError).message
                    }`,
                );
            }
        } else {
            alert(`Failed to upload restaurant logo.`);
        }

        setIsLoading(false);
    };

    return (
        <>
            <form onSubmit={handleSubmit} className={classes.form}>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                    <InputLabel htmlFor="restaurant-title">
                        Title (Example: Slurp)
                    </InputLabel>
                    <OutlinedInput
                        id="restaurant-title"
                        value={values.title}
                        onChange={handleChange("title")}
                        // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        labelWidth={60}
                    />
                </FormControl>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                    <div
                        {...picker.getRootProps()}
                        className={joinCls("dropzone", classes.logoInputContainer)}>
                        <input
                            className={classes.logoInput}
                            {...picker.getInputProps()}
                        />
                        <p
                            className={joinCls(
                                "MuiFormLabel-root",
                                classes.logoInputLabel,
                            )}>
                            {values.logo
                                ? `Selected logo: ${values.logo.name}\n`
                                : "Drag and drop logo here or click here to select logo (jpg, jpeg, png)"}
                        </p>
                        {values.logoUrl && (
                            <img className={classes.logo} src={values.logoUrl} />
                        )}
                    </div>
                </FormControl>
                {/* {values.logoUrl && <img className={classes.logo} src={values.logoUrl} />} */}
                <FormControl fullWidth className={classes.margin} variant="outlined">
                    <InputLabel htmlFor="restaurant-application-form">
                        Application form (Example:
                        https://helloapplication.typeform.com/to/N2b9bL)
                    </InputLabel>
                    <OutlinedInput
                        id="restaurant-application-form"
                        value={values.applicationForm}
                        onChange={handleChange("applicationForm")}
                        // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        labelWidth={60}
                    />
                    <HowToTypeform />
                </FormControl>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                    <InputLabel htmlFor="restaurant-application-form-id">
                        Application form ID (Example: N2b9bL)
                    </InputLabel>
                    <OutlinedInput
                        id="restaurant-application-form-id"
                        value={values.applicationFormId}
                        onChange={handleChange("applicationFormId")}
                        // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        labelWidth={60}
                    />
                </FormControl>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                    <InputLabel htmlFor="restaurant-hire-form">
                        Hire form (Example: https://hellohire.typeform.com/to/PxMXsh)
                    </InputLabel>
                    <OutlinedInput
                        id="restaurant-hire-form"
                        value={values.hireForm}
                        onChange={handleChange("hireForm")}
                        // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        labelWidth={60}
                    />
                    <HowToTypeformHiring />
                </FormControl>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                    <InputLabel htmlFor="restaurant-hire-form-id">
                        Hire form ID (Example: PxMXsh)
                    </InputLabel>
                    <OutlinedInput
                        id="restaurant-hire-form-id"
                        value={values.hireFormId}
                        onChange={handleChange("hireFormId")}
                        // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        labelWidth={60}
                    />
                </FormControl>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                    <InputLabel htmlFor="restaurant-address">
                        Address (Example: Refshalevej 153, 1432 København)
                    </InputLabel>
                    <OutlinedInput
                        id="restaurant-address"
                        value={values.address}
                        onChange={handleChange("address")}
                        // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        labelWidth={60}
                    />
                </FormControl>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                    <InputLabel htmlFor="restaurant-website">
                        Website (Example: https://noma.dk)
                    </InputLabel>
                    <OutlinedInput
                        id="restaurant-address"
                        value={values.website}
                        onChange={handleChange("website")}
                        // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        labelWidth={60}
                    />
                </FormControl>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                    <InputLabel htmlFor="restaurant-instagram">
                        Instagram profile (Example:
                        https://www.instagram.com/annacommunity)
                    </InputLabel>
                    <OutlinedInput
                        id="restaurant-instagram"
                        value={values.instagram}
                        onChange={handleChange("instagram")}
                        // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        labelWidth={60}
                    />
                </FormControl>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                    <TextField
                        id="restaurant-trial-paid"
                        select
                        label="Is trials paid?"
                        value={values.isTrialPaid}
                        onChange={handleChange("isTrialPaid")}
                        variant="outlined">
                        <MenuItem key="trial-true" value={1}>
                            Yes
                        </MenuItem>
                        <MenuItem key="trial-false" value={0}>
                            No
                        </MenuItem>
                    </TextField>
                </FormControl>

                <FormControl fullWidth className={classes.margin} variant="outlined">
                    <InputLabel shrink>{restaurantGroupLabel}</InputLabel>
                    <Select
                        displayEmpty
                        value={values.groupId}
                        onChange={e =>
                            setValues(values => ({
                                ...values,
                                groupId: e.target.value as string,
                            }))
                        }
                        input={
                            <OutlinedInput
                                label={restaurantGroupLabel}
                                classes={{
                                    notchedOutline: classes.notchedOutline,
                                }}
                            />
                        }>
                        <MenuItem key="group-none" value={""}>
                            No
                        </MenuItem>
                        {restaurantGroups.map(group => (
                            <MenuItem key={`group-${group.id}`} value={group.id}>
                                {group.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl style={{ width: "100%" }}>
                    <TimezoneSelect
                        value={values.timeZone}
                        onChange={({ value }) =>
                            setValues(values => ({
                                ...values,
                                timeZone: value,
                            }))
                        }
                        timezones={{
                            "Europe/Copenhagen": "Copenhagen",
                        }}
                    />
                </FormControl>

                <FormControl fullWidth className={classes.margin} variant="outlined">
                    <InputLabel>Positions</InputLabel>
                    <RestaurantPositions
                        right={values.positions}
                        setRight={(value: Positions[]) =>
                            setValues({ ...values, positions: value })
                        }
                    />
                </FormControl>

                <FormControl fullWidth className={classes.margin} variant="outlined">
                    <SharedTalentPoolKeywords
                        type={AnalyticsType.Motivation}
                        label={"Motivation keywords"}
                        keywords={values.motivationKeywords}
                        onChange={motivationKeywords =>
                            setValues(values => ({ ...values, motivationKeywords }))
                        }
                    />
                </FormControl>

                <FormControl fullWidth className={classes.margin} variant="outlined">
                    <SharedTalentPoolKeywords
                        type={AnalyticsType.SelfIntro}
                        label={"Self intro keywords"}
                        keywords={values.selfIntroKeywords}
                        onChange={selfIntroKeywords =>
                            setValues(values => ({ ...values, selfIntroKeywords }))
                        }
                    />
                </FormControl>

                <FormControl fullWidth className={classes.margin} variant="outlined">
                    <SharedTalentPoolKeywords
                        type={AnalyticsType.WorkExperience}
                        label={"Work experience keywords"}
                        keywords={values.workExperienceKeywords}
                        onChange={workExperienceKeywords =>
                            setValues(values => ({ ...values, workExperienceKeywords }))
                        }
                    />
                </FormControl>

                <RestaurantLabels
                    onlyFormControls
                    onCompleted={labels => {
                        setValues(values => ({ ...values, labels }));
                    }}
                />

                <div style={{ margin: 20, marginTop: 64 }}>
                    <Button
                        fullWidth
                        type="submit"
                        variant="outlined"
                        disabled={isLoading}>
                        {isLoading && (
                            <CircularProgress
                                style={{ position: "absolute" }}
                                size={24}
                            />
                        )}
                        Setup {values.title}
                    </Button>
                </div>
            </form>
        </>
    );
}

function AddRestaurant() {
    return (
        <Grid container spacing={3} style={{ padding: 24 }}>
            <Grid item xs={12}>
                <h1>Restaurant setup</h1>
                <FormGroup row>
                    <RestaurantForm />
                </FormGroup>
            </Grid>
        </Grid>
    );
}

export default AddRestaurant;

const restaurantGroupLabel = "Is restaurant belonging to a group?";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            maxWidth: "100%",
        },
        root: {
            display: "flex",
            flexWrap: "wrap",
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: "25ch",
        },
        logoInputContainer: {
            width: "100%",
            minHeight: 100,
            borderRadius: 4,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#c4c4c4",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#c4c4c436",
        },
        logoInput: {
            height: 100,
        },
        logoInputLabel: {
            margin: 32,
            padding: 0,
        },
        logo: {
            maxWidth: 300,
            marginBottom: 32,
        },
        notchedOutline: {
            // NOTE: the legend element is a child of the notchedOutline component
            "& legend": {
                maxWidth: "1000px",
            },
        },
    }),
);
