import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import WarningIcon from "@material-ui/icons/Warning";
import { updateUserPassword } from "../../api";
import { CircularProgress } from "@material-ui/core";

export default function ResetPassword({ open, setOpen, userId }) {
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState("");
    const handleClose = () => {
        setIsLoading(false);
        setPassword("");
        setOpen(false);
    };

    const onSave = async () => {
        setIsLoading(true);
        const error = await updateUserPassword(userId, password);
        if (error) {
            setIsLoading(false);
            return alert(error);
        }
        return handleClose();
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <WarningIcon /> Reset password (only for users who signed up with
                    email)
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        When changing the password. The user will be signed out and login
                        again. The action can not be undone. <b>Min. 8 Characters.</b>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="password"
                        label="New Password"
                        type="text"
                        fullWidth
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isLoading} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onSave} disabled={isLoading} color="primary">
                        {isLoading ? <CircularProgress /> : "Update password"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
