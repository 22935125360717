import React from "react";
import Chart from "react-google-charts";
import {
    useGetUniqueApplicantsByRestaurant,
    useGetDuplicateApplicantsByRestaurant,
} from "../../hooks/useGetUniqueApplicantsByRestaurant";
import { useGetRestaurants, useGetRestaurantsMap } from "../../hooks/useGetRestaurants";
import ApplicantsReport from "../../components/Reports/Applicants";
import RejectedApplicantsReport from "../../components/Reports/RejectedApplicants";
import ContactedApplicantsReport from "../../components/Reports/ContactedApplicants";
import ChatMessagesReport from "../../components/Reports/ChatMessages";
import AppUsageStatsReport from "../../components/Reports/AppUsageStats";
import ActiveUsersReportDaily from "../../components/Reports/ActiveUsersReportDaily";
import ActiveSessionsReport from "../../components/Reports/ActiveSessions";
import ActiveUsersReportWeekly from "../../components/Reports/ActiveUsersReportWeekly";
import ActiveUsersReportMonthly from "../../components/Reports/ActiveUsersReportMonthly";
import { useGetActiveUsers } from "../../hooks/useGetActiveUsers";

export default function Dashboard() {
    const restaurantMap = useGetRestaurantsMap(true);
    const { activeUsers } = useGetActiveUsers();
    // TODO Extract all month by month data into single structure
    const { duplicateApplicants } = useGetDuplicateApplicantsByRestaurant();
    const { loading, uniqueApplicants } = useGetUniqueApplicantsByRestaurant();
    if (loading) return null;
    const activeRestaurants = Object.values(restaurantMap).filter(
        restaurant => restaurant.isActive,
    );
    let totalUniqueApplicants = 0,
        totalDuplicateApplicants = 0;

    console.log({ activeUsers, uniqueApplicants, duplicateApplicants });

    return (
        <div style={{ padding: "20dp" }}>
            <h4>Active restaurants {activeRestaurants.length}</h4>
            <h4>Active users {activeUsers.length}</h4>
            <ActiveUsersReportDaily />
            <ActiveUsersReportWeekly />
            <ActiveUsersReportMonthly />
            <AppUsageStatsReport />
            <ActiveSessionsReport />
            <ApplicantsReport />
            <RejectedApplicantsReport />
            <ContactedApplicantsReport />
            <ChatMessagesReport />
        </div>
    );
}
