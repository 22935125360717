import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { firestore } from "../../firebase/configure";
import { FirestoreCollections } from "../../enums/FirebaseCollections";

function validateForm(alias: string) {
    if (!alias) return false;
    return true;
}

export default function AddAliasForm({ positionId, language, aliases, handleClose }) {
    const [isLoading, setisLoading] = useState(false);
    const [alias, setalias] = useState("");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setalias(e.target.value);
    };

    const submitAlias = async () => {
        setisLoading(true);
        const isValid = validateForm(alias);

        console.log(isValid, positionId, language);
        await firestore
            .collection(FirestoreCollections.POSITIONS)
            .doc(positionId)
            .set({ [language]: [...aliases, alias.trim()] }, { merge: true });
        setisLoading(false);
        handleClose();
    };
    return (
        <>
            <DialogContent>
                <DialogContentText>
                    Adding to {positionId} for language {language}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Alias"
                    type="Alias"
                    fullWidth
                    value={alias}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={submitAlias} color="primary" disabled={isLoading}>
                    Add
                </Button>
            </DialogActions>
        </>
    );
}
