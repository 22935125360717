import React, { useState } from "react";
import { useGetUserChatMessages } from "../../../hooks/useGetChatMessages";
import { makeStyles, Theme, createStyles, List, Tooltip } from "@material-ui/core";
import Message from "./Message";
import chatMessages from "./data/activity.json";
import { predictMessageAction } from "../../../lib/regex/prediction";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: 50,
        },
        messages: {
            alignSelf: "flex-end",
            width: "80%",
            display: "inline-block",
            overflowY: "scroll",
            maxHeight: "90vh",
            backgroundColor: theme.palette.background.paper,
        },
        analytics: {
            alignSelf: "flex-end",
            width: "20%",
            display: "inline-block",
            overflowY: "scroll",
            maxHeight: "90vh",
            backgroundColor: theme.palette.background.paper,
        },
        inline: {
            display: "inline",
        },
        title: {
            fontSize: 14,
        },
    }),
);

export default function ChatAnalytics() {
    const classes = useStyles();
    let totalMessagesCount: number = 0;
    let userMessagesCount: number = 0;
    let applicantMessagesCount: number = 0;
    let userActionMessagesCount: number = 0;
    let applicantActionMessagesCount: number = 0;
    totalMessagesCount = chatMessages.length;
    // console.log("Total messages", chatMessages);
    const userMessages = chatMessages.filter(
        message =>
            message.type === "USER_MESSAGE" &&
            message.authorMetadata.source != "EXTERNAL",
    );
    userMessagesCount = userMessages.length;
    // console.log("User messages", userMessages);
    const userPredictedActions = userMessages.map(item => {
        const isApplicant = item.authorMetadata.source === "EXTERNAL";
        const message: string = item["USER_MESSAGE"] ? item["USER_MESSAGE"] : "";
        return { action: predictMessageAction(message), item: item };
    });
    const userPredictedActionsFiltered = userPredictedActions.filter(
        x => x["action"] != "",
    );
    // console.log("User predicted actions", userPredictedActionsFiltered);
    userActionMessagesCount = userPredictedActionsFiltered.length;
    const applicantMessages = chatMessages.filter(
        message => message.authorMetadata.source === "EXTERNAL",
    );
    applicantMessagesCount = applicantMessages.length;
    // console.log("Applicant messages", applicantMessages);
    const applicantPredictedActions = applicantMessages.map(item => {
        const isApplicant = item.authorMetadata.source === "EXTERNAL";
        const message: string = item["USER_MESSAGE"] ? item["USER_MESSAGE"] : "";
        return predictMessageAction(message);
    });
    const applicantPredictedActionsFiltered = applicantPredictedActions.filter(
        x => x != "",
    );
    applicantActionMessagesCount = applicantPredictedActionsFiltered.length;
    console.log(
        "Counts",
        totalMessagesCount,
        userMessagesCount,
        userActionMessagesCount,
        applicantMessagesCount,
        applicantActionMessagesCount,
    );

    return (
        <div>
            <List className={classes.root}>
                {userPredictedActionsFiltered.map((prediction: any) => {
                    const item = prediction.item;
                    const action = prediction.action;
                    if (item.authorMetadata.source != "SYSTEM") {
                        return (
                            <Message
                                key={item.id}
                                id={item.id}
                                message={
                                    item.type === "USER_MESSAGE"
                                        ? item["USER_MESSAGE"]
                                        : item.type
                                }
                                createTime={item.createTime}
                                type={item.type}
                                authorMetadata={item.authorMetadata}
                                action={action}
                            />
                        );
                    }
                    return null;
                })}
            </List>
            {/* <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    const lastMessage = chatMessages[chatMessages.length - 1];
                    setLastDate(lastMessage.createTime.toDate());
                }}>
                Load more
            </Button> */}
        </div>
    );
}
