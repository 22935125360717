import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    ListItemText,
    MenuItem,
    TextField,
    Theme,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core";
import mapValues from "lodash.mapvalues";
import React, { useState } from "react";
import { AnalyticsType } from "../../types/fixedTypes";

export type Keywords = { [key: string]: boolean };

interface SharedTalentPoolKeywordsProps {
    readonly type: AnalyticsType;
    readonly label: string;
    readonly keywords: Keywords;
    readonly onChange: (keywords: Keywords) => void;
}

export default function SharedTalentPoolKeywords({
    type,
    label,
    keywords,
    onChange,
}: SharedTalentPoolKeywordsProps) {
    const classes = useStyles();
    const [keyword, setKeyword] = useState<string>("");

    const updateKeywords = (newKeywords: string[]) => {
        onChange({
            ...mapValues(keywords, _ => false),
            ...Object.fromEntries(newKeywords.map(keyword => [keyword, true])),
        });
    };

    const addNewKeyword = () => {
        onChange({ ...keywords, [keyword]: true });
        setKeyword("");
    };

    return (
        <Card>
            <CardHeader title={label} subheader={"Shared talent pool"} />
            <CardContent>
                <TextField
                    select
                    variant="outlined"
                    // label={label}
                    fullWidth
                    margin="normal"
                    SelectProps={{
                        multiple: true,
                        renderValue: selected => (selected as string[]).join(", "),
                        value: getSelectedKeywords(keywords),
                        onChange: e => {
                            e.persist();
                            updateKeywords(e.target.value as string[]);
                        },
                    }}>
                    {Object.entries(keywords).map(([keyword, selected]) => (
                        <MenuItem key={`${type}Keywords-${keyword}`} value={keyword}>
                            <Checkbox checked={selected} />
                            <ListItemText primary={keyword} />
                        </MenuItem>
                    ))}
                </TextField>
                <div className={classes.actions}>
                    <TextField
                        fullWidth
                        id="standard-basic"
                        label="Keyword"
                        value={keyword}
                        onChange={e => setKeyword(e.target.value)}
                    />
                    <Button
                        onClick={addNewKeyword}
                        className={classes.button}
                        variant="outlined"
                        disabled={!keyword}>
                        Add new keyword
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
}

export function getSelectedKeywords(keywords: Keywords) {
    return Object.keys(keywords).filter(k => keywords[k]);
}

const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        actions: {
            display: "flex",
            flexDirection: "row",
            flex: 1,
            width: "50%",
        },
        button: {
            marginLeft: 32,
            width: 260,
        },
    }),
);
