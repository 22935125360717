import React from "react";
import Chart from "react-google-charts";

export interface LineChartProps {
    title: string;
    subtitle: string;
    data: any[][]; // Allow 2-dimensional data with first array as labels
}

export default function LineChart({ title, subtitle, data }: LineChartProps) {
    return (
        <Chart
            width={"100%"}
            height={"400px"}
            chartType="Line"
            loader={<div>Loading Chart</div>}
            data={data}
            options={{
                chart: {
                    title,
                    subtitle,
                },
            }}
            rootProps={{ "data-testid": "1" }}
        />
    );
}
