import { useEffect, useState } from "react";
import { firestore } from "../firebase/configure";
import { FirestoreCollections } from "../enums/FirebaseCollections";
import { User } from "../types/User";

/**
 *
 * Added the firebase logic like this untill
 * we have a better way to abstract it with sagas for similar
 * Just to keep it outside of the component
 *
 */

export function useGetUser(userId: string): User | {} {
    const [user, setUser] = useState<User | {}>({});
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        const unsubscribe = firestore
            .collection(FirestoreCollections.USERS)
            .doc(userId)
            .onSnapshot(
                doc => {
                    const result = {
                        id: doc.id,
                        ...doc.data(),
                    } as User;

                    setUser(result);
                    setLoading(false);
                },
                err => {
                    console.log(err);
                },
            );
        return () => unsubscribe();
    }, []);

    return { loading, user };
}
