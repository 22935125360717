import React, { useState, useEffect } from "react";
// @ts-ignore
import {
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Divider,
    Tooltip,
    Button,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AnnaHand from "../../assets/anna.jpg";
import { formatDistanceToNow, format } from "date-fns";
import { predictMessageAction } from "../../lib/regex/prediction";
import { getActionStateColor } from "../../lib/utils";

export default function TimelineTick({
    id,
    createTime,
    type,
    authorMetadata,
    funnel,
    funnelId,
    message,
}: any) {
    const date = createTime && createTime.toDate();
    const funnelTagId = funnelId + ":" + id; // TODO Define delimiters in config
    const isAnna = type !== "USER_MESSAGE" || authorMetadata.source === "SYSTEM";
    const isApplicant = authorMetadata.source === "EXTERNAL" ? funnel : "";
    const primary = `${message} ${
        authorMetadata.displayName ? " - (" + authorMetadata.displayName + ")" : ""
    }`;
    let action: string = "";
    if (!isAnna && !isApplicant) {
        action = predictMessageAction(message);
    }
    return (
        <>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Tooltip key={id} title={id} placement="top" interactive>
                        <Avatar
                            alt="Remy Sharp"
                            src={
                                isAnna
                                    ? AnnaHand
                                    : isApplicant.image || authorMetadata.image
                            }
                        />
                    </Tooltip>
                </ListItemAvatar>
                <ListItemText
                    primary={<div>{type}</div>}
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                // className={classes.inline}
                                color="textPrimary">
                                {isAnna
                                    ? "Anna"
                                    : isApplicant.firstName || authorMetadata.displayName}
                            </Typography>
                            {" - "}
                            {formatDistanceToNow(date, { addSuffix: true })}{" "}
                            {format(date, "- (HH:mm)")}
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Button size="small" variant="contained" color={getActionStateColor(action)}>
                {action}
            </Button>
            <Divider variant="inset" component="li" />
        </>
    );
}
