import "./App.css";

import { format, subDays } from "date-fns";
import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { Nav, Navbar, Spinner } from "react-bootstrap";
import { BrowserRouter as Router, Link, Redirect, Route, Switch } from "react-router-dom";

import {
    AppBar,
    createStyles,
    Drawer,
    makeStyles,
    SwipeableDrawer,
    Toolbar,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import logo from "./assets/hand-of-anna.png";
import ChatAnalytics from "./components/analytics/chats/ChatAnalytics";
import Chats from "./components/chats/Chats";
import SharedPoolChatContainer from "./components/chats/SharedPoolChats";
import SearchBar from "./components/SearchBar/index";
import SignInScreen from "./components/SignIn";
import Applicants from "./Routes/Applicants";
import Dashboard from "./Routes/Dashboard";
import Forms from "./Routes/Forms";
import FormScreen from "./Routes/Forms/FormScreen";
import Kpis from "./Routes/Kpis/index";
import Positions from "./Routes/Positions/index";
import AddRestaurant from "./Routes/Restaurants/AddRestaurant";
import Restaurants from "./Routes/Restaurants/index";
import Restaurant from "./Routes/Restaurants/Restaurant";
import SharedTalentPool from "./Routes/SharedTalentPool/index";
import Tracking from "./Routes/Tracking/index";
import Users from "./Routes/Users";
import User from "./Routes/Users/User";

function PrivateRoute({ children, isAuth, ...rest }: any) {
    console.log(isAuth);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuth ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

const dateFormat = "yyyy-MM-dd";
const today = new Date();
const todayString = format(today, dateFormat);
const threeWeeks = format(subDays(today, 21), dateFormat); // 21 days
const twoWeeks = format(subDays(today, 14), dateFormat); // 14 days
const oneWeek = format(subDays(today, 7), dateFormat); // 7 days
type Anchor = "top" | "left" | "bottom" | "right";

const App: React.FC = () => {
    const [userName, setUserName] = useState("");
    const [isAuth, setIsAuth] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [darkmode, setDarkmode] = useState(true);
    const [isOpen, setisOpen] = useState({ left: false });
    const classes = useStyles();
    useEffect(() => {
        firebase.auth().onAuthStateChanged((user: any) => {
            if (user && user.displayName) {
                setUserName(user.displayName);
                setIsAuth(true);
                setIsLoading(false);
            } else {
                setIsAuth(false);
                setIsLoading(false);
            }
        });
        return () => {};
    }, []);

    if (isLoading) {
        return (
            <div>
                <Spinner animation="grow" variant="success" />
                Loading...
            </div>
        );
    }
    const toggleDrawer = (anchor: Anchor, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event &&
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" ||
                (event as React.KeyboardEvent).key === "Shift")
        ) {
            return;
        }

        setisOpen({ ...isOpen, [anchor]: open });
    };

    console.log(process.env.REACT_APP_ENV);
    return (
        <Router>
            <div key="left">
                <AppBar position="static" className={classes.color}>
                    <Toolbar className={classes.positioning}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer("left", true)}>
                            <MenuIcon />
                        </IconButton>
                        <SearchBar />
                    </Toolbar>
                </AppBar>
                <SwipeableDrawer
                    anchor="left"
                    open={isOpen["left"]}
                    onClose={toggleDrawer("left", false)}
                    onOpen={toggleDrawer("left", true)}>
                    <div
                        style={{
                            backgroundColor: "#4c9999",
                            height: "100%",
                            flexDirection: "column",
                        }}>
                        <Link to="/dashboard">
                            <div className={classes.link}>Dashboard</div>
                        </Link>
                        <Link to="/shared-pool-chats">
                            <div className={classes.link}>Shared Pool Chats</div>
                        </Link>
                        <Link to="/chats">
                            <div className={classes.link}>Chats</div>
                        </Link>
                        <Link to="/shared-talent-pool">
                            <div className={classes.link}>Shared Talent Pool</div>
                        </Link>
                        <Link to="/predictions">
                            <div className={classes.link}>Prediction Analytics</div>
                        </Link>
                        <Link to="/restaurants">
                            <div className={classes.link}>Restaurants</div>
                        </Link>
                        <Link to="/positions">
                            <div className={classes.link}>Positions</div>
                        </Link>
                        <Link to="/users">
                            <div className={classes.link}>Users</div>
                        </Link>
                        <Link to="/tracking">
                            <div className={classes.link}>Tracking</div>
                        </Link>
                        <Link to="/kpis">
                            <div className={classes.link}>KPIs</div>
                        </Link>
                        <Link to="/forms">
                            <div className={classes.link}>Forms</div>
                        </Link>
                    </div>
                </SwipeableDrawer>
                <Switch>
                    <Route path="/login">
                        <SignInScreen>
                            <div className="App">
                                <header
                                    className={
                                        darkmode ? "App-header-dark-mode" : "App-header"
                                    }>
                                    <img src={logo} className="App-logo" alt="logo" />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={
                                            darkmode
                                                ? {
                                                      backgroundColor: "#fff",
                                                      color: "#282c34",
                                                  }
                                                : { backgroundColor: "#282c34" }
                                        }
                                        onClick={() => setDarkmode(!darkmode)}>
                                        {darkmode ? "Light mode" : "Dark mode"}
                                    </Button>
                                </header>
                            </div>
                        </SignInScreen>
                    </Route>
                    <PrivateRoute path="/dashboard" exact isAuth={isAuth}>
                        <Dashboard />
                    </PrivateRoute>
                    <PrivateRoute path="/chats" exact isAuth={isAuth}>
                        <Chats />
                    </PrivateRoute>
                    <PrivateRoute path="/shared-pool-chats" exact isAuth={isAuth}>
                        <SharedPoolChatContainer />
                    </PrivateRoute>
                    <PrivateRoute path="/shared-talent-pool" exact isAuth={isAuth}>
                        <SharedTalentPool />
                    </PrivateRoute>
                    <PrivateRoute path="/predictions" exact isAuth={isAuth}>
                        <ChatAnalytics />
                    </PrivateRoute>
                    <PrivateRoute path="/restaurants" exact isAuth={isAuth}>
                        <Restaurants />
                    </PrivateRoute>
                    <PrivateRoute path="/positions" exact isAuth={isAuth}>
                        <Positions />
                    </PrivateRoute>
                    <PrivateRoute path="/tracking" exact isAuth={isAuth}>
                        <Tracking />
                    </PrivateRoute>

                    <PrivateRoute path="/users/:userId" exact isAuth={isAuth}>
                        <User />
                    </PrivateRoute>
                    <PrivateRoute path="/restaurants/add" exact isAuth={isAuth}>
                        <AddRestaurant />
                    </PrivateRoute>
                    <PrivateRoute path="/restaurants/:restaurantId" exact isAuth={isAuth}>
                        <Restaurant />
                    </PrivateRoute>
                    <PrivateRoute path="/users" exact isAuth={isAuth}>
                        <Users />
                    </PrivateRoute>
                    <PrivateRoute path="/kpis" exact isAuth={isAuth}>
                        <Kpis />
                    </PrivateRoute>
                    <PrivateRoute path="/applicants/:funnelId" exact isAuth={isAuth}>
                        <Applicants />
                    </PrivateRoute>
                    <PrivateRoute path="/forms/:templateId" exact isAuth={isAuth}>
                        <FormScreen />
                    </PrivateRoute>
                    <PrivateRoute path="/forms" exact isAuth={isAuth}>
                        <Forms />
                    </PrivateRoute>
                </Switch>
            </div>
        </Router>
    );
};

export default App;

const useStyles = makeStyles(() =>
    createStyles({
        color: {
            background: "#4c9999",
            boxShadow: "none",
        },
        positioning: { display: "flex", justifyContent: "space-between" },
        link: {
            color: "#fff",
            padding: "8px 16px",
        },
    }),
);
