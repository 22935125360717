import { useEffect, useState } from "react";
import * as firebase from "firebase";

import { annaformFirebase } from "../firebase/index";

const { firestore } = annaformFirebase;

const COLLECTIONS = {
    TEMPLATES: "templates",
};

export const getFormTemplate = (templateId: string) =>
    firestore.collection(COLLECTIONS.TEMPLATES).doc(templateId);
export const getFormTemplates = () => firestore.collection(COLLECTIONS.TEMPLATES);

export const updateFormTemplate = async (templateId: string, formTemplate: any) => {
    try {
        await firestore
            .collection(COLLECTIONS.TEMPLATES)
            .doc(templateId)
            .set(
                {
                    ...formTemplate,
                    updateTime: firebase.firestore.FieldValue.serverTimestamp(),
                },
                {
                    merge: true,
                },
            );
    } catch (error) {
        console.error(error);
        return error;
    }
};

export function useGetFormTemplates() {
    const [templates, setData] = useState<any>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchFormTemplates = async () => {
            const snapshot = await getFormTemplates().get();

            if (!snapshot.docs) {
                setData([]);
            } else {
                setData(snapshot.docs.map(doc => doc.data()));
            }
            setLoading(false);
        };
        fetchFormTemplates();
    }, []);

    return { templates, loading };
}

export function useGetFormTemplate(templateId) {
    const [template, setData] = useState<any>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchFormTemplate = async () => {
            const doc = await getFormTemplate(templateId).get();

            if (!doc.data()) {
                setData(undefined);
            } else {
                setData({
                    id: doc.id,
                    ...doc.data(),
                });
            }
            setLoading(false);
        };
        fetchFormTemplate();
    }, [templateId]);

    return { template, loading };
}

export function useUpdateFormTemplate(templateId: string) {
    const setFormTemplate = async (
        formTemplate: any,
        onSuccess: () => void,
        onError: () => void,
    ) => {
        console.log("now??");

        try {
            await updateFormTemplate(templateId, formTemplate);
            onSuccess();
        } catch (error) {
            onError();
        }
    };

    return [setFormTemplate];
}
