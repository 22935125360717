import React from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import { useGetFormTemplate, useUpdateFormTemplate } from "../../hooks/useFormTemplate";
import FormGraph from "./FormGraph";

function FormScreen() {
    const { templateId } = useParams();
    const { template, loading } = useGetFormTemplate(templateId);
    const [updateTemplateFunc] = useUpdateFormTemplate(templateId);

    if (loading) {
        return (
            <div>
                <Spinner animation="grow" variant="success" />
                Loading...
            </div>
        );
    }

    if (template) {
        return <FormGraph formConfig={template} updateFunc={updateTemplateFunc} />;
    }

    return <div>Template was not found or there was an error</div>;
}

export default FormScreen;
