import { useEffect, useState } from "react";
import { FirestoreCollections } from "../enums/FirebaseCollections";
import { firestore } from "../firebase/configure";
import { fetchData } from "../helpers/dataLoading";

/**
 * Retrieves all applications
 */
export function useGetAllApplications(appliedAfterInDays: number) {
    const [applications, setData] = useState<Contracts.DBUserViewedApplication[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        if (appliedAfterInDays) {
            const data = await fetchData("getAllApplications", { appliedAfterInDays });
            setData(data);
        }
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, [appliedAfterInDays]);

    return { loading, applications };
}

export function useGetApplicationById(
    applicationId: string | null,
): { application: With.Id<Contracts.DBApplicationShape> | null } {
    const [application, setApplication] = useState<With.Id<
        Contracts.DBApplicationShape
    > | null>(null);

    const loadData = async () => {
        if (applicationId) {
            const doc = await firestore
                .collection(FirestoreCollections.APPLICATIONS)
                .doc(applicationId)
                .get();
            setApplication({
                id: doc.id,
                ...doc.data(),
            } as With.Id<Contracts.DBApplicationShape>);
        }
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, [applicationId]);

    return { application };
}

/**
 * Retrieves all applications for a restaurant
 */
export function useGetApplicationsByUserToken(
    userToken: string,
): { loading: boolean; data: Contracts.DBUserViewedApplication[] } {
    const [data, setData] = useState<Contracts.DBUserViewedApplication[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        if (userToken) {
            const data = await fetchData("userTestApplications", {
                userToken,
            });
            setData(data);
        }
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, [userToken]);

    return { loading, data };
}

/**
 * Retrieves all applications for a restaurant
 */
export function useGetSharedPoolApplications(): {
    loading: boolean;
    sharedPoolApplications: Contracts.DBSharedPoolApplication[];
} {
    const [sharedPoolApplications, setData] = useState<
        Contracts.DBSharedPoolApplication[]
    >([]);
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        setLoading(true);
        const data = await fetchData("sharedPoolApplications");
        setData(data || []);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, sharedPoolApplications };
}

export type Gender = "male" | "female" | null;

// export enum ApplicationTypeValue {
//     Administrative = "Administrative",
//     Baker = "Baker",
//     Bakery = "Bakery",
//     Bread = "Bread",
//     EventCommunication = "Events & Communications",
//     Host = "Host",
//     Pastry = "Pastry",
//     Brewery = "Brewery",
//     Dishwasher = "Dishwasher",
//     FrontofHouse = "Front of House",
//     InternshipBoh = "Internship - Kitchen",
//     InternshipFoh = "Internship - Front of House",
//     internshipBakery = "Internship - Bakery",
//     internshipBread = "Internship - Bread",
//     internshipPastry = "Internship - Pastry",
//     Kitchen = "Kitchen",
//     CrewMember = "Crew Member",
//     Management = "Management",
//     Under18 = "U18",
//     Favourite = "favourite",
// }
