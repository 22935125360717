import React from "react";
// @ts-ignore
import {
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Divider,
    Tooltip,
    Button,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AnnaHand from "../../../assets/anna.jpg";
import { formatDistanceToNow, format } from "date-fns";
import { getActionStateColor, formatDateTime } from "../../../lib/utils";

export default function Message({
    id,
    createTime,
    type,
    authorMetadata,
    funnel,
    message,
    action,
}: any) {
    const date = formatDateTime(createTime._seconds);
    const isAnna = type !== "USER_MESSAGE" || authorMetadata.source === "SYSTEM";
    const isApplicant = authorMetadata.source === "EXTERNAL" ? funnel : "";
    const primary = `${message} ${
        authorMetadata.displayName ? " - (" + authorMetadata.displayName + ")" : ""
    }`;

    return (
        <>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Tooltip key={id} title={id} placement="top" interactive>
                        <Avatar
                            alt="Remy Sharp"
                            src={isAnna ? AnnaHand : authorMetadata.image}
                        />
                    </Tooltip>
                </ListItemAvatar>
                <ListItemText
                    primary={<div>{primary}</div>}
                    secondary={
                        <React.Fragment>
                            <Button
                                size="small"
                                variant="contained"
                                color={getActionStateColor(action)}>
                                {action}
                            </Button>
                            <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary">
                                {isAnna ? "Anna" : authorMetadata.displayName}
                            </Typography>
                            {" - "}
                            {formatDistanceToNow(date, { addSuffix: true })}{" "}
                            {format(date, "- (HH:mm)")}
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
        </>
    );
}
