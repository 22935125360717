import React from "react";
import { Color } from "../../enums/Colors";
import { Grid } from "@material-ui/core";
import {
    useGetLastWeekRatioFunnels,
    useGetOverallFunnelsStats,
} from "../../hooks/useGetOverallStats";
import { keyBy } from "lodash";
import { ApplicationState } from "../../types/fixedTypes";
import KpiCard from "./KpiCard";

export default function OverallFunnelsStats() {
    const { loading, overallStats } = useGetOverallFunnelsStats();
    const { lastWeekStatsLoading, lastWeekStats } = useGetLastWeekRatioFunnels();

    if (loading || lastWeekStatsLoading) {
        return (
            <>
                <Grid item md={3} xs={12}>
                    <KpiCard title="Loading..." color={Color.veryLightBorder} value={0} />
                </Grid>
                <Grid item md={3} xs={12}>
                    <KpiCard title="Loading..." color={Color.veryLightBorder} value={0} />
                </Grid>
                <Grid item md={3} xs={12}>
                    <KpiCard title="Loading..." color={Color.veryLightBorder} value={0} />
                </Grid>
                <Grid item md={3} xs={12}>
                    <KpiCard title="Loading..." color={Color.veryLightBorder} value={0} />
                </Grid>
            </>
        );
    }
    const applied = overallStats.reduce((sum, current) => sum + current.applications, 0);
    const lastWeekStatsApplied = lastWeekStats.reduce(
        (sum, current) => sum + current.applications,
        0,
    );
    const statsByState = keyBy(overallStats, "state");
    const lastWeekStatsByState = keyBy(lastWeekStats, "state");
    const hired =
        (statsByState[ApplicationState.HIRED]?.applications || 0) +
        (statsByState[ApplicationState.CONTRACT_PENDING]?.applications || 0);
    const lastWeekStatsHired =
        (lastWeekStatsByState[ApplicationState.HIRED]?.applications || 0) +
        (lastWeekStatsByState[ApplicationState.CONTRACT_PENDING]?.applications || 0);
    const rejected = statsByState[ApplicationState.REJECTED]?.applications || 0;
    const lastWeekStatsRejected =
        lastWeekStatsByState[ApplicationState.REJECTED]?.applications || 0;
    const stale = applied - (hired + rejected);
    // RATIOS
    const rejectRatio = (rejected / applied) * 100;
    const hireRatio = (hired / applied) * 100;
    const staleRatio = (stale / applied) * 100;

    // Last week diffs
    const staleLastWeek =
        lastWeekStatsApplied - (lastWeekStatsHired + lastWeekStatsRejected);

    const applicationsDiff = applied - lastWeekStatsApplied;
    const rejectionsDiff = rejected - lastWeekStatsRejected;
    const hiresDiff = hired - lastWeekStatsHired;
    const staleDiff = stale - staleLastWeek;

    // last week diffs ratios
    // RATIOS
    const rejectRatioDiff =
        (lastWeekStatsRejected / lastWeekStatsApplied) * 100 - rejectRatio;
    const hireRatioDiff = (lastWeekStatsHired / lastWeekStatsApplied) * 100 - hireRatio;
    const staleRatioDiff = (staleLastWeek / lastWeekStatsApplied) * 100 - staleRatio;

    return (
        <>
            <Grid item md={3} xs={12}>
                <KpiCard
                    title="Applications"
                    value={applied}
                    footer="Total applications in the system"
                    growth={applicationsDiff}
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <KpiCard
                    title="Rejected"
                    color={Color.danger}
                    value={rejected}
                    growth={rejectionsDiff}
                    footer="Applications that are currently in Rejected state"
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <KpiCard
                    title="Hired"
                    color={Color.cucumber}
                    value={hired}
                    growth={hiresDiff}
                    footer="Applications that are currently in Hired/Contract Pending state"
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <KpiCard
                    title="Stale/working on"
                    color={Color.vanilla}
                    value={stale}
                    growth={staleDiff}
                    footer="Applications that are currently in between Applied and Hired/Rejected state"
                />
            </Grid>
            {/* RATIOS */}
            <Grid item md={3} xs={12}>
                <KpiCard
                    title="Applications"
                    footer="Total applications in the system"
                    value={100}
                    percentageValue
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <KpiCard
                    title="Rejected ratio"
                    color={Color.danger}
                    value={rejectRatio}
                    percentageValue
                    growth={rejectRatioDiff}
                    footer="Percentage of applications that were rejected"
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <KpiCard
                    title="Hired ratio"
                    color={Color.cucumber}
                    value={hireRatio}
                    percentageValue
                    growth={hireRatioDiff}
                    footer="Percentage of applications that were hired"
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <KpiCard
                    title="Stale/working on ratio"
                    color={Color.vanilla}
                    value={staleRatio}
                    percentageValue
                    growth={staleRatioDiff}
                    footer="Percentage of applications that are being worked on"
                />
            </Grid>
        </>
    );
}
