import config from "../config";

export async function fetchData(endpoint: string, body: any = {}) {
    const result = await fetch(`${config.API_BASE_URL}/${endpoint}`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, cors, *same-origin
        headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
            token: "Ejw023ohij3rhu23uhorju23ruig23rguhb2ih3gr2",
            ...body,
        }),
    });
    const data = await result.json();
    console.log({ fetchData: data });

    return data[0];
}
