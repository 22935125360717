import uniqBy from "lodash.uniqby";

const headers = {
    "Content-Type": "application/json",
    // 'Content-Type': 'application/x-www-form-urlencoded',
};

const appRoot = "https://europe-west1-all-anna.cloudfunctions.net";

export async function getData(startDate: string, endDate: string) {
    const localStartDate = localStorage.getItem(startDate);
    const localEndDate = localStorage.getItem(endDate);
    if (!localStartDate || !localEndDate) {
        localStorage.setItem(startDate, startDate);
        localStorage.setItem(endDate, endDate);
    }
    const actionsKey = `${startDate}:${endDate}:actions`;
    if (localStartDate === startDate && localEndDate === endDate) {
        const localData = localStorage.getItem(`${actionsKey}`);
        if (localData) {
            return JSON.parse(localData);
        }
        console.log("SHOUld");
    }
    const result = await fetch(
        "https://europe-west1-all-anna.cloudfunctions.net/testQuery",
        {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            headers,
            body: JSON.stringify({
                token: "Ejw023ohij3rhu23uhorju23ruig23rguhb2ih3gr2",
                startDate,
                endDate,
            }),
        },
    );
    const data = await result.json();
    const dataToStore = uniqBy(data[0], "funnel_id");
    // localStorage.clear();
    localStorage.setItem(`${actionsKey}`, JSON.stringify(dataToStore));
    return dataToStore;
}

export async function getApplicationData(startDate: string, endDate: string) {
    const localStartDate = localStorage.getItem(startDate);
    const localEndDate = localStorage.getItem(endDate);
    if (!localStartDate || !localEndDate) {
        localStorage.setItem(startDate, startDate);
        localStorage.setItem(endDate, endDate);
    }
    const actionsKey = `${startDate}:${endDate}:applications`;
    if (localStartDate === startDate && localEndDate === endDate) {
        const localData = localStorage.getItem(`${actionsKey}`);
        if (localData) {
            return JSON.parse(localData);
        }
    }
    const result = await fetch(
        "https://europe-west1-all-anna.cloudfunctions.net/applicationsByRestaurant",
        {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            headers,
            body: JSON.stringify({
                token: "Ejw023ohij3rhu23uhorju23ruig23rguhb2ih3gr2",
                startDate,
                endDate,
            }),
        },
    );
    const data = await result.json();
    // localStorage.clear();
    localStorage.setItem(`${actionsKey}`, JSON.stringify(data[0]));
    return data[0];
}

export async function updateUserPassword(
    userId: string,
    password: string,
): Promise<null | string> {
    try {
        const result = await fetch(`${appRoot}/updateUserPassword`, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            headers,
            body: JSON.stringify({
                token: "Ejw023ohij3rhu23uhorju23ruig23rguhb2ih3gr2",
                userId,
                password,
            }),
        });
        const status = result.status;

        if (status === 400) {
            const body = await result.json();
            return body?.error || "Bad request";
        }
        return null;
    } catch (error) {
        console.error(error);
        return "Something went wrong";
    }

    // localStorage.clear();
}
