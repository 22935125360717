export const annaId = "tzRpKenzqiYXEqh4sdBX";

export const ExcludedRestaurants = [
    "tzRpKenzqiYXEqh4sdBX", // anna (internal hiring restaurant)
];

export const TestRestaurants = [
    "CjLqp97X9L0WbeX6F3Vx", // Anna test restaurant
    "8IX3EXPeu1RzSuetxcP4", // Investaurant 16
    "ROkmB0lTtwJqNNgHS5Hi", // Investaurant 17
    "9mgzyIEbVb1TvEzBApku", // Investaurant 18
    "pxVnRtQryorpWJjnYPf2", // Investaurant 19
    "57OaV9fImbQSWqBLGE9Y", // Investaurant 20
    "uQc8wIUEWr434XITe36f", // Investaurant 21
    "n0GvXuTsxZgHVKIMkvWo", // Investaurant 22
    "VIRypt68O7YicS7YlNyL", // Investaurant 23
    "1qiGvcujjecl2S1zBfWv", // Investaurant 24
    "DWFW7cKFHIuxks5NVFCp", // Investaurant 25
];

export const ExcludedUsers = [
    "KH1gccsRg9YSvuejCkA15e8cwjF2", // Bartosz
    "m556oo6NpWR8CUOZ9uM30u75c6T2", // DD
    "neaWMsoNFCaMPVI6hI42OfcPabG2", // DD
    "Nl65xFTsJDb3Pw0zs53a9O0g2qj2", // Julieta
    "HvQ4jY5v0aNGyZfkwjOVWF4Z4tH2", // Niels
    "XUVQWH4vi9TMCFwAPdLep5IcXV53", // Roberta
    "eYxiz13vAKVKk03h3hkCXfGGjJg2", // Simon
    "HqzY31anjlQjIOeHD32pP8unbEY2", // Aleks
];
