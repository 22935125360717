import { useEffect, useState } from "react";
import { firestore } from "../firebase/configure";

/**
 *
 * Added the firebase logic like this untill
 * we have a better way to abstract it with sagas for similar
 * Just to keep it outside of the component
 *
 */

export function useGetUserReponsibilities(userId: string) {
    const [userResponsibilities, setUserResponsibilities] = useState<any | null>({});
    useEffect(() => {
        const unsubscribe = firestore
            .collection("users")
            .doc(userId)
            .collection("userPreferences")
            .orderBy("createTime", "desc")
            .limit(1)
            .onSnapshot(
                query => {
                    if (!query.empty && query.docs.length > 0) {
                        const result = query.docs.map(doc => {
                            return {
                                id: doc.id,
                                ...doc.data(),
                            };
                        });
                        setUserResponsibilities(result[0]);
                    }
                },
                err => {
                    console.log(err);
                },
            );
        return () => unsubscribe();
    }, [userId]);

    return userResponsibilities;
}
