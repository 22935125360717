import React from "react";
import { Grid } from "@material-ui/core";
import PositionsTree from "./PositionsTree";
import { usePositions } from "../../hooks/usePositions";
import { Spinner } from "react-bootstrap";

function Positions() {
    const { isLoading, positions } = usePositions();
    console.log({ positions });
    if (isLoading) {
        return (
            <div>
                <Spinner animation="grow" variant="success" />
                Loading...
            </div>
        );
    }
    return (
        <>
            <div style={{ margin: 20 }}>
                <Grid container justify="center">
                    <PositionsTree positions={positions} />
                </Grid>
            </div>
        </>
    );
}

export default Positions;
