import React, { SyntheticEvent, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Select from "@material-ui/core/Select";
import { MenuItem } from "@material-ui/core";
import { supportedLanguages } from "../../helpers/languages";
import { firestore } from "../../firebase/configure";
import { FirestoreCollections } from "../../enums/FirebaseCollections";
import { Intl } from "../../enums/Intl";

function validateForm(form: { id: string; language: Intl; alias: string }) {
    let isValid = true;
    Object.entries(form).forEach(([key, value]) => {
        if (!value) {
            alert(`${key} is not valid`);
            isValid = false;
        }
    });
    return isValid;
}

export default function AddPositionForm({ handleClose }) {
    const [isLoading, setisLoading] = useState(false);
    const [form, setform] = useState({
        id: "",
        language: Intl.en_US,
        alias: "",
    });

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setform({
            ...form,
            language: e.target.value as Intl,
        });
    };
    const handleChangeAlias = (e: React.ChangeEvent<HTMLInputElement>) => {
        setform({
            ...form,
            alias: e.target.value,
        });
    };
    const handleChangeId = (e: React.ChangeEvent<HTMLInputElement>) => {
        setform({
            ...form,
            id: e.target.value.replace(" ", ""),
        });
    };

    const submitLanguage = async () => {
        setisLoading(true);
        let isValid = validateForm(form);

        if (isValid) {
            console.log(isValid, form.language);
            await firestore
                .collection(FirestoreCollections.POSITIONS)
                .doc(form.id)
                .set({ [form.language]: [form.alias.trim()] }, { merge: true });
            handleClose();
        }
        setisLoading(false);
    };
    return (
        <>
            <DialogContent>
                <DialogContentText>Adding new position</DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Position id"
                    type="text"
                    fullWidth
                    value={form.id}
                    onChange={handleChangeId}
                />
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={form.language}
                    // @ts-ignore (weird material UI stuff here ??)
                    onChange={handleChange}>
                    {supportedLanguages.map(language => (
                        <MenuItem key={language.id} value={language.id}>
                            {language.label}
                        </MenuItem>
                    ))}
                </Select>
                <TextField
                    margin="dense"
                    id="name"
                    label="Alias"
                    type="text"
                    fullWidth
                    value={form.alias}
                    onChange={handleChangeAlias}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={submitLanguage} color="primary" disabled={isLoading}>
                    Add
                </Button>
            </DialogActions>
        </>
    );
}
