import React from "react";
import BrowseApplicantsByCategory from "./BrowseApplicantsByCategory";

export default function BrowseSharedPoolByCategory({
    restaurants,
    applications,
    sharedPoolApplications,
}) {
    let restaurantsByCategory = {};
    let metricsByRestaurant = {};
    if (!restaurants) return <>Loading...</>;
    restaurants
        .filter(restaurant => !restaurant?.groupId)
        .map((restaurant: Contracts.DBRestaurantShape) => {
            // const categoryName = restaurant?.category || "N/A";
            const categories = restaurant?.categories || [];
            categories.map((categoryName: string) => {
                if (!restaurantsByCategory[categoryName])
                    restaurantsByCategory[categoryName] = [];
                restaurantsByCategory[categoryName].push(restaurant);
                metricsByRestaurant[restaurant.id] = {
                    restaurant,
                    sharedPoolApplications: [],
                    activeSharedPoolApplications: [],
                    sharedPoolIntroductions: [],
                };
            });
        });

    sharedPoolApplications.map(application => {
        if (application?.restaurantIds?.length > 0) {
            application?.restaurantIds?.map(restaurantId => {
                const strippedRestaurantId = restaurantId.replaceAll('"', "");
                metricsByRestaurant[strippedRestaurantId]?.sharedPoolApplications.push(
                    application,
                );
                if (application?.isActive === "true")
                    metricsByRestaurant[
                        strippedRestaurantId
                    ]?.activeSharedPoolApplications.push(application);
            });
        } else {
            // No active restaurants
            if (!metricsByRestaurant["Generic"]) {
                metricsByRestaurant["Generic"] = {
                    restaurant: {},
                    sharedPoolApplications: [],
                    activeSharedPoolApplications: [],
                    sharedPoolIntroductions: [],
                };
            }
            metricsByRestaurant["Generic"]?.sharedPoolApplications.push(application);
            if (application?.isActive === "true")
                metricsByRestaurant["Generic"]?.activeSharedPoolApplications.push(
                    application,
                );
        }
    });
    return (
        <>
            <BrowseApplicantsByCategory
                restaurants={restaurants}
                applications={applications}
                sharedPoolApplications={sharedPoolApplications}
                restaurantsByCategory={restaurantsByCategory}
            />
        </>
    );
}
