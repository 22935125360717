import React from "react";
import { useGetDailyActiveUsersReport } from "../../hooks/useGetActiveUsersReport";
import LineChart from "./LineChart";

export default function ActiveUsersReportDaily() {
    const { loading, data } = useGetDailyActiveUsersReport();

    if (loading) return null;
    let chartData = [["Day", "Daily Active Users"]];
    let maxActiveUsers = 0;
    let maxActiveUsersDate = "";
    data.map(row => {
        chartData.push([`${row.month}/${row.day}`, row.activeUsers]);
        if (row.activeUsers > maxActiveUsers) {
            maxActiveUsers = row.activeUsers;
            maxActiveUsersDate = row.date.value;
        }
    });
    return (
        <LineChart
            data={chartData}
            title={`Active users per day`}
            subtitle={`Highest users per day is ${maxActiveUsers} on ${maxActiveUsersDate}`}
        />
    );
}
