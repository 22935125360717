import { firestore } from "../firebase/configure";
import { FirestoreCollections } from "../enums/FirebaseCollections";
import * as firebase from "firebase";
import { Positions } from "../types/Positions";
import { RestaurantType } from "../types/fixedTypes";

interface RestaurantCreationStatus {
    readonly success: boolean;
}

export interface RestaurantCreationSuccess extends RestaurantCreationStatus {
    readonly success: true;
}

export interface RestaurantCreationError extends RestaurantCreationStatus {
    readonly success: false;
    readonly message?: string;
}

export async function addRestaurant(
    documentValues: Omit<Contracts.DBRestaurantShape, "id" | "phoneNumber">,
    positions: Positions[],
    keywords: {
        [key in Contracts.AnalyticsType]: string[];
    },
): Promise<RestaurantCreationStatus> {
    try {
        const batch = firestore.batch();

        const restaurantRef = await firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .doc();

        batch.set(restaurantRef, documentValues);

        const positionRef = await firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .doc(restaurantRef.id)
            .collection(FirestoreCollections.RESTAURANT_POSITIONS)
            .doc();

        batch.set(positionRef, {
            createTime: new Date(),
            list: positions,
            snoozeAll: false,
            snoozeSettings: {},
        });

        const trialPreferenceRef = await firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .doc(restaurantRef.id)
            .collection(FirestoreCollections.RESTAURANT_TRIAL_PREFERENECE)
            .doc();

        batch.set(trialPreferenceRef, {
            createTime: new Date(),
            monday: {
                endTimeInMinutes: 1260,
                startTimeInMinutes: 600,
            },
            type: "TRIAL_BOH",
            interval: 660,
        });

        await updateRestaurantKeywords(restaurantRef.id, keywords, batch);

        await batch.commit();

        return { success: true };
    } catch (error) {
        const result: RestaurantCreationError = {
            success: false,
            message: error.message,
        };

        return result;
    }
}

/**
 * USERS
 */

export async function toggleUserActive(
    userId: string,
    isAccountSetup: boolean,
): Promise<boolean | undefined> {
    try {
        await firestore.collection(FirestoreCollections.USERS).doc(userId).update({
            isAccountSetup,
        });
        return true;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}
export async function updateUser(userId: string, data: {}): Promise<boolean | undefined> {
    try {
        await firestore
            .collection(FirestoreCollections.USERS)
            .doc(userId)
            .update({
                ...data,
            });
        return true;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}

export async function updateUserResponsibilities(
    userId: string,
    positions: string[],
): Promise<string | undefined> {
    try {
        const docRef = await firestore
            .collection(FirestoreCollections.USERS)
            .doc(userId)
            .collection(FirestoreCollections.USER_PREFERENECE)
            .add({
                createTime: new Date(),
                responsibilityPreference: positions,
            });
        return docRef.id;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}

export async function addUserToRestaurant(
    restaurantId: string,
    userId: string,
): Promise<boolean | undefined> {
    try {
        await firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .doc(restaurantId)
            .update({
                [`members.${userId}.uid`]: userId,
            });
        return true;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}

export async function removeUserFromRestaurant(
    restaurantId: string,
    userId: string,
): Promise<boolean | undefined> {
    try {
        await firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .doc(restaurantId)
            .update({
                [`members.${userId}`]: firebase.firestore.FieldValue.delete(),
            });
        return true;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}
export async function updateRestaurantConfig({
    restaurantId,
    config,
}: {
    restaurantId: string;
    config: Contracts.RestaurantConfig;
}): Promise<boolean | undefined> {
    try {
        await firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .doc(restaurantId)
            .update({
                config,
            });
        return true;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}

export async function updateRestaurantGroupIdAndName({
    restaurantId,
    groupId,
    groupName,
}: {
    restaurantId: string;
    groupId: string | null;
    groupName: string | null;
}): Promise<boolean | undefined> {
    try {
        await firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .doc(restaurantId)
            .update({
                groupId: groupId,
                groupName: groupName,
            });
        return true;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}

export async function getRestaurantKeywordsByType(
    restaurantId: string,
    keywordsType: Contracts.AnalyticsType,
): Promise<Contracts.DBRestaurantKeywords> {
    try {
        const doc = await firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .doc(restaurantId)
            .collection(FirestoreCollections.ANALYTICS)
            .doc(keywordsType)
            .get();
        return {
            id: doc.id,
            ...doc.data(),
        } as Contracts.DBRestaurantKeywords;
    } catch (error) {
        console.error(
            `Failed to get keywords for keywordsType ${keywordsType} restaurantId ${restaurantId}, Error: ${error}`,
        );
        return error;
    }
}

export async function updateRestaurantCategory(restaurantId: string, category: string) {
    try {
        await firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .doc(restaurantId)
            .update({
                category,
            });
    } catch (error) {
        throw new Error(`Failed to update restaurant category. ${error}`);
    }
}

export async function updateRestaurantCategories(
    restaurantId: string,
    categories: string[],
) {
    try {
        await firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .doc(restaurantId)
            .update({
                categories,
            });
    } catch (error) {
        throw new Error(
            `Failed to update restaurant ${restaurantId} with categories ${categories.join(
                ",",
            )}. ${error}`,
        );
    }
}

export async function setRestaurantIsActive({
    restaurantId,
    isActive,
}: {
    restaurantId: string;
    isActive: boolean;
}) {
    try {
        await firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .doc(restaurantId)
            .update({
                isActive,
            });
    } catch (error) {
        throw new Error(`Failed to update restaurant isActive. ${error}`);
    }
}

export async function updateRestaurantLabels(
    restaurantId: string,
    labels: Contracts.DBRestaurantLabels,
    type: RestaurantType,
) {
    try {
        await firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .doc(restaurantId)
            .update({
                labels: { ...labels, type },
                category: type,
            });
    } catch (error) {
        throw new Error(`Failed to update restaurant labels. ${error}`);
    }
}

export async function updateRestaurantKeywords(
    restaurantId: string,
    allKeywords: {
        [key in Contracts.AnalyticsType]: string[];
    },
    /** Provide if a batch should be use instead of a direct update */
    batch?: firebase.firestore.WriteBatch,
) {
    try {
        for (let type in allKeywords) {
            const analyticsRef = await firestore
                .collection(FirestoreCollections.RESTAURANTS)
                .doc(restaurantId)
                .collection(FirestoreCollections.ANALYTICS)
                .doc(type);

            const keywords = {
                keywords: allKeywords[type],
            } as Contracts.DBRestaurantKeywords;

            if (batch) {
                batch.set(analyticsRef, keywords);
            } else {
                analyticsRef.set(keywords);
            }
        }
    } catch (error) {
        throw new Error(`Failed to update restaurant keywords. ${error}`);
    }
}

export async function updateRestaurantLogo(restaurantId: string, logo: string) {
    try {
        await firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .doc(restaurantId)
            .update({
                logo,
            });
    } catch (error) {
        throw new Error(`Failed to update restaurant logo. ${error}`);
    }
}

/**
 * Updates the list of restaurants that the applicant is eligible for
 */
export async function updateSharedPoolApplicationRestaurants({
    sharedPoolApplicationId,
    restaurantIds,
}): Promise<boolean | undefined> {
    try {
        await firestore
            .collection(FirestoreCollections.SHARED_POOL_APPLICATIONS)
            .doc(sharedPoolApplicationId)
            .set(
                {
                    restaurantIds,
                    updateTime: new Date(),
                },
                { merge: true },
            );
        return true;
    } catch (error) {
        throw new Error(`Failed to update shared pool application. ${error}`);
    }
}
