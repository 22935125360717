import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import RestaurantLabels from "../../Routes/Restaurants/RestaurantLabels";
import { updateRestaurantLabels } from "../../firebase/firestore";
import { getRestaurantTypeForLabels } from "../../helpers/restaurant";
import EditRestaurantsInGroup from "./EditRestaurantsInGroup";

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: "2px solid #000",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    }),
);

export default function EditMicroPool({
    groupId,
    groupName,
    restaurants,
    allRestaurants,
}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <EditIcon onClick={handleOpen} />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">Update {groupName}</h2>
                        <p id="transition-modal-description">
                            <EditRestaurantsInGroup
                                groupId={groupId}
                                groupName={groupName}
                                restaurants={restaurants}
                                allRestaurants={allRestaurants}
                            />
                        </p>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
