import { useEffect, useState } from "react";
import { fetchData } from "../helpers/dataLoading";

export function useGetDailyActiveUsersReport(): any | {} {
    const [data, setData] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("dailyAppUsageStats");
        setData(data);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, data };
}

export function useGetWeeklyActiveUsersReport(): any | {} {
    const [data, setData] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("weeklyActiveUsersReport");
        setData(data);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, data };
}

export function useGetMonthlyActiveUsersReport(): any | {} {
    const [data, setData] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("monthlyActiveUsersReport");
        setData(data);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, data };
}

export function useGetRollingMonthlyActiveUsersReport(): any | {} {
    const [lastMonthActiveUsers, setData] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("rollingMonthlyActiveUsersReport");
        setData(data);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, lastMonthActiveUsers };
}
