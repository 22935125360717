import { useEffect, useState } from "react";
import { fetchData } from "../helpers/dataLoading";

export function useGetOverallStats(): any | {} {
    const [stats, setStates] = useState<{}>({});
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("ratioAppliedRejectedHired");
        setStates(data[0]);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, stats };
}

export function useGetOverallFunnelsStats(): any | {} {
    const [overallStats, setStats] = useState<{}>({});
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("funnelsGroupedByState");
        setStats(data);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, overallStats };
}

export function useGetLastWeekRatio(): any | {} {
    const [lastWeekStats, setStates] = useState<{}>({});
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("lastWeekRatio");
        setStates(data[0]);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, lastWeekStats };
}

export function useGetLastWeekRatioFunnels(): any | {} {
    const [lastWeekStats, setStats] = useState<{}>({});
    const [lastWeekStatsLoading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("lastWeekRatioFunnels");
        setStats(data);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { lastWeekStatsLoading, lastWeekStats };
}
