import React, { useState } from "react";
import SharedTalentPoolOverview from "./Dashboard";
import {
    useGetAllApplications,
    useGetSharedPoolApplications,
} from "../../hooks/useGetApplications";
import { useGetRestaurants } from "../../hooks/useGetRestaurants";
import BrowseSharedPoolByCategory from "./BrowseSharedPool";
import { useGetRejectedApplicationsReport } from "../../hooks/useGetRejectedApplications";
import { useGetApplicationsByRestaurantReport } from "../../hooks/useGetApplicantsReport";
import MicroPoolsOverview from "./MicroPoolsDashboard";
import { useGetUsersMap } from "../../hooks/useGetUsers";
import { User } from "../../types/User";
import SharedPoolIntroductionsOverview from "./SharedPoolIntroductions";

function renderTab({
    tabKey,
    restaurants,
    applications,
    applicationsByRestaurant,
    rejectedByRestaurant,
    sharedPoolApplications,
    users,
}: {
    tabKey: number;
    restaurants: With.Id<Contracts.DBRestaurantShape>[];
    applications: Contracts.DBUserViewedApplication[];
    applicationsByRestaurant: any[]; // FIXME Add type
    rejectedByRestaurant: any[]; // FIXME Add type
    sharedPoolApplications: Contracts.DBSharedPoolApplication[];
    users: {
        [userId: string]: User;
    };
}) {
    switch (tabKey) {
        case 1:
            return <MicroPoolsOverview restaurants={restaurants} />;

        case 2:
            return (
                <BrowseSharedPoolByCategory
                    restaurants={restaurants}
                    applications={applications}
                    sharedPoolApplications={sharedPoolApplications}
                />
            );

        case 3:
            return <SharedPoolIntroductionsOverview restaurants={restaurants} />;

        default:
            return (
                <SharedTalentPoolOverview
                    applicationsByRestaurant={applicationsByRestaurant}
                    rejectedByRestaurant={rejectedByRestaurant}
                    restaurants={restaurants}
                    sharedPoolApplications={sharedPoolApplications}
                    users={users}
                />
            );
    }
}

const buttonStyle = {
    border: "none",
    padding: "8px 16px",
    outline: "none",
    borderRadius: "8px 8px 0px 0px",
};

const buttonActiveStyle = {
    backgroundColor: "#3f50b5",
    color: "white",
};

export default function SharedTalentPool() {
    const [tabKey, setTabKey] = useState(0);
    const restaurants: With.Id<Contracts.DBRestaurantShape>[] = useGetRestaurants(true);
    const { applicationsByRestaurant } = useGetApplicationsByRestaurantReport();
    const { rejectedByRestaurant } = useGetRejectedApplicationsReport();
    const { sharedPoolApplications } = useGetSharedPoolApplications();
    const users = useGetUsersMap();
    // Fetch all applications within the last 2 months
    const { loading, applications } = useGetAllApplications(60);

    if (loading) return <>Loading...</>;

    return (
        <>
            <div style={{ borderBottom: "1px solid #333", padding: "8px 8px 0px" }}>
                <button
                    style={
                        tabKey === 0
                            ? { ...buttonStyle, ...buttonActiveStyle }
                            : buttonStyle
                    }
                    onClick={() => setTabKey(0)}>
                    Dashboard
                </button>
                <button
                    style={
                        tabKey === 1
                            ? { ...buttonStyle, ...buttonActiveStyle }
                            : buttonStyle
                    }
                    onClick={() => setTabKey(1)}>
                    Internal Groups
                </button>
                <button
                    style={
                        tabKey === 2
                            ? { ...buttonStyle, ...buttonActiveStyle }
                            : buttonStyle
                    }
                    onClick={() => setTabKey(2)}>
                    Browse Shared Pool
                </button>
                <button
                    style={
                        tabKey === 3
                            ? { ...buttonStyle, ...buttonActiveStyle }
                            : buttonStyle
                    }
                    onClick={() => setTabKey(3)}>
                    Shared Pool Introductions
                </button>
            </div>
            {renderTab({
                tabKey,
                restaurants,
                applicationsByRestaurant,
                rejectedByRestaurant,
                sharedPoolApplications,
                applications,
                users,
            })}
        </>
    );
}
