import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { updateRestaurantLogo } from "../firebase/firestore";
import Files from "../services/files";
import { ImageUploadType } from "../types/fixedTypes";

export function useRestaurantLogo(restaurantId?: string) {
    const [logo, setLogo] = useState<{ file: File; url: string }>();
    const [logoUploading, setLogoUploading] = useState(false);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: ["image/jpeg", "image/jpg", "image/png"],
        multiple: false,
    });

    const upload = async (logo: File, withRestaurantLogoUpdate: boolean = false) => {
        setLogoUploading(true);

        const result = await Files.uploadImageWithCompression(
            logo as File,
            ImageUploadType.RESTAURANT_LOGO,
        );

        if (withRestaurantLogoUpdate && restaurantId && result.isSuccess) {
            await updateRestaurantLogo(restaurantId, result.url);
        }

        setLogoUploading(false);

        return result;
    };

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            setLogo({
                file,
                url: URL.createObjectURL(file),
            });
        }
    }, [acceptedFiles]);

    return {
        picker: {
            logo,
            getRootProps,
            getInputProps,
        },
        upload,
        logoUploading,
    };
}
