import React from "react";
import {
    makeStyles,
    withStyles,
    useTheme,
    Theme,
    createStyles,
} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { formatDateTime } from "../../lib/utils";
import { mediumLocalDateAndTimeFormat } from "../../helpers/dateTime";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { differenceInHours, subDays } from "date-fns";
import { Box, Button, Collapse, Icon, Typography } from "@material-ui/core";
import { useGetApplicationById } from "../../hooks/useGetApplications";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            margin: 20,
            // maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: "2px solid #000",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            maxHeight: "90vh",
            overflow: "auto",
        },
        button: {
            margin: theme.spacing(1),
        },
    }),
);
interface Props {
    restaurant?: Contracts.DBRestaurantShape;
    sharedPoolIntroductions: Contracts.DBSharedPoolIntroduction[];
}

const useStyles1 = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
        },
    }),
);

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page">
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page">
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page">
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page">
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

const useStyles2 = makeStyles({
    table: {
        width: "100%",
    },
});

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }),
)(TableRow);

function Row({
    sharedPoolIntroduction,
}: {
    sharedPoolIntroduction: Contracts.DBSharedPoolIntroduction;
}) {
    const [open, setOpen] = React.useState(false);
    const {
        application,
    }: {
        application: With.Id<Contracts.DBApplicationShape> | null;
    } = useGetApplicationById(open ? sharedPoolIntroduction.applicationId : null);
    console.log({ application });
    return (
        <React.Fragment>
            <StyledTableRow>
                <StyledTableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell align="center">
                    {sharedPoolIntroduction.appliedRestaurantMeta_title}
                </StyledTableCell>
                <StyledTableCell align="center">
                    <div>
                        {sharedPoolIntroduction?.firstName}
                        {", "}
                        &nbsp;
                        {sharedPoolIntroduction?.lastName}
                    </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                    {sharedPoolIntroduction.introductionUserMeta_userName}
                </StyledTableCell>
                <StyledTableCell align="right">
                    {sharedPoolIntroduction?.position} /
                    {sharedPoolIntroduction?.positionBoh ||
                        sharedPoolIntroduction?.positionFoh}{" "}
                    /{sharedPoolIntroduction?.workHours}
                </StyledTableCell>
                <StyledTableCell align="right">
                    {mediumLocalDateAndTimeFormat(
                        subDays(formatDateTime(sharedPoolIntroduction?.expireTime), 30),
                    )}
                </StyledTableCell>
                <StyledTableCell align="right">
                    {mediumLocalDateAndTimeFormat(
                        formatDateTime(sharedPoolIntroduction?.requestTime),
                    )}
                </StyledTableCell>
                <StyledTableCell align="right">
                    {sharedPoolIntroduction.isActive === "true"
                        ? "Pending"
                        : mediumLocalDateAndTimeFormat(
                              formatDateTime(sharedPoolIntroduction?.updateTime),
                          )}
                </StyledTableCell>
                <StyledTableCell align="right">
                    {sharedPoolIntroduction.isActive === "true"
                        ? "Pending"
                        : sharedPoolIntroduction.communicationState ===
                          "CONTACTED_PENDING"
                        ? "Auto-expired"
                        : `${differenceInHours(
                              formatDateTime(sharedPoolIntroduction?.updateTime),
                              formatDateTime(sharedPoolIntroduction?.requestTime),
                          )} h`}
                </StyledTableCell>
                <StyledTableCell align="center">
                    {sharedPoolIntroduction.communicationState}
                </StyledTableCell>
                <StyledTableCell align="center">
                    {sharedPoolIntroduction.isActive === "true" ? "Active" : "Expired"}
                </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                More Info
                            </Typography>
                            <Table aria-label="applicant">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Self Intro</StyledTableCell>
                                        <StyledTableCell>Work Experience</StyledTableCell>
                                        <StyledTableCell>Email</StyledTableCell>
                                        <StyledTableCell>Phone Number</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell align="right">
                                            {
                                                application?.answers
                                                    ?.application_self_intro?.content
                                            }
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {
                                                application?.answers
                                                    ?.application_work_experience?.content
                                            }
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {
                                                application?.answers?.application_email
                                                    ?.content
                                            }
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {
                                                application?.answers
                                                    ?.application_phone_number?.content
                                            }
                                        </StyledTableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </StyledTableCell>
            </StyledTableRow>
        </React.Fragment>
    );
}
function IntroductionsTable({ sharedPoolIntroductions }) {
    const classes = useStyles2();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    if (sharedPoolIntroductions?.length <= 0) return <>No data...</>;
    const emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, sharedPoolIntroductions.length - page * rowsPerPage);

    const handleChangePage = (
        // @ts-ignore
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} stickyHeader aria-label="Shared Talent Pool">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="right"></StyledTableCell>
                        <StyledTableCell align="right">Applied To</StyledTableCell>
                        <StyledTableCell>Applicant</StyledTableCell>
                        <StyledTableCell>Requested By</StyledTableCell>
                        <StyledTableCell>Position</StyledTableCell>
                        <StyledTableCell align="right">Applied Time</StyledTableCell>
                        <StyledTableCell align="right">Request Time</StyledTableCell>
                        <StyledTableCell align="right">Response Time</StyledTableCell>
                        <StyledTableCell align="right">
                            Time taken by applicant (hours)
                        </StyledTableCell>
                        <StyledTableCell align="right">Response</StyledTableCell>
                        <StyledTableCell align="right">Status</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? sharedPoolIntroductions.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage,
                          )
                        : sharedPoolIntroductions
                    ).map(sharedPoolIntroduction => (
                        <Row
                            key={sharedPoolIntroduction.id}
                            sharedPoolIntroduction={sharedPoolIntroduction}></Row>
                    ))}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <StyledTableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                            colSpan={3}
                            count={sharedPoolIntroductions.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { "aria-label": "rows per page" },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}

export default function ViewRestaurantIntroductions({
    restaurant,
    sharedPoolIntroductions,
}: Props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Button
                onClick={handleOpen}
                variant={sharedPoolIntroductions?.length > 0 ? "contained" : "outlined"}
                color="primary"
                className={classes.button}>
                {sharedPoolIntroductions?.length || 0}
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">
                            {sharedPoolIntroductions?.length || 0} Introductions{" "}
                            {restaurant?.title ? `for ${restaurant?.title}` : ``}
                        </h2>
                        <div id="transition-modal-description">
                            <IntroductionsTable
                                sharedPoolIntroductions={sharedPoolIntroductions}
                            />
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
