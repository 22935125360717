import { ConditionOp, FieldActionType, FieldRef, FieldType } from "./fixedTypes";

export default {
    id: "_a_anna",
    title: "Annaform",
    fields: [
        {
            id: "cY1AKL0dwxhI", // TODO Add random generator for these ids
            title: "What is your full name?",
            ref: FieldRef.application_fullname,
            type: FieldType.applicantName,
            validations: { required: true },
        },
        {
            id: "F4ojF19MV52r",
            title: "Where would you like to apply?",
            ref: FieldRef.application_position,
            type: FieldType.select,
            validations: {
                required: true,
            },
            properties: {
                options: [
                    {
                        ref: "2d75e1150b225812",
                        label: "Kitchen",
                    },
                    {
                        ref: "8eccdfe08efda55d",
                        label: "Front of House",
                    },
                    {
                        ref: "8daf3d8b-5a85-4a23-87fa-896c97bd3486",
                        label: "Bakery",
                    },
                    {
                        ref: "99484365-b735-49ba-a60d-8fc7a68cddab",
                        label: "U18 team member (select if between 16-18 years old)",
                    },
                    {
                        ref: "31259997-e0d6-49ae-be6b-7b1ebdcd0f60",
                        label: "Administrative",
                    },
                    {
                        ref: "92f01eef-d184-4a09-9832-437e2f6c6895",
                        label: "Internship - Kitchen",
                    },
                    {
                        ref: "05f55aeb-4581-4559-bb0c-15716de00c47",
                        label: "Internship - Front of House",
                    },
                    {
                        ref: "d09e8add-8275-4eb4-9ced-80c6944a52cf",
                        label: "Crew Member",
                    },
                ],
            },
        },
        {
            id: "u7Wp1UIrMprx",
            title: "Which *kitchen* position are you applying for?",
            ref: FieldRef.application_position_boh,
            type: FieldType.select,
            validations: {
                required: true,
            },
            properties: {
                options: [
                    {
                        ref: "6659da29-48ce-44c0-b424-906b74ec3b2a",
                        label: "Cook",
                    },
                    {
                        ref: "cd09513f-115f-4226-af10-4b9fd7447da9",
                        label: "Sous Chef",
                    },
                    {
                        ref: "2f99eeba-a021-4e57-a9ef-77048a62ed5c",
                        label: "Head Chef",
                    },
                    {
                        ref: "21fa3b57-ef5f-4faf-8ddc-42a5b7bb1dc0",
                        label: "Dishwasher",
                    },
                ],
            },
        },
        {
            id: "wiK6DzdkRegG",
            title: "Which *front of house* position are you applying for?",
            ref: FieldRef.application_position_foh,
            type: FieldType.select,
            validations: {
                required: true,
            },
            properties: {
                options: [
                    {
                        ref: "1617e455-88ce-4c2f-9a1f-0b9db35f8718",
                        label: "Server",
                    },
                    {
                        ref: "7a9bec88-f460-4233-8693-6d452140298c",
                        label: "Restaurant Manager",
                    },
                ],
            },
        },
        {
            id: "bEWX6jrwYbC4",
            title: "Which *administrative* position are you applying for?",
            ref: FieldRef.application_position_admin,
            type: FieldType.select,
            validations: {
                required: true,
            },
            properties: {
                options: [
                    {
                        id: "viLK4wlv8JSS",
                        ref: "ce53dc18-f60f-478c-b4c5-74068e3463d9",
                        label: "Administrative assistant",
                    },
                    {
                        id: "DrqrS4Ya4QJV",
                        ref: "0bd0fa76-660c-4f74-9c53-f340f3949326",
                        label: "Event coordinator",
                    },
                    {
                        id: "gO4Y8JZon1Xv",
                        ref: "8bcaebc4-0c59-4a55-859f-e3dd24951c02",
                        label: "Janitor",
                    },
                ],
            },
        },
        {
            id: "bEWX6jrwYbC3",
            title:
                "Why would you like to join [restaurant]? Why us and not somewhere else?",
            ref: FieldRef.application_motivation,
            type: FieldType.longText,
            validations: {
                required: true,
            },
            description:
                "Pss...tip: imagine this as your cover letter, the more you write here the more your application is likely to be taken into account.",
        },
        {
            id: "qGS1qAaGFKAu",
            title:
                "Tell us about yourself - what is something you’re good at that we can look forward to?",
            ref: FieldRef.application_self_intro,
            type: FieldType.longText,
            validations: {
                required: true,
            },
        },
        {
            id: "l7rd6GLUASnd",
            title: "Do you want to join us part- or full-time?",
            ref: FieldRef.application_workhours,
            type: FieldType.select,
            validations: {
                required: true,
            },
            properties: {
                options: [
                    {
                        ref: "791fbd95db0f661c",
                        label: "Full-time",
                    },
                    {
                        ref: "b914d79d41515aab",
                        label: "Part-time",
                    },
                    {
                        ref: "bsq5v70qgoolw43q",
                        label: "Part-time (min 10h - SU)",
                    },
                ],
            },
        },
        {
            id: "mSWabOnBNxO4",
            title: "When are you available to work during the week?",
            ref: FieldRef.application_shift_availability,
            type: FieldType.availability,
            validations: { required: true },
            description: "Select at least one option in shifts and one in days.",
        },
        {
            id: "Et0Arf4DNGjA",
            title: "Where do you currently live?",
            ref: FieldRef.application_location,
            type: FieldType.select,
            validations: { required: true },
            properties: {
                options: [{ ref: "44q8fm84ink5loz9", label: "Copenhagen" }],
                allow_other_choice: true,
            },
        },
        {
            id: "Mf1gKBOlM0cH",
            title:
                "From what day are you available, {{field:application_fullname.firstName}}?",
            ref: FieldRef.application_start_date,
            type: FieldType.date,
            validations: {
                required: true,
                minToday: true,
            },
        },
        {
            id: "rLsKTwtA17su",
            title: "Do you have a work permit to work in Denmark?",
            ref: FieldRef.application_visa_type,
            type: FieldType.select,
            validations: { required: true },
            properties: {
                options: [
                    {
                        ref: "c9a7d82bd1804440",
                        label: "Yes - I’m a Danish/European citizen",
                    },
                    {
                        ref: "2e4d1b5bfff40e59",
                        label: "Yes - I have a long-term work visa",
                    },
                    {
                        ref: "81316316-785a-405d-a123-773023950c43",
                        label: "Yes - I have a work holiday visa",
                    },
                    {
                        ref: "fa31e0157b6c1158",
                        label: "No - I do not have a visa",
                    },
                ],
            },
        },
        {
            id: "998ZJ8tkHGeb",
            title:
                "How many hours per week are you allowed to work and when is your visa expiring?",
            ref: FieldRef.application_visa_type_holiday,
            type: FieldType.hoursExpiration,
            validations: { required: true },
        },
        {
            id: "GLYs8Gp0JO2v",
            ref: FieldRef.application_work_experience,
            type: FieldType.workExperience,
            title: "What were the last 3 places that you worked at?",
            validations: {
                required: true,
            },
            description: "Please start from most recent",
        },
        {
            id: "BwWBgiPM9z6g",
            title: "Thanks! How many years have you worked in the hospitality industry?",
            description: "Hospitality includes hotels, restaurants, cafes, bars etc.",
            ref: FieldRef.application_workexperience_total,
            type: FieldType.select,
            validations: {
                required: true,
            },
            properties: {
                options: [
                    {
                        ref: "4bd164cd-af57-4691-9759-5f47c9889bc0",
                        label: "0-1 years",
                    },
                    {
                        ref: "4d592427-5e33-4543-8821-aa7a44964b2c",
                        label: "1-2 years",
                    },
                    {
                        ref: "99b29a98-1fec-4c9c-8778-81d1ccef6f01",
                        label: "2-3 years",
                    },
                    {
                        ref: "9bf08776-490b-4872-9ee9-266af68f63a2",
                        label: "3-4 years",
                    },
                    {
                        ref: "c95abfc1-5545-4acd-b069-5aaf86b9ba5a",
                        label: "4-5 years",
                    },
                    {
                        ref: "24b895a6-2edf-4756-8cb0-073fb52f4195",
                        label: "5+ years",
                    },
                ],
            },
        },
        {
            id: "epOKy5MsQpZm",
            title: "Which languages do you speak well enough to communicate with guests?",
            description: "Choose as many as you like",
            ref: FieldRef.application_spoken_languages,
            type: FieldType.selectMultiple,
            validations: {
                required: true,
            },
            properties: {
                options: [
                    {
                        ref: "a69f835f-64ef-4a27-b4f8-9c9efbc40eb9",
                        label: "English",
                    },
                    {
                        ref: "06e4b2fb-a215-4d9a-be2f-66cd9ba4a133",
                        label: "Danish",
                    },
                    {
                        ref: "66acff30-fdc7-481d-96eb-df48a842ca77",
                        label: "Spanish",
                    },
                    {
                        ref: "d8ae28b3-d8d7-48cd-9194-f116fb701d1a",
                        label: "Italian",
                    },
                ],
            },
        },
        {
            id: "IBKDi1uxPQO3",
            title: "Upload your CV here.",
            description: "Please only upload pdf files. Thanks!",
            ref: FieldRef.application_cv,
            type: FieldType.fileUpload,
            validations: {
                required: true,
            },
        },
        {
            id: "ugDOlIVxJqQy",
            title: "Please, also leave a picture of yourself.",
            ref: FieldRef.application_profile_picture,
            type: FieldType.fileUpload,
            validations: {
                required: true,
            },
        },
        {
            id: "Ieoc0nURdQTB",
            title: "Please leave us your contact details so we can reach you.",
            ref: FieldRef.application_email_phone_number,
            type: FieldType.emailPhone,
            validations: { required: true },
        },
        {
            id: "m3OaL0R34AWY",
            ref: FieldRef.application_shared_pool_consent,
            type: FieldType.sharedTalentPool,
            validations: {
                required: true,
            },
        },
        {
            id: "hEk8ITHSCvnP",
            title: "Thanks! Before we wrap it up, any other things you want to mention?",
            ref: FieldRef.application_comments,
            type: FieldType.longText,
        },
    ],
    logic: [
        {
            type: "field",
            ref: FieldRef.application_position,
            actions: [
                {
                    action: FieldActionType.jump,
                    details: {
                        to: {
                            type: "field",
                            value: FieldRef.application_position_boh,
                        },
                    },
                    condition: {
                        op: ConditionOp.is,
                        vars: [
                            {
                                type: "field",
                                value: "application_position",
                            },
                            {
                                type: "choice",
                                value: "2d75e1150b225812",
                            },
                        ],
                    },
                },
                {
                    action: FieldActionType.jump,
                    details: {
                        to: {
                            type: "field",
                            value: FieldRef.application_position_foh,
                        },
                    },
                    condition: {
                        op: ConditionOp.is,
                        vars: [
                            {
                                type: "field",
                                value: FieldRef.application_position,
                            },
                            {
                                type: "choice",
                                value: "8eccdfe08efda55d",
                            },
                        ],
                    },
                },
                {
                    action: FieldActionType.jump,
                    details: {
                        to: {
                            type: "field",
                            value: FieldRef.application_position_admin,
                        },
                    },
                    condition: {
                        op: ConditionOp.is,
                        vars: [
                            {
                                type: "field",
                                value: FieldRef.application_position,
                            },
                            {
                                type: "choice",
                                value: "31259997-e0d6-49ae-be6b-7b1ebdcd0f60",
                            },
                        ],
                    },
                },
                {
                    action: FieldActionType.jump,
                    details: {
                        to: {
                            type: "field",
                            value: FieldRef.application_motivation,
                        },
                    },
                    condition: {
                        op: ConditionOp.always,
                        vars: [],
                    },
                },
            ],
        },
        {
            type: "field",
            ref: FieldRef.application_position_admin,
            actions: [
                {
                    action: FieldActionType.jump,
                    details: {
                        to: {
                            type: "field",
                            value: FieldRef.application_motivation,
                        },
                    },
                    condition: {
                        op: ConditionOp.always,
                        vars: [],
                    },
                },
            ],
        },
        {
            type: "field",
            ref: FieldRef.application_position_boh,
            actions: [
                {
                    action: FieldActionType.jump,
                    details: {
                        to: {
                            type: "field",
                            value: FieldRef.application_motivation,
                        },
                    },
                    condition: {
                        op: ConditionOp.always,
                        vars: [],
                    },
                },
            ],
        },
        {
            type: "field",
            ref: FieldRef.application_position_foh,
            actions: [
                {
                    action: FieldActionType.jump,
                    details: {
                        to: {
                            type: "field",
                            value: FieldRef.application_motivation,
                        },
                    },
                    condition: {
                        op: ConditionOp.always,
                        vars: [],
                    },
                },
            ],
        },
        {
            type: "field",
            ref: "application_visa_type",
            actions: [
                {
                    action: "jump",
                    details: {
                        to: {
                            type: "field",
                            value: "application_visa_type_holiday",
                        },
                    },
                    condition: {
                        op: "or",
                        vars: [
                            {
                                op: "is",
                                vars: [
                                    {
                                        type: "field",
                                        value: "application_visa_type",
                                    },
                                    {
                                        type: "choice",
                                        value: "81316316-785a-405d-a123-773023950c43",
                                    },
                                ],
                            },
                            {
                                op: "is",
                                vars: [
                                    {
                                        type: "field",
                                        value: "application_visa_type",
                                    },
                                    {
                                        type: "choice",
                                        value: "4b2b4ff95be49c3c",
                                    },
                                ],
                            },
                            {
                                op: "is",
                                vars: [
                                    {
                                        type: "field",
                                        value: "application_visa_type",
                                    },
                                    {
                                        type: "choice",
                                        value: "2e4d1b5bfff40e59",
                                    },
                                ],
                            },
                        ],
                    },
                },
                {
                    action: "jump",
                    details: {
                        to: {
                            type: "field",
                            value: "application_work_experience",
                        },
                    },
                    condition: {
                        op: "always",
                        vars: [],
                    },
                },
            ],
        },
    ],
};
