import * as ElasticAppSearch from "@elastic/app-search-javascript";
import { ApplicationState } from "../types/fixedTypes";

const client = ElasticAppSearch.createClient({
    searchKey: "search-464575tbi9cxv1q9inti8kum",
    endpointBase: "https://anna-search.ent.europe-west3.gcp.cloud.es.io",
    engineName: "production",
    // to ensure the results are fresh (though make it slightly slower)
    cacheResponses: true,
});

interface Props {
    searchTerm: string;
    // filters: {
    //     all: {
    //         user_meta_position?: string | string[];
    //         // user_meta_work_hours?: string | string[];

    //         subscribers?: string[];
    //         restaurant_id?: string;
    //         workhour_filter?: string[];
    //     }[];
    //     none: { state?: ApplicationState[]; subscribers?: string[] }[];
    // };
    page?: {
        size: number;
        current: number;
    };
}

var options = {
    search_fields: {
        user_meta_first_name: {},
        user_meta_position: {},
        user_meta_position_detail: {},
    },
    result_fields: {
        id: { raw: {} },
        user_meta_first_name: { raw: {} },
        user_meta_last_name: { raw: {} },
        user_meta_work_hours: { raw: {} },
        user_meta_position: { raw: {} },
        user_meta_position_detail: { raw: {} },
        user_meta_image: { raw: {} },
        first_subscriber_profile_image: { raw: {} },
        user_meta_visa_type: { raw: {} },
        create_time: { raw: {} },
        update_time: { raw: {} },
        applicant_id: { raw: {} },
        state: { raw: {} },
        restaurant_id: { raw: {} },
        workhour_filter: { raw: {} },
    },
    sort: {
        update_time: "desc",
    },
    // page: {
    //     size: 2,
    //     current: 2,
    // },
};

async function funnelSearch({ searchTerm = "", page }: Props) {
    try {
        const resultMap = await client.search(searchTerm, { ...options, page });

        const data = resultMap.results.map(result => ({
            id: result.getRaw("id"),
            userMetaFirstName: result.getRaw("user_meta_first_name"),
            userMetaLastName: result.getRaw("user_meta_last_name"),
            userMetaWorkHours: result.getRaw("user_meta_work_hours"),
            userMetaPosition: result.getRaw("user_meta_position"),
            userMetaPositionDetail: result.getRaw("user_meta_position_detail"),
            userMetaProfileImage: result.getRaw("user_meta_image"),
            firstSubscriberProfileImage: result.getRaw("first_subscriber_profile_image"),
            userMetaVisaType: result.getRaw("user_meta_visa_type"),
            createTime: new Date(result.getRaw("create_time")),
            updateTime: new Date(result.getRaw("update_time")),
            applicantId: result.getRaw("applicant_id"),
            favourite: result.getRaw("favourite"),
            state: result.getRaw("state"),
            restaurantId: result.getRaw("restaurant_id"),
            workhourFilter: result.getRaw("workhour_filter"),
        }));
        return { data, meta: resultMap.info.meta };
    } catch (error) {
        console.log(error);
        return { data: [], meta: {} };
    }
}

export default funnelSearch;

// export function mapWorkHours(value: TypeformWorkHours | WorkhoursValue) {
//     switch (value) {
//         case TypeformWorkHours.FULL_TIME:
//         case WorkhoursValue.UNDER_40_HOURS:
//             return MappedWorkHours.FULL_TIME;
//         case TypeformWorkHours.PART_TIME:
//         case WorkhoursValue.UNDER_30_HOURS:
//         case WorkhoursValue.UNDER_20_HOURS:
//         case WorkhoursValue.UNDER_10_HOURS:
//             return MappedWorkHours.PART_TIME;
//         case TypeformWorkHours.PART_TIME_SU_MIN_10_HOURS:
//             return MappedWorkHours.PART_TIME_MIN_10_HOURS;
//         case TypeformWorkHours.PART_TIME_12_HOURS:
//             return MappedWorkHours.PART_TIME_12_HOURS;
//         case TypeformWorkHours.PART_TIME_OR_FULL_TIME:
//             return MappedWorkHours.PART_TIME_OR_FULL_TIME;
//         case TypeformWorkHours.INTERNSHIP:
//             return MappedWorkHours.INTERNSHIP;
//         case TypeformWorkHours.U18:
//             return MappedWorkHours.U18;

//         case TypeformWorkHours.UNDER_10_HOURS:
//             return MappedWorkHours.UNDER_10_HOURS;
//         case TypeformWorkHours.UNDER_20_HOURS:
//             return MappedWorkHours.UNDER_20_HOURS;
//         case TypeformWorkHours.UNDER_30_HOURS:
//             return MappedWorkHours.UNDER_30_HOURS;
//         case TypeformWorkHours.UNDER_40_HOURS:
//             return MappedWorkHours.UNDER_40_HOURS;
//         default:
//             return value;
//     }
// }
