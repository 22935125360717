import { useEffect, useState } from "react";
import { firestore } from "../firebase/configure";
import { FirestoreCollections } from "../enums/FirebaseCollections";
import { annaId } from "../config/filter";

export function useGetRestaurantGroups() {
    const [restaurantGroups, setRestaurantGroups] = useState<
        Contracts.DBRestaurantGroup[]
    >([]);

    useEffect(() => {
        const unsubscribe = firestore
            .collection(FirestoreCollections.RESTAURANT_GROUPS)
            .orderBy("title", "asc")
            .onSnapshot(
                query => {
                    const result = query.docs
                        .filter(doc => doc.id !== annaId)
                        .map(doc => {
                            return {
                                id: doc.id,
                                ...doc.data(),
                            } as Contracts.DBRestaurantGroup;
                        });
                    setRestaurantGroups(result);
                },
                error => console.error(`Failed to fetch restaurant groups. ${error}`),
            );
        return () => unsubscribe();
    }, []);

    return restaurantGroups;
}
