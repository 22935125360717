export function getFromStorage(key: string) {
    try {
        const string = localStorage.getItem(key);
        return string && JSON.parse(string);
    } catch (error) {
        console.error(error);
    }
}

export function saveToStorage(key: string, value: string) {
    try {
        const stringified = JSON.stringify(value);
        return localStorage.setItem(key, stringified);
    } catch (error) {
        console.error(error);
    }
}

export function updateStorage(storageKey: string, storageMap: any) {
    const storedMap = getFromStorage(storageKey);
    const newMap = {
        ...storageMap,
        ...storedMap,
    };
    saveToStorage(storageKey, newMap);
}

export function updateStorageList(storageKey: string, storageList: any) {
    const storedList = getFromStorage(storageKey) ? getFromStorage(storageKey) : [];
    const newList = [...storageList, ...storedList];
    saveToStorage(storageKey, JSON.stringify(newList));
}
