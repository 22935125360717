import React, { useState } from "react";
import { makeStyles, createStyles, Switch } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { FormControlLabel, Input } from "@material-ui/core";
import { toggleUserActive, updateUser } from "../../firebase/firestore";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import WarningIcon from "@material-ui/icons/Warning";
import ResetPassword from "./ResetPassword";
interface Props {
    id: string;
    isAccountSetup: boolean;
    name: string;
    email: string;
    phoneNumber: string;
    notificationToken: string | null;
    timeZone: string;
}

function EditValue({
    valueKey,
    valueToEdit,
    onSave,
}: {
    valueKey: string;
    valueToEdit: string;
    onSave: (value: string) => void;
}) {
    const [isEditing, setisEditing] = useState(false);
    const [value, setValue] = useState(valueToEdit);

    if (isEditing) {
        return (
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography variant="body1" color="textPrimary">
                    <b>{valueKey}:</b>
                </Typography>
                <Input value={value} onChange={e => setValue(e.target.value)} />
                <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    size="small"
                    onClick={() => {
                        setisEditing(false);
                        onSave(value);
                    }}>
                    <DoneIcon fontSize="small" />
                </IconButton>
                <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    size="small"
                    onClick={() => setisEditing(false)}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </div>
        );
    }
    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography variant="body1" color="textPrimary">
                <b>{valueKey}:</b> {valueToEdit || <span>No {valueKey}</span>}
            </Typography>
            <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                size="small"
                onClick={() => setisEditing(true)}>
                <EditIcon fontSize="small" />
            </IconButton>
        </div>
    );
}

export default function UserDetails({
    id,
    isAccountSetup,
    name,
    email,
    phoneNumber,
    notificationToken,
    timeZone,
}: Props) {
    const classes = useStyles();
    const hasNotificationToken = !!notificationToken;
    const [open, setOpen] = React.useState(false);
    return (
        <div style={{ padding: 24 }}>
            <EditValue
                valueKey="name"
                valueToEdit={name}
                onSave={value => updateUser(id, { name: value })}
            />
            <Typography variant="body1" color="textPrimary">
                <b>Email:</b>{" "}
                {email || <span className={classes.emptyStateText}>No email</span>}
            </Typography>
            <Typography variant="body1" color="textPrimary">
                <b>Phone:</b>{" "}
                {phoneNumber || <span className={classes.emptyStateText}>No phone</span>}
            </Typography>
            <Typography variant="body1" color="textPrimary">
                <b>Notification:</b>{" "}
                {hasNotificationToken ? (
                    "Setup"
                ) : (
                    <span className={classes.emptyStateText}>Not setup</span>
                )}
            </Typography>
            <Typography variant="body1" color="textPrimary">
                <b>Time zone:</b> {timeZone}
            </Typography>
            <FormControlLabel
                control={
                    <Switch
                        checked={isAccountSetup}
                        onChange={() => {
                            toggleUserActive(id, !isAccountSetup);
                        }}
                        name="checkedAa"
                    />
                }
                label="is Account active"
            />
            <div>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<WarningIcon />}
                    onClick={() => setOpen(true)}>
                    Reset password
                </Button>
            </div>
            <ResetPassword open={open} setOpen={setOpen} userId={id} />
        </div>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        cardContainer: {
            minHeight: 200,
        },
        emptyStateText: {
            color: "#d8d8d8",
        },
    }),
);
