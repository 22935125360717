import React from "react";
import { Controller } from "react-hook-form";
import { FormControl, Button, Select, MenuItem, TextField } from "@material-ui/core";
import * as uuid from "uuid";

import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const emptyCondition = {
    op: "is",
    vars: [
        {
            type: "field",
            value: "",
        },
        { type: "constant", value: "" },
    ],
};
const alwaysCondition = {
    op: "always",
    vars: [],
};
const emptyAction = {
    action: "jump",
    details: {
        to: {
            type: "field",
            value: "",
        },
    },
    condition: emptyCondition,
};
const alwaysActions = {
    action: "jump",
    details: {
        to: {
            type: "field",
            value: "",
        },
    },
    condition: alwaysCondition,
};

const addAction = (logicObj, customAction) => ({
    ...logicObj,
    actions: [...logicObj.actions, customAction || emptyAction],
});
const removeAction = (logicObj, actionIndex) => ({
    ...logicObj,
    actions: logicObj.actions.filter((_, i) => i !== actionIndex),
});
const updateAction = (logicObj, actionIndex, newValues) => ({
    ...logicObj,
    actions: logicObj.actions.map((action, i) =>
        i === actionIndex ? { ...action, ...newValues } : action,
    ),
});

const conditionInputTransform = condition => {
    if (condition.op === "and" || condition.op === "or") {
        return [
            ...condition.vars.reduce((acc, subCondition) => {
                if (subCondition.op === "or") {
                    return [
                        ...acc,
                        ...subCondition.vars.map(v => ({ ...v, nextOp: "or" })),
                    ];
                }

                if (subCondition.op === "and") {
                    return [
                        ...acc,
                        ...subCondition.vars.map((v, i) => ({
                            ...v,
                            nextOp: i === subCondition.vars.length - 1 ? "or" : "and",
                        })),
                    ];
                }

                return [
                    ...acc,
                    {
                        ...subCondition,
                        nextOp: condition.op,
                    },
                ];
            }, []),
        ];
    }

    return [condition];
};

const mapOrAndVars = conditionsArr => {
    const output: any[] = [];
    let index = 0;

    while (index < conditionsArr.length) {
        const indexCondition = conditionsArr[index];

        if (indexCondition.nextOp === "or") {
            output.push(indexCondition);
            index++;
        } else {
            const nextNonAndConditionIndex = conditionsArr
                .slice(index)
                .findIndex(cond => cond.nextOp !== "and");
            const andConditions = conditionsArr.slice(
                index,
                nextNonAndConditionIndex === -1
                    ? conditionsArr.length
                    : index + nextNonAndConditionIndex + 1,
            );

            if (andConditions.length >= 2) {
                output.push({
                    op: "and",
                    vars: andConditions,
                });
            } else {
                output.push(andConditions[0]);
            }

            index += andConditions.length;
        }
    }

    return output;
};

const conditionOutputTransform = conditionsArr => {
    if (conditionsArr.length === 1) {
        return conditionsArr[0];
    }

    if (conditionsArr.length >= 2) {
        const hasOr = conditionsArr.some(
            (c, i) => c.nextOp === "or" && i !== conditionsArr.length - 1,
        );
        const hasAnd = conditionsArr.some(
            (c, i) => c.nextOp === "and" && i !== conditionsArr.length - 1,
        );

        if (hasOr && hasAnd) {
            return {
                op: "or",
                vars: mapOrAndVars(conditionsArr),
            };
        }

        return {
            op: conditionsArr[0].nextOp,
            vars: conditionsArr.map(({ nextOp, ...rest }) => rest),
        };
    }

    return null;
};

function LogicField({ allFields, initialFieldData, control }) {
    return (
        <Controller
            name="logic"
            control={control}
            render={({ onChange, value }) => {
                const actions = value?.actions || [];
                const transformedActions = actions.map((action, index) => ({
                    ...action,
                    index,
                }));
                const defaultJump = transformedActions.find(
                    jump => jump.condition.op === "always",
                ) || {
                    action: "jump",
                    details: {
                        to: {
                            type: "field",
                            value: "",
                        },
                    },
                    condition: {
                        op: "always",
                        vars: [],
                    },
                };

                const jumps = transformedActions.filter(
                    jump => jump.condition.op !== "always",
                );

                const updateLogicToField = (index, newVal) => {
                    let tmpValue = value;
                    let tmpIndex = index;

                    if (tmpIndex === undefined) {
                        tmpValue = addAction(tmpValue, alwaysActions);
                        tmpIndex = tmpValue.actions.length - 1;
                    }

                    onChange(
                        updateAction(tmpValue, tmpIndex, {
                            details: {
                                to: {
                                    type: "field",
                                    value: newVal,
                                },
                            },
                        }),
                    );
                };

                const renderLogicRow = (action, index) => {
                    const isAlwaysJump = action.condition.op === "always";
                    const toFields = allFields.filter(
                        (field, i) => i !== 0 && field.ref !== initialFieldData.ref,
                    );
                    const selectedFieldIndex = allFields.findIndex(
                        f => f.ref === initialFieldData.ref,
                    );
                    const conditionFields = allFields.slice(0, selectedFieldIndex + 1);

                    const conditionArray = conditionInputTransform(action.condition);

                    console.log("inputttt???", conditionArray);

                    const updateConditionVar = (conditionIndex, varIndex, varValue) => {
                        const nextConditionArray = [...conditionArray];

                        nextConditionArray[conditionIndex] = {
                            ...nextConditionArray[conditionIndex],
                            vars: nextConditionArray[conditionIndex].vars.map((v, i) =>
                                i === varIndex ? varValue : v,
                            ),
                        };

                        onChange(
                            updateAction(value, index, {
                                condition: conditionOutputTransform(nextConditionArray),
                            }),
                        );
                    };

                    const updateConditionNextOp = (conditionIndex, nextOp) => {
                        const nextConditionArray = [...conditionArray];

                        nextConditionArray[conditionIndex] = {
                            ...nextConditionArray[conditionIndex],
                            nextOp,
                        };

                        onChange(
                            updateAction(value, index, {
                                condition: conditionOutputTransform(nextConditionArray),
                            }),
                        );
                    };

                    const addCondition = () => {
                        const nextConditionArray = [...conditionArray];

                        if (nextConditionArray.length) {
                            nextConditionArray[nextConditionArray.length - 1] = {
                                ...nextConditionArray[nextConditionArray.length - 1],
                                nextOp: "and",
                            };
                        }

                        nextConditionArray.push(emptyCondition);

                        onChange(
                            updateAction(value, index, {
                                condition: conditionOutputTransform(nextConditionArray),
                            }),
                        );
                    };

                    const removeCondition = conditionIndex => {
                        onChange(
                            updateAction(value, index, {
                                condition: conditionOutputTransform(
                                    conditionArray.filter((_, i) => i !== conditionIndex),
                                ),
                            }),
                        );
                    };

                    const removeLastCondition = () => {
                        if (conditionArray.length > 1) {
                            removeCondition(conditionArray.length - 1);
                        }
                    };

                    return (
                        <div
                            style={{
                                width: "100%",
                                ...(!isAlwaysJump
                                    ? {
                                          padding: 16,
                                          border: "1px solid lightgray",
                                          borderRadius: 8,
                                      }
                                    : {}),
                            }}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                }}>
                                <div style={{ whiteSpace: "nowrap", marginRight: 16 }}>
                                    {isAlwaysJump ? <b>Always jump to</b> : "Jump to"}
                                </div>

                                <Select
                                    fullWidth
                                    value={action?.details?.to?.value}
                                    onChange={e =>
                                        updateLogicToField(index, e.target.value)
                                    }>
                                    {toFields.map(({ ref, id }) => (
                                        <MenuItem key={id} value={ref}>
                                            {ref}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>

                            {!isAlwaysJump && (
                                <div
                                    style={{
                                        display: "flex",
                                        marginTop: 8,
                                        width: "100%",
                                    }}>
                                    <div style={{ width: 59, marginRight: 16 }}>IF</div>

                                    <div style={{ width: "100%" }}>
                                        {conditionArray.map((cond, condIndex) => {
                                            const isLast =
                                                condIndex === conditionArray.length - 1;

                                            const varField = cond.vars[0].value;
                                            const varValue = cond.vars[1].value;

                                            const varFieldObj = allFields.find(
                                                f => f.ref === varField,
                                            );
                                            const varFieldType = varFieldObj?.type;
                                            const isVarFieldSelect =
                                                varFieldType === "select" ||
                                                varFieldType === "multipleSelect" ||
                                                varFieldType === "dropdown";

                                            return (
                                                <div key={uuid.v4()}>
                                                    <Select
                                                        fullWidth
                                                        value={varField}
                                                        onChange={e =>
                                                            updateConditionVar(
                                                                condIndex,
                                                                0,
                                                                {
                                                                    type: "field",
                                                                    value: e.target.value,
                                                                },
                                                            )
                                                        }>
                                                        {conditionFields.map(
                                                            ({ ref, id }) => (
                                                                <MenuItem
                                                                    key={id}
                                                                    value={ref}>
                                                                    {ref}
                                                                </MenuItem>
                                                            ),
                                                        )}
                                                    </Select>

                                                    {cond.op}

                                                    {isVarFieldSelect ? (
                                                        <Select
                                                            fullWidth
                                                            value={varValue}
                                                            onChange={e =>
                                                                updateConditionVar(
                                                                    condIndex,
                                                                    1,
                                                                    {
                                                                        type: "choice",
                                                                        value:
                                                                            e.target
                                                                                .value,
                                                                    },
                                                                )
                                                            }>
                                                            {varFieldObj.properties.options.map(
                                                                ({ ref, label }) => (
                                                                    <MenuItem
                                                                        key={ref}
                                                                        value={ref}>
                                                                        {label}
                                                                    </MenuItem>
                                                                ),
                                                            )}
                                                        </Select>
                                                    ) : (
                                                        <TextField
                                                            fullWidth
                                                            value={varValue}
                                                            onChange={e =>
                                                                updateConditionVar(
                                                                    condIndex,
                                                                    1,
                                                                    {
                                                                        type: "constant",
                                                                        value:
                                                                            e.target
                                                                                .value,
                                                                    },
                                                                )
                                                            }
                                                        />
                                                    )}

                                                    {!isLast && (
                                                        <Select
                                                            fullWidth
                                                            value={cond.nextOp}
                                                            onChange={e =>
                                                                updateConditionNextOp(
                                                                    condIndex,
                                                                    e.target.value,
                                                                )
                                                            }>
                                                            <MenuItem value={"and"}>
                                                                And
                                                            </MenuItem>

                                                            <MenuItem value={"or"}>
                                                                Or
                                                            </MenuItem>
                                                        </Select>
                                                    )}
                                                </div>
                                            );
                                        })}

                                        <Button
                                            style={{
                                                marginTop: 16,
                                                marginRight: 16,
                                            }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => addCondition()}>
                                            <AddCircleOutlineIcon />
                                        </Button>

                                        {conditionArray.length > 1 && (
                                            <Button
                                                style={{
                                                    marginTop: 16,
                                                }}
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => removeLastCondition()}>
                                                <RemoveCircleOutlineIcon />
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                };

                return (
                    <div style={{ width: "100%" }}>
                        {jumps.map(action => (
                            <div
                                key={action.index}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 10,
                                }}>
                                {renderLogicRow(action, action.index)}

                                <Button
                                    style={{
                                        marginLeft: 16,
                                    }}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() =>
                                        onChange(removeAction(value, action.index))
                                    }>
                                    <RemoveCircleOutlineIcon />
                                </Button>
                            </div>
                        ))}

                        <Button
                            style={{
                                marginTop: 16,
                                marginBottom: 16,
                            }}
                            variant="contained"
                            color="primary"
                            onClick={() => onChange(addAction(value, emptyAction))}>
                            <AddCircleOutlineIcon />
                        </Button>

                        {renderLogicRow(defaultJump, defaultJump?.index)}
                    </div>
                );
            }}
        />
    );
}

export default LogicField;
