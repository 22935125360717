import React from "react";
import { Color } from "../../enums/Colors";
import { Grid } from "@material-ui/core";
import KpiCard from "./KpiCard";
import { useGetActiveUsers } from "../../hooks/useGetActiveUsers";
import { useGetRollingMonthlyActiveUsersReport } from "../../hooks/useGetActiveUsersReport";

export default function RollingMonthlyActiveUsers({
    restaurants,
}: {
    restaurants: Contracts.DBRestaurantShape[];
}) {
    const { activeUsers } = useGetActiveUsers();
    const { loading, lastMonthActiveUsers } = useGetRollingMonthlyActiveUsersReport();

    if (loading || !activeUsers || !lastMonthActiveUsers) {
        return (
            <>
                <Grid item md={3} xs={12}>
                    <KpiCard title="Loading..." color={Color.veryLightBorder} value={0} />
                </Grid>
                <Grid item md={3} xs={12}>
                    <KpiCard title="Loading..." color={Color.veryLightBorder} value={0} />
                </Grid>
                <Grid item md={3} xs={12}>
                    <KpiCard title="Loading..." color={Color.veryLightBorder} value={0} />
                </Grid>
                <Grid item md={3} xs={12}>
                    <KpiCard title="Loading..." color={Color.veryLightBorder} value={0} />
                </Grid>
            </>
        );
    }
    const activeRestaurants = restaurants.filter(restaurant => restaurant.isActive);
    const lastMonthActiveUsersPercentage =
        (lastMonthActiveUsers.length / activeUsers.length) * 100 || 0;

    return (
        <>
            <Grid item md={3} xs={12}>
                <KpiCard
                    title="Active Restaurants"
                    value={activeRestaurants.length}
                    footer="Restaurants that are marked active"
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <KpiCard
                    title="Total Users in Active Restaurants"
                    color={Color.danger}
                    value={activeUsers.length}
                    footer={`Total number of users in active restaurants`}
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <KpiCard
                    title="Active Users (last 30 days)"
                    color={Color.cucumber}
                    value={lastMonthActiveUsers.length}
                    footer="Users that were active in the last 30 days"
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <KpiCard
                    title="MAU %"
                    color={Color.vanilla}
                    value={lastMonthActiveUsersPercentage}
                    percentageValue
                    footer="Percentage of users that were active from active restaurants"
                />
            </Grid>
        </>
    );
}
