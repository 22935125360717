import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Badge, Grid, Paper } from "@material-ui/core";
import { People, Person } from "@material-ui/icons";
import { User } from "../../types/User";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        containerItem: {
            display: "inline-block",
            padding: 5,
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: "2px solid #000",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            maxHeight: "90vh",
            overflow: "auto",
        },
    }),
);

export default function UsersList({
    restaurant,
    members,
    users,
}: {
    restaurant?: Contracts.DBRestaurantShape;
    members?: string[];
    users: {
        [userId: string]: User;
    };
}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    if ((!restaurant && !members) || !users) return null;

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    // @ts-ignore
    const activeMembers = members ? members : Object.keys(restaurant?.members) || [];

    return (
        <div>
            <Badge
                onClick={handleOpen}
                className={classes.containerItem}
                badgeContent={activeMembers.length}
                color="primary">
                <People onClick={handleOpen} />
            </Badge>
            {activeMembers.length > 0 && (
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}>
                    <Fade in={open}>
                        <div className={classes.paper}>
                            <h2 id="transition-modal-title">
                                {restaurant?.title} Users {`(${activeMembers.length})`}
                            </h2>
                            <div id="transition-modal-description">
                                <div className={classes.root}>
                                    <Grid container spacing={1}>
                                        {activeMembers.map(userId => {
                                            return (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        textAlign: "left",
                                                    }}>
                                                    <Paper className={classes.paper}>
                                                        {users[userId]?.name ||
                                                            users[userId]?.email ||
                                                            users[userId]?.phoneNumber ||
                                                            "N/A"}
                                                    </Paper>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            )}
        </div>
    );
}
