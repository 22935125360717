import React, { useMemo } from "react";
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    makeStyles,
    Theme,
    createStyles,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EditMicroPool from "./EditMicroPool";

export function MemberItem(props: { title: string; logo: string }) {
    const { title } = props;
    return (
        <ListItem button>
            <ListItemIcon>
                <Avatar src={props?.logo}>U</Avatar>
            </ListItemIcon>
            <ListItemText primary={title} />
        </ListItem>
    );
}

interface RestaurantCardProps {
    readonly groupId: string;
    readonly groupName: string;
    readonly restaurants: Contracts.DBRestaurantShape[];
    readonly allRestaurants: Contracts.DBRestaurantShape[];
}
function MicroPoolOverview({
    groupId,
    groupName,
    restaurants,
    allRestaurants,
}: RestaurantCardProps) {
    const classes = useStyles();

    return (
        <Card key={groupName} className={classes.cardContainer}>
            {/* CARD HEAD */}
            <CardHeader
                avatar={
                    <EditMicroPool
                        groupId={groupId}
                        groupName={groupName}
                        restaurants={restaurants}
                        allRestaurants={allRestaurants}
                    />
                }
                titleTypographyProps={{ variant: "h6" }}
                title={groupName}
                subheader={
                    <p>
                        <b>Restaurants</b>: {restaurants?.length ?? 0}
                    </p>
                }
            />
            {/* CARD CONTENT */}
            <CardContent>
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    className={classes.root}>
                    {restaurants.map((restaurant, index) => (
                        <MemberItem key={index} {...restaurant} />
                    ))}
                </List>
            </CardContent>
        </Card>
    );
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContainer: {
            minHeight: 200,
        },
        headline: {
            marginTop: 10,
        },
        chips: {
            marginBottom: 6,
            marginRight: 6,
        },
        root: {
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    }),
);

export default MicroPoolOverview;
