import React from "react";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import List from "@material-ui/core/List";
import { makeStyles, Theme, createStyles, Collapse } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AppleIcon from "@material-ui/icons/Apple";
import AndroidIcon from "@material-ui/icons/Android";
import { formatDistanceToNow } from "date-fns";
import { useGetUserDevices } from "../../hooks/useGetUserDevices";
import { UserDevice } from "../../types/Device";

export default function UserDevices({ userId }: { userId: string }) {
    const devices = useGetUserDevices(userId);
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const expandClick = () => {
        setOpen(!open);
    };
    console.log({ devices });

    return (
        <div style={{ padding: 24 }}>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classes.root}>
                {devices.map((device: UserDevice) => (
                    <React.Fragment key={device.id}>
                        <ListItem button onClick={expandClick}>
                            <ListItemIcon>
                                {device?.systemName === "iOS" ? (
                                    <AppleIcon />
                                ) : (
                                    <AndroidIcon />
                                )}
                            </ListItemIcon>
                            <ListItemText primary={device?.model} />
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button className={classes.nested}>
                                    <ListItemText
                                        primary={`App version: ${device?.appVersion}`}
                                    />
                                </ListItem>
                                <ListItem button className={classes.nested}>
                                    <ListItemText
                                        primary={`Last seen: ${formatDistanceToNow(
                                            device?.lastSeen?.toDate(),
                                            {
                                                addSuffix: true,
                                            },
                                        )}`}
                                    />
                                </ListItem>
                                <ListItem button className={classes.nested}>
                                    <ListItemText
                                        primary={`isTablet: ${device?.isTablet}`}
                                    />
                                </ListItem>
                            </List>
                        </Collapse>
                    </React.Fragment>
                ))}
            </List>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContainer: {
            minHeight: 200,
        },
        root: {
            width: "100%",
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    }),
);
