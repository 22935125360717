import React from "react";
import CountUp from "react-countup";
import { Color } from "../../enums/Colors";

export default function KpiCard({
    title,
    value,
    color = Color.primaryAnanas,
    percentageValue = false,
    growth,
    growthExplainer = "(since last week)",
    footer,
    withPrecision = false,
}: {
    title: string;
    value: number;
    color?: Color;
    percentageValue?: boolean;
    growth?: number;
    growthExplainer?: string;
    footer?: string;
    withPrecision?: boolean;
}) {
    return (
        <div style={styles.card}>
            <div style={{ ...styles.cardHeader, backgroundColor: color }}>
                {withPrecision ? value?.toFixed(2) : <CountUp end={value} />}
                {percentageValue && "%"}
                {growth && growth > 0 ? (
                    <div>
                        {/* @ts-ignore */}
                        <div style={styles.growth}>
                            <div style={growth > 0 ? styles.arrowUp : styles.arrowDown} />
                            <div style={styles.growthCount}>
                                <CountUp end={growth} />
                            </div>
                        </div>
                        <div style={styles.growthExplainer}>{growthExplainer}</div>
                    </div>
                ) : null}
            </div>

            <div style={styles.cardContent}>{title}</div>
            {footer && <div style={styles.cardFooter}>{footer}</div>}
        </div>
    );
}

const styles = {
    card: {
        borderRadius: 20,
        boxShadow: "0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.08)",
    },
    cardHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        color: "#fff",
        padding: 10,
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,
        fontSize: 42,
        fontWeight: 700,
    },
    growth: {
        fontSize: 24,
        display: "flex",
        flexDirection: "row",
        marginLeft: 4,
    },
    growthCount: { alignSelf: "flex-start", marginTop: -8 },
    growthExplainer: { fontSize: 10, marginLeft: 6, marginTop: -10 },
    arrowUp: {
        width: 0,
        height: 0,
        borderLeft: "5px solid transparent",
        borderRight: "5px solid transparent",
        borderBottom: `5px solid ${Color.primary}`,
    },
    arrowDown: {
        width: 0,
        height: 0,
        borderLeft: "5px solid transparent",
        borderRight: "5px solid transparent",
        borderTop: `5px solid ${Color.danger}`,
    },
    cardContent: {
        // textAlign: "center",
        fontWeight: 700,
        padding: 10,
        borderBottomRightRadius: 20,
        borderBottomLeftRadius: 20,
        fontSize: 20,
    },
    cardFooter: {
        // textAlign: "center",
        padding: 10,
        borderBottomRightRadius: 20,
        borderBottomLeftRadius: 20,
        fontSize: 20,
    },
};
