import React from "react";
import { useGetApplicantJourney } from "../../hooks/useGetApplicantJourney";
import { useParams } from "react-router-dom";
import Chart from "react-google-charts";

const nameMap = {
    action_applied: "action_applied",
    action_reviewed: "action_reviewed",
    action_chat_message_sent: "action_chat_message_sent",
    action_chat_message_received: "action_chat_message_received",
    action_chat_message_received_email: "action_chat_message_received_email",
    action_contract_pending: "action_contract_pending",
    action_hired: "action_hired",
    action_interview_pending: "action_interview_pending",
    action_interview_canceled: "action_interview_canceled",
    action_interview_canceled_applicant: "action_interview_canceled_applicant",
    action_interview_confirmation: "action_interview_confirmation",
    action_interview_request_new_timeslots: "action_interview_request_new_timeslots",
    action_interview_rescheduled: "action_interview_rescheduled",
    action_interview_rescheduled_applicant: "action_interview_rescheduled_applicant",
    action_interview_scheduled: "action_interview_scheduled",
    action_rejected: "action_rejected",
    action_trial_pending: "action_trial_pending",
    action_trial_canceled: "action_trial_canceled",
    action_trial_canceled_applicant: "action_trial_canceled_applicant",
    action_trial_confirmation: "action_trial_confirmation",
    action_trial_request_new_timeslots: "action_trial_request_new_timeslots",
    action_trial_rescheduled: "action_trial_rescheduled",
    action_trial_rescheduled_applicant: "action_trial_rescheduled_applicant",
    action_trial_scheduled: "action_trial_scheduled",
};

function nameIt(event) {
    switch (event) {
        case nameMap.action_applied:
            return "Applied";
        case nameMap.action_reviewed:
            return "Shortlisted";
        case nameMap.action_chat_message_sent:
            return "Chat message sent";
        case nameMap.action_chat_message_received:
            return "Chat message received";
        case nameMap.action_chat_message_received_email:
            return "Chat message received";
        case nameMap.action_contract_pending:
            return "Contract sent";
        case nameMap.action_hired:
            return "Hired";
        case nameMap.action_interview_pending:
            return "Interview invite";
        case nameMap.action_interview_canceled:
            return "Interview canceled";
        case nameMap.action_interview_canceled_applicant:
            return "Interview canceled by applicant";
        case nameMap.action_interview_confirmation:
            return "Interview to be confirmed";
        case nameMap.action_interview_request_new_timeslots:
            return "Interview requested new timeslots";
        case nameMap.action_interview_rescheduled:
            return "Interview rescheduled";
        case nameMap.action_interview_rescheduled_applicant:
            return "Interview rescheduled by applicant";
        case nameMap.action_interview_scheduled:
            return "Interview scheduled";
        case nameMap.action_rejected:
            return "Rejected";
        case nameMap.action_trial_pending:
            return "Trial invite";
        case nameMap.action_trial_canceled:
            return "Trial canceled";
        case nameMap.action_trial_canceled_applicant:
            return "Trial canceled by applicant";
        case nameMap.action_trial_confirmation:
            return "Trial to be confirmed";
        case nameMap.action_trial_request_new_timeslots:
            return "Trial requested new timeslots";
        case nameMap.action_trial_rescheduled_applicant:
            return "Trial rescheduled by applicant";
        case nameMap.action_trial_rescheduled:
            return "Trial rescheduled";
        case nameMap.action_trial_scheduled:
            return "Trial scheduled";

        default:
            return event;
    }
}

export default function Applicants() {
    const params = useParams();
    console.log({ params });
    // @ts-ignore
    const { events } = useGetApplicantJourney({ funnelId: params?.funnelId });
    console.log({ events });
    const data = events
        // .filter(_, index => events[index + 1])
        .map(({ event, timestamp }, index) => [
            nameIt(event),
            nameIt(event),
            new Date(timestamp),
            new Date(events[index + 1] ? events[index + 1]?.timestamp : timestamp),
        ]);
    console.log({ data });

    return (
        <div>
            {/* <Chart
                width={1200}
                height={"300px"}
                chartType="Sankey"
                loader={<div>Loading Chart</div>}
                data={[["From", "To", "Weight"], ...data]}
                rootProps={{ "data-testid": "1" }}
            /> */}
            <Chart
                width={"100%"}
                height={"600px"}
                chartType="Timeline"
                loader={<div>Loading Chart</div>}
                data={[
                    [
                        { type: "string", id: "Term" },
                        { type: "string", id: "Name" },
                        { type: "date", id: "Start" },
                        { type: "date", id: "End" },
                    ],
                    ...data,
                ]}
                options={{
                    timeline: {
                        groupByRowLabel: false,
                    },
                }}
                rootProps={{ "data-testid": "1" }}
            />
        </div>
    );
}
