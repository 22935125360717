import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useGetUserRestaurants } from "../../hooks/useGetUserRestaurants";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SelectRestaurant from "./SelectRestaurant";
import {
    CircularProgress,
    ListItemSecondaryAction,
    Popover,
    ListItemAvatar,
    Avatar,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { addUserToRestaurant, removeUserFromRestaurant } from "../../firebase/firestore";
import DeleteIcon from "@material-ui/icons/Delete";

interface Props {
    userId: string;
    isEditing: boolean;
}

function Item({
    id,
    title,
    logo,
    isEditing = false,
    removeUser,
}: {
    id: string;
    title: string;
    logo: string;
    isEditing: boolean;
    removeUser: () => void;
}) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const popId = open ? `simple-popover-${id}` : undefined;
    return (
        <ListItem button>
            <ListItemAvatar>
                <Avatar alt="Remy Sharp" src={logo} />
            </ListItemAvatar>
            <ListItemText primary={title} />
            {isEditing && (
                <ListItemSecondaryAction>
                    <Button aria-describedby={popId} onClick={handleClick}>
                        <DeleteIcon />
                    </Button>
                </ListItemSecondaryAction>
            )}
            <Popover
                id={popId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}>
                <div style={{ margin: 20 }}>
                    <p>Are you sure you want to remove this user from {title}?</p>
                    <Button
                        aria-describedby={id}
                        variant="outlined"
                        fullWidth
                        onClick={removeUser}>
                        Yes
                    </Button>
                </div>
            </Popover>
        </ListItem>
    );
}

export default function UserRestaurants({ userId, isEditing = false }: Props) {
    const classes = useStyles();

    const userRestaurantList = useGetUserRestaurants(userId);
    const removeUser = async (restaurantId: string, userId: string) => {
        const done = await removeUserFromRestaurant(restaurantId, userId);
        if (!done) return alert("Failed to remove user from restaurant");
    };
    return (
        <div>
            <List component="nav" aria-label="secondary mailbox folders">
                {isEditing && (
                    <SelectRestaurant
                        label="Add to restaurant"
                        filter={userRestaurantList.map(({ id }) => id)}
                        userId={userId}
                    />
                )}

                {userRestaurantList?.length === 0 && (
                    <div>Not part of any restaurants</div>
                )}
                {userRestaurantList.map(({ id, title, logo }) => {
                    return (
                        <Item
                            key={id}
                            id={id}
                            title={title}
                            logo={logo}
                            isEditing={isEditing}
                            removeUser={() => removeUser(id, userId)}
                        />
                    );
                })}
            </List>
        </div>
    );
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            margin: 20,
            // maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
    }),
);
