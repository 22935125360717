export enum FieldType {
    applicantName = "applicantName",
    availability = "availability",
    date = "date",
    dropdown = "dropdown",
    email = "email",
    emailPhone = "emailPhone",
    fileUpload = "file_upload",
    hoursExpiration = "hoursExpiration",
    longText = "long_text",
    phoneNumber = "phone_number",
    select = "select",
    selectMultiple = "select_multiple",
    sharedTalentPool = "sharedTalentPool",
    shortText = "short_text",
    workExperience = "workExperience",
}

export enum SimpleFieldType {
    date = "date",
    email = "email",
    longText = "longText",
    phoneNumber = "phoneNumber",
    sharedTalentPool = "sharedTalentPool",
    text = "text",
    upload = "upload",
    workExperience = "workExperience",
}

export enum SelectFieldType {
    dropdown = "dropdown",
    select = "select",
    selectMultiple = "selectMultiple",
}

export enum FieldRef {
    application_comments = "application_comments",
    application_cv = "application_cv",
    application_email_phone_number = "application_email_phone_number",
    application_fullname = "application_fullname",
    application_motivation = "application_motivation",
    application_location = "application_location",
    application_position = "application_position",
    application_position_admin = "application_position_admin",
    application_position_boh = "application_position_boh",
    application_position_foh = "application_position_foh",
    application_profile_picture = "application_profile_picture",
    application_self_intro = "application_self_intro",
    application_shift_availability = "application_shift_availability",
    application_start_date = "application_start_date",
    application_spoken_languages = "application_spoken_languages",
    application_shared_pool_consent = "application_shared_pool_consent",
    application_terms_consent = "application_terms_consent",
    application_visa_type = "application_visa_type",
    application_visa_type_holiday = "application_visa_type_holiday",
    application_workhours = "application_workhours",
    application_work_experience = "application_work_experience",
    application_workexperience_total = "application_workexperience_total",
}

export enum FieldActionType {
    jump = "jump",
}
export enum ConditionOp {
    is = "is",
    always = "always",
}
