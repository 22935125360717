import React, { useState } from "react";
import { Theme } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles, createStyles } from "@material-ui/core";
import useSearch from "./useSearch";
import SearchResults from "./SearchResults";

export default function SearchBar() {
    const classes = useStyles();
    const [value, setvalue] = useState("");
    const { initialLoading, results } = useSearch(value);
    return (
        <div style={{ position: "relative", minWidth: 350 }}>
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    placeholder="Search atleast 3 characters.."
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                    onChange={e => setvalue(e.target.value)}
                    value={value}
                />
            </div>
            <SearchResults isOpen={value?.length > 0} results={results} />
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        color: {
            background: "#4c9999",
            boxShadow: "none",
        },
        link: {
            color: "#fff",
            padding: "8px 16px",
        },
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
            display: "none",
            [theme.breakpoints.up("sm")]: {
                display: "block",
            },
        },
        search: {
            position: "relative",
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.common.black,
            "&:hover": {
                backgroundColor: theme.palette.common.black,
            },
            marginLeft: 0,
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                marginLeft: theme.spacing(1),
                width: "auto",
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        inputRoot: {
            color: "inherit",
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create("width"),
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                width: "6ch",
                "&:focus": {
                    width: "24ch",
                },
            },
        },
    }),
);
