import { differenceInDays } from "date-fns";
import { useEffect, useState } from "react";
import { fetchData } from "../helpers/dataLoading";

export function useGetRejectedByAppliedDateTime(): {
    loading: boolean;
    averageInDays: number;
} {
    const [averageInDays, setAverageInDays] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("rejectedByAppliedDateTime");
        const events = data;
        console.log({ events });

        const diff = events.reduce(
            (acc, event) =>
                (acc += differenceInDays(
                    new Date(event.rejectTime.value),
                    new Date(event.applyTime.value),
                )),
            0,
        );
        console.log({ diff });

        const total = diff / events?.length;
        console.log(total);

        setAverageInDays(total);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, averageInDays };
}
