import { useEffect, useState } from "react";
import { firestore } from "../firebase/configure";
import { FirestoreCollections } from "../enums/FirebaseCollections";

export function useGetRestaurant(
    restaurantId: string,
): { loading: boolean; restaurant: Contracts.DBRestaurantShape } {
    const [restaurant, setRestaurant] = useState<any | null>({});
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        const unsubscribe = firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .doc(restaurantId)
            .onSnapshot(
                doc => {
                    const result = {
                        id: doc.id,
                        ...doc.data(),
                    };

                    setRestaurant(result);
                    setLoading(false);
                },
                err => {
                    console.log(err);
                },
            );
        return () => unsubscribe();
    }, []);

    return { loading, restaurant };
}
