import React, { useMemo, useState } from "react";
import { useGetUsersMap } from "../../hooks/useGetUsers";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    List,
} from "@material-ui/core";
import { useGetRestaurants } from "../../hooks/useGetRestaurants";
import RestaurantCard, { RestaurantItem } from "../../components/RestaurantCard";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import { MemberItem } from "../../components/RestaurantCard";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { Restaurant } from "@material-ui/icons";
import { RestaurantType, restaurantTypes } from "../../types/fixedTypes";
import keyBy from "lodash.keyby";
import mapValues from "lodash.mapvalues";
import { sortUsersByNameEmailPhone } from "../../helpers/sorting";

function Restaurants() {
    const userMap = useGetUsersMap();
    const restaurants = useGetRestaurants();

    const nonMembers = useMemo(
        () =>
            Object.values(userMap)
                .filter(member => !restaurants.find(r => r.members[member.id]))
                .sort(sortUsersByNameEmailPhone),
        [restaurants, userMap],
    );

    const [selectedRestaurantTypes, setSelectedRestaurantTypes] = useState<
        { [key in RestaurantType]?: boolean }
    >(mapValues(keyBy(restaurantTypes), _ => false)); // Creates an object with keys being all restaurant types and values equal to false

    const noRestaurantTypesSelected = useMemo(
        () => Object.values(selectedRestaurantTypes).every(t => !t),
        [selectedRestaurantTypes],
    );

    const restaurantsWithNoLabels = restaurants.filter(restaurant => !restaurant.labels);

    const restaurantsFilteredByType = useMemo(
        () =>
            restaurants.filter(r => {
                // If there are no types selected, show all restaurants
                if (noRestaurantTypesSelected) {
                    return true;
                }

                // Otherwise, if the restaurant has a type (labels), check if the type is selected
                if (r.labels) {
                    return selectedRestaurantTypes[r.labels.type];
                }

                // Otherwise, if the restaurant does not have a type, check if Unknown type is selected
                return selectedRestaurantTypes[RestaurantType.Unknown];
            }),
        [restaurants, selectedRestaurantTypes, noRestaurantTypesSelected],
    );

    return (
        <>
            <div style={{ textAlign: "right" }}>
                <Link to={`/restaurants/add`}>
                    <IconButton aria-label="delete" color="primary">
                        <AddCircleIcon fontSize="large" />
                    </IconButton>
                </Link>
            </div>
            {nonMembers.length > 0 && (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        id="usersNotAssigned">
                        <Typography style={{ fontWeight: 700 }}>
                            😵 Users not assigned to any restaurant ({nonMembers.length})
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List component="nav">
                            {nonMembers.map(member => (
                                <MemberItem key={member.id} {...member} />
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            )}
            <div style={{ margin: 20 }} />
            {restaurantsWithNoLabels.length > 0 && (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        id="restaurantsNotAssigned">
                        <Typography style={{ fontWeight: 700 }}>
                            <Restaurant /> Restaurants with no labels assigned (
                            {restaurantsWithNoLabels.length})
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List component="nav">
                            <p>
                                These restaurants are not categorized. Assign labels to
                                categorize the restaurants.
                            </p>
                            {restaurantsWithNoLabels.map(restaurant => (
                                <RestaurantItem
                                    key={`restaurantNotAssigned-${restaurant.id}`}
                                    {...restaurant}
                                />
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            )}

            <div style={{ margin: 20 }} />

            <Grid container justify="center">
                <FormControl component="fieldset">
                    <FormLabel component="legend" style={{ textAlign: "center" }}>
                        Filter by type
                    </FormLabel>
                    <FormGroup aria-label="type" row>
                        {restaurantTypes.map(type => (
                            <FormControlLabel
                                key={type}
                                value={type}
                                control={
                                    <Checkbox
                                        checked={selectedRestaurantTypes[type]}
                                        onChange={e =>
                                            setSelectedRestaurantTypes(types => ({
                                                ...types,
                                                [type]: e.target.checked,
                                            }))
                                        }
                                    />
                                }
                                label={type}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            </Grid>

            <div style={{ margin: 20 }} />

            <Grid container spacing={3}>
                {restaurantsFilteredByType.map(restaurant => {
                    const members = Object.keys(restaurant.members).map(
                        member => userMap[member],
                    );

                    return (
                        <Grid item md={3} xs={12}>
                            <RestaurantCard {...restaurant} members={members} />
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
}

export default Restaurants;
