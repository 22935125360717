import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { groupBy } from "lodash";
import MicroPoolOverview from "../../components/SharedTalentPool/MicroPoolOverview";
import { useGetRestaurantGroups } from "../../hooks/useGetRestaurantGroups";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            padding: 10,
        },
        gridRoot: {
            flexGrow: 1,
        },
        cardRoot: {
            minWidth: 275,
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
        badgeRoot: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        heading: {
            fontSize: theme.typography.pxToRem(20),
            fontWeight: theme.typography.fontWeightBold,
        },
        table: {
            minWidth: 300,
        },
        containerItem: {
            display: "inline-block",
            "vertical-align": "top",
            padding: 5,
        },
    }),
);

export default function MicroPoolsOverview({ restaurants }) {
    const classes = useStyles();
    const restaurantGroups: Contracts.DBRestaurantGroup[] = useGetRestaurantGroups();
    const groupedRestaurants = groupBy(
        restaurants.filter(restaurant => restaurant?.groupId),
        "groupName",
    );
    return (
        <div className={classes.root}>
            <Paper>
                <h4>Internal Groups</h4>
                {restaurantGroups.map(restaurantGroup => (
                    <div className={classes.containerItem}>
                        <MicroPoolOverview
                            groupId={restaurantGroup.id}
                            groupName={restaurantGroup.title}
                            restaurants={groupedRestaurants[restaurantGroup.title] || []}
                            allRestaurants={restaurants}
                        />
                    </div>
                ))}
            </Paper>
        </div>
    );
}
