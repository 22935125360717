import React, { useState } from "react";
import Chart from "react-google-charts";

function renderTab(tabKey: number) {
    switch (tabKey) {
        case 0:
            return <ActionChart />;

        case 1:
            return <UIChart />;

        default:
            return <ActionChart />;
    }
}

const buttonStyle = {
    border: "none",
    padding: "8px 16px",
    outline: "none",
    borderRadius: "8px 8px 0px 0px",
};

const buttonActiveStyle = {
    backgroundColor: "#f2f2f2",
};

export default function Tracking() {
    const [tabKey, setTabKey] = useState(1);

    return (
        <>
            <div style={{ borderBottom: "1px solid #333", padding: "8px 8px 0px" }}>
                <button
                    style={
                        tabKey === 0
                            ? { ...buttonStyle, ...buttonActiveStyle }
                            : buttonStyle
                    }
                    onClick={() => setTabKey(0)}>
                    Action events
                </button>
                <button
                    style={
                        tabKey === 1
                            ? { ...buttonStyle, ...buttonActiveStyle }
                            : buttonStyle
                    }
                    onClick={() => setTabKey(1)}>
                    UI events
                </button>
            </div>
            {renderTab(tabKey)}
        </>
    );
}

function ActionChart() {
    return (
        <>
            <Chart
                width={"800px"}
                height={"300px"}
                chartType="WordTree"
                loader={<div>Loading Chart</div>}
                data={[
                    ["Phrases"],
                    ["User invites applicant for interview"],
                    ["User invites applicant for trial"],
                    ["User confirms interview"],
                    ["User confirms trial"],
                    ["User reschedules interview"],
                    ["User reschedules trial"],
                    ["User cancels interview"],
                    ["User cancels trial"],
                    ["User rejects applicant"],
                    ["User hires applicant"],
                    ["User sends chat message to applicant"],
                ]}
                options={{
                    wordtree: {
                        format: "implicit",
                        word: "User",
                    },
                }}
                rootProps={{ "data-testid": "1" }}
            />
            <Chart
                width={"800px"}
                height={"300px"}
                chartType="WordTree"
                loader={<div>Loading Chart</div>}
                data={[
                    ["Phrases"],
                    ["Applicant applies"],
                    ["Applicant suggests times for interview"],
                    ["Applicant suggests times for trial"],
                    ["Applicant reschedules interview"],
                    ["Applicant reschedules trial"],
                    ["Applicant cancels interview"],
                    ["Applicant cancels trial"],
                    ["Applicant sends chat message to user"],
                ]}
                options={{
                    wordtree: {
                        format: "implicit",
                        word: "Applicant",
                    },
                }}
                rootProps={{ "data-testid": "2" }}
            />
            <Chart
                width={"800px"}
                height={"300px"}
                chartType="WordTree"
                loader={<div>Loading Chart</div>}
                data={[
                    ["Phrases"],
                    ["System marks interview as finished"],
                    ["System marks trialed as finished"],
                ]}
                options={{
                    wordtree: {
                        format: "implicit",
                        word: "System",
                    },
                }}
                rootProps={{ "data-testid": "3" }}
            />
        </>
    );
}

function UIChart() {
    return (
        <>
            <Chart
                width={"1000px"}
                height={"800px"}
                chartType="WordTree"
                loader={<div>Loading Chart</div>}
                data={[
                    ["Phrases"],
                    ["User app went inactive"],
                    ["User app went to background"],
                    ["User app went active/open"],
                    ["User clicks open push notification"],
                    ["User clicks homescreen new applicants"],
                    ["User clicks homescreen toggle snozzing on"],
                    ["User clicks homescreen toggle snozzing off"],
                    ["User clicks homescreen confirm interview"],
                    ["User clicks modal confirm interview time"],
                    ["User clicks modal request new interview time "],
                    ["User clicks homescreen confirm trial"],
                    ["User clicks modal confirm trial time"],
                    ["User clicks modal request new trial time"],
                    ["User clicks homescreen scheduled interview"],
                    ["User clicks homescreen scheduled trial"],
                    ["User clicks homescreen new messages"],
                    ["User clicks applicant list toggle filter"],
                    ["User clicks applicant list profile"],
                    ["User clicks profile chat input field"],
                    ["User clicks profile inteview invite button"],
                    ["User clicks profile inteview reschedule button"],
                    ["User clicks profile inteview cancel button"],
                    ["User clicks profile trial invite button"],
                    ["User clicks profile trial reschedule button"],
                    ["User clicks profile trial cancel button"],
                    ["User clicks profile request contract info button"],
                    ["User clicks profile cv"],
                    ["User clicks profile email"],
                    ["User clicks profile chat toggle"],
                    ["User clicks settings toggle on responsibilities"],
                    ["User clicks share apply link"],
                    ["User clicks sign out"],
                    ["User went to sign in screen"],
                    ["User went to home screen"],
                    ["User went to applicant profile screen"],
                    ["User went to applicant list screen"],
                    ["User went to team screen"],
                    ["User went to settings screen"],
                ]}
                options={{
                    wordtree: {
                        format: "implicit",
                        word: "User",
                    },
                }}
                rootProps={{ "data-testid": "4" }}
            />
        </>
    );
}
