import React, { useState } from "react";
import { useGetActivityByFunnel } from "../../hooks/useGetActivityByFunnel";
import { makeStyles, Theme, createStyles, List, Tooltip } from "@material-ui/core";
import Message from "./Message";
import TimelineTick from "./TimelineTick";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            alignSelf: "flex-end",
            width: "100%",
            // paddingBottom: 50,
            overflowY: "scroll",
            maxHeight: "90vh",
            // maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
            // padding: 0
        },
        messages: {
            alignSelf: "flex-end",
            width: "70%",
            display: "inline-block",
            // paddingBottom: 50,
            overflowY: "scroll",
            maxHeight: "90vh",
            // maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
            // padding: 0
        },
        timeline: {
            alignSelf: "flex-end",
            width: "30%",
            display: "inline-block",
            // paddingBottom: 50,
            overflowY: "scroll",
            maxHeight: "90vh",
            // maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
            // padding: 0
        },
        inline: {
            display: "inline",
        },
    }),
);

export default function ActivityFeed({
    funnelId,
    funnel,
    setTaggedFunnelMessages,
    showTimeline = true,
}: any) {
    const classes = useStyles();
    const activity = useGetActivityByFunnel(funnelId);

    console.log(activity);

    return (
        <div>
            {showTimeline && (
                <List className={classes.timeline}>
                    {activity.map((item: any) => {
                        return (
                            <TimelineTick
                                key={item.id}
                                id={item.id}
                                message={
                                    item.type === "USER_MESSAGE"
                                        ? item["USER_MESSAGE"]
                                        : item.type
                                }
                                createTime={item.createTime}
                                type={item.type}
                                authorMetadata={item.authorMetadata}
                                funnel={funnel}
                                funnelId={funnelId}
                                setTaggedFunnelMessages={setTaggedFunnelMessages}
                            />
                        );
                    })}
                </List>
            )}
            <List className={classes.messages}>
                {activity.map((item: any) => {
                    return (
                        <Message
                            key={item.id}
                            id={item.id}
                            message={
                                item.type === "USER_MESSAGE"
                                    ? item["USER_MESSAGE"]
                                    : item.type
                            }
                            createTime={item.createTime}
                            type={item.type}
                            authorMetadata={item.authorMetadata}
                            funnel={funnel}
                            funnelId={funnelId}
                            setTaggedFunnelMessages={setTaggedFunnelMessages}
                        />
                    );
                })}
            </List>
        </div>
    );
}
