import { parseISO } from "date-fns";

export const getActionStateColor = (applicationState: string): any => {
    switch (applicationState) {
        case "APPLIED":
        case "REVIEWED":
            return "default";
        case "INTERVIEW_PENDING":
        case "INTERVIEW_CONFIRMATION":
        case "INTERVIEW_SCHEDULED":
        case "INTERVIEW_CANCELED":
        case "INTERVIEWED":
            return "primary";
        case "TRIAL_PENDING":
        case "TRIAL_CONFIRMATION":
        case "TRIAL_SCHEDULED":
        case "TRIAL_CANCELED":
        case "TRIALED":
        case "HIRED":
        case "REJECTED":
            return "secondary";
        case "CONTRACT_PENDING":
        case "CONTRACT_CANCELED":
        case "CONTRACT_CONFIRMED":
            return "disabled";
    }
};

export const sanitizeMessage = (message: string): string => {
    return message.replace(/[^\x20-\x7E]/gim, "");
};

export const formatDateTime = (epochTime: number): Date => {
    let epoch = new Date(0);
    epoch.setSeconds(epochTime || 0);
    return epoch;
};

export const formatBqDate = (timestamp: string): Date | null =>
    timestamp ? parseISO(timestamp) : null;
