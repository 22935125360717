import React, { useMemo } from "react";
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    makeStyles,
    Theme,
    createStyles,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { Link } from "react-router-dom";
import { User } from "../types/User";
import { sortUsersByNameEmailPhone } from "../helpers/sorting";

export function MemberItem(props: {
    id: string;
    name: string;
    email: string;
    phoneNumber: string | null;
    profileImage: string;
}) {
    const { name, email, phoneNumber } = props;
    return (
        <Link to={`/users/${props.id}`}>
            <ListItem button>
                <ListItemIcon>
                    <Avatar src={props?.profileImage}>U</Avatar>
                </ListItemIcon>
                <ListItemText primary={name || email || phoneNumber} />
            </ListItem>
        </Link>
    );
}

export function RestaurantItem(props: { id: string; title: string; logo: string }) {
    const { id, title, logo } = props;
    return (
        <Link to={`/restaurants/${id}`}>
            <ListItem button>
                <ListItemIcon>
                    <Avatar src={logo}>R</Avatar>
                </ListItemIcon>
                <ListItemText primary={title} />
            </ListItem>
        </Link>
    );
}

interface RestaurantCardProps extends Omit<Contracts.DBRestaurantShape, "members"> {
    readonly members: User[];
}

function RestaurantCard({ id, title, logo, members, labels }: RestaurantCardProps) {
    const classes = useStyles();

    const membersSorted = useMemo(() => members.sort(sortUsersByNameEmailPhone), [
        members,
    ]);

    return (
        <Card key={id} className={classes.cardContainer}>
            {/* CARD HEAD */}
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" src={logo}>
                        U
                    </Avatar>
                }
                titleTypographyProps={{ variant: "h6" }}
                title={<Link to={`/restaurants/${id}`}>{title}</Link>}
                subheader={
                    <p>
                        <b>Type</b>: {labels?.type ?? "❓"}
                    </p>
                }
            />
            {/* CARD CONTENT */}
            <CardContent>
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    className={classes.root}>
                    {members.map((member, index) => (
                        <MemberItem key={index} {...member} />
                    ))}
                </List>
            </CardContent>
        </Card>
    );
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContainer: {
            minHeight: 200,
        },
        headline: {
            marginTop: 10,
        },
        chips: {
            marginBottom: 6,
            marginRight: 6,
        },
        root: {
            width: "100%",
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    }),
);

export default RestaurantCard;
