import { useEffect, useState } from "react";
import { fetchData } from "../helpers/dataLoading";

export function useGetAppUsageStatsReport(): any | {} {
    const [data, setData] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const data = await fetchData("monthlyAppUsageStatsReport");
        setData(data);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, data };
}
