import { PREDICTED_ACTIONS } from "../../enums/Actions";

// TODO This should be driven by a CSV file at some point
const predictionsMap: { [key in PREDICTED_ACTIONS]: Array<Array<String>> } = {
    [PREDICTED_ACTIONS.INTERVIEW_PENDING]: [
        ["interview"],
        ["a", "interview"],
        ["available"],
        ["meet", "you"],
        ["meet", "you", "at"],
        ["meet", "you", "to"],
        ["come", "by", "on"],
        ["afternoon", "at"],
        ["morning", "at"],
    ],
    // [PREDICTED_ACTIONS.INTERVIEW_CONFIRMATION]: [
    //     ["works", "for", "me"],
    //     ["absolutely"],
    //     ["sounds", "good"],
    //     ["yes"],
    // ],
    [PREDICTED_ACTIONS.TRIAL_PENDING]: [
        ["trial"],
        ["a", "trial"],
        ["meet", "you", "at"],
        ["come", "by", "on"],
        ["afternoon", "at"],
        ["morning", "at"],
    ],
    // [PREDICTED_ACTIONS.TRIAL_CONFIRMATION]: [
    //     ["works", "for", "me"],
    //     ["absolutely"],
    //     ["sounds", "good"],
    //     ["yes"],
    // ],
    [PREDICTED_ACTIONS.CONTRACT_PENDING]: [["contract"]],
};

/**
 * Helper function to build regex pattern
 *
 * Example : ^(?=.*?\meet\b)(?=.*?\you\b)(?=.*?\bto\b).*$
 *
 * Strict matcher
 * TODO Address misspellings with threshold
 *
 *
 * @param matchingWords
 */
const regexBuilder = function (matchingWords: Array<String>): RegExp {
    let regex = "";
    matchingWords.map((word: String) => {
        regex += `(?=.*?\\${word}(.!?)?\\b)`;
    });
    regex = `^${regex}.*$`;
    return new RegExp(regex, "im"); // Ignore case and match multi-line
};

/**
 * Convenience function to create a map of actions and Regex matchers
 *
 * @param matchingWordsArr
 */
const createRegexMatcher = function (
    matchingWordsArr: Array<Array<String>>,
): Array<RegExp> {
    let matchers: Array<RegExp> = [];
    matchingWordsArr.map((words: Array<String>) => {
        matchers.push(regexBuilder(words));
    });
    return matchers;
};

/**
 * Returns the list of matchers for each action
 */
export const matchers: { [key in PREDICTED_ACTIONS]: Array<RegExp> } = {
    [PREDICTED_ACTIONS.INTERVIEW_PENDING]: createRegexMatcher(
        predictionsMap[PREDICTED_ACTIONS.INTERVIEW_PENDING],
    ),
    // [PREDICTED_ACTIONS.INTERVIEW_CONFIRMATION]: createRegexMatcher(
    //     predictionsMap[PREDICTED_ACTIONS.INTERVIEW_CONFIRMATION],
    // ),
    [PREDICTED_ACTIONS.TRIAL_PENDING]: createRegexMatcher(
        predictionsMap[PREDICTED_ACTIONS.TRIAL_PENDING],
    ),
    // [PREDICTED_ACTIONS.TRIAL_CONFIRMATION]: createRegexMatcher(
    //     predictionsMap[PREDICTED_ACTIONS.TRIAL_CONFIRMATION],
    // ),
    [PREDICTED_ACTIONS.CONTRACT_PENDING]: createRegexMatcher(
        predictionsMap[PREDICTED_ACTIONS.CONTRACT_PENDING],
    ),
};
