import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, Grid, Input, Paper, Switch } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
    updateRestaurantConfig,
    updateRestaurantCategory,
    setRestaurantIsActive,
    updateRestaurantCategories,
} from "../../firebase/firestore";
import { ListItemSecondaryAction, Popover, Avatar } from "@material-ui/core";
import { Color } from "../../enums/Colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: "2px solid #000",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            maxHeight: "90vh",
            overflow: "auto",
        },
    }),
);

function Item({
    category,
    removeCategory,
}: {
    category: string;
    removeCategory: (category: string) => void;
}) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const popId = open ? `simple-popover-${category}` : undefined;
    return (
        <ListItem button>
            <ListItemText primary={category} />
            <ListItemSecondaryAction>
                <Button aria-describedby={popId} onClick={handleClick}>
                    <DeleteIcon />
                </Button>
            </ListItemSecondaryAction>
            <Popover
                id={popId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}>
                <div style={{ margin: 20 }}>
                    <p>Are you sure you want to remove {category}?</p>
                    <Button
                        aria-describedby={category}
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                            removeCategory(category);
                            handleClose();
                        }}>
                        Yes
                    </Button>
                </div>
            </Popover>
        </ListItem>
    );
}

export default function EditRestaurantConfig({
    restaurant,
}: {
    restaurant: Contracts.DBRestaurantShape;
}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [sharedPoolEnabled, setSharedPoolEnabled] = React.useState(
        Boolean(restaurant?.config?.sharedPoolEnabled),
    );
    const [isActive, setIsActive] = React.useState(Boolean(restaurant?.isActive));
    const [categories, setCategories] = React.useState<string[]>(
        restaurant?.categories || [],
    );
    const [category, setCategory] = React.useState(restaurant?.category || "");
    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCategory(event.target.value);
    };

    if (!restaurant) return null;
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setSharedPoolEnabled(event.target.checked);
        const config = {
            sharedPoolEnabled: event.target.checked,
        };
        const done = await updateRestaurantConfig({
            restaurantId: restaurant.id,
            config,
        });
        if (!done) alert("Unable to update config");
        else alert("Updated restaurant config");
    };

    const addRestaurantCategory = async () => {
        try {
            const index = categories.indexOf(category);
            if (index > -1) {
                alert(`Category ${category} has already been added`);
                return;
            }
            categories.push(category);
            setCategories(categories);
            await updateRestaurantCategories(restaurant?.id, categories);
        } catch (error) {
            alert(error);
        }
    };

    const removeRestaurantCategory = async (category: string) => {
        try {
            const index = categories.indexOf(category);
            if (index > -1) {
                categories.splice(index, 1);
                setCategories(categories);
                await updateRestaurantCategories(restaurant?.id, categories);
            }
        } catch (error) {
            alert(error);
        }
    };

    const updateActiveStatus = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsActive(event.target.checked);
        await setRestaurantIsActive({
            restaurantId: restaurant.id,
            isActive: event.target.checked,
        });
    };

    return (
        <div>
            <EditIcon onClick={handleOpen} />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">Update {restaurant.title}</h2>
                        <div id="transition-modal-description">
                            <div className={classes.root}>
                                <Grid container spacing={1}>
                                    <Grid
                                        item
                                        xs={9}
                                        style={{
                                            textAlign: "left",
                                        }}>
                                        <Paper
                                            style={{
                                                backgroundColor: isActive
                                                    ? Color.secondaryBasil
                                                    : Color.light,
                                            }}
                                            className={classes.paper}>
                                            Active Status
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Paper className={classes.paper}>
                                            <Switch
                                                checked={isActive}
                                                onChange={updateActiveStatus}
                                                color="primary"
                                                name="isActiveSwitch"
                                                inputProps={{
                                                    "aria-label": "primary checkbox",
                                                }}
                                            />
                                        </Paper>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={9}
                                        style={{
                                            textAlign: "left",
                                        }}>
                                        <Paper
                                            style={{
                                                backgroundColor: sharedPoolEnabled
                                                    ? Color.secondaryBasil
                                                    : Color.light,
                                            }}
                                            className={classes.paper}>
                                            Shared Pool Enabled
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Paper className={classes.paper}>
                                            <Switch
                                                checked={sharedPoolEnabled}
                                                onChange={handleChange}
                                                color="primary"
                                                name="sharedPoolEnabledSwitch"
                                                inputProps={{
                                                    "aria-label": "primary checkbox",
                                                }}
                                            />
                                        </Paper>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={9}
                                        style={{
                                            textAlign: "left",
                                        }}>
                                        <Paper className={classes.paper}>
                                            Category:{" "}
                                            <Input
                                                defaultValue={category}
                                                inputProps={{
                                                    "aria-label": "description",
                                                }}
                                                onChange={handleTextChange}
                                            />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Paper className={classes.paper}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={addRestaurantCategory}>
                                                Add Category
                                            </Button>
                                        </Paper>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Paper className={classes.paper}>
                                            <h5>Assigned Pool Categories</h5>
                                            <br />
                                            {categories &&
                                                categories.map((category: string) => (
                                                    <Item
                                                        category={category}
                                                        removeCategory={
                                                            removeRestaurantCategory
                                                        }
                                                    />
                                                ))}
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
