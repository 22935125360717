import { useEffect, useState } from "react";
import config from "../config";

export function useGetUserActions(userId: string): any | {} {
    const [actions, setActions] = useState<{}>({});
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const result = await fetch(`${config.API_BASE_URL}/userActions`, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({
                token: "Ejw023ohij3rhu23uhorju23ruig23rguhb2ih3gr2",
                userId,
            }),
        });
        const data = await result.json();
        console.log({ data: data[0][0] });
        setActions(data[0][0]);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, actions };
}
