import { matchers as predictionMatchers } from "./matchers";
import { sanitizeMessage } from "../utils";

/**
 * Will predict the next possible state of action based on the given inputs
 */
// TODO Add current state of the applicant at the time of passing the message
export const predictMessageAction = function (
    message: string,
    // isApplicant: Boolean,
): string {
    for (const [action, matchers] of Object.entries(predictionMatchers)) {
        // Get rid of all new line and special characters before matching
        const sanitizedMessage: string = sanitizeMessage(message);
        if (matchers.some((matcher: RegExp) => matcher.test(sanitizedMessage))) {
            console.log("***MATCH***", action);
            return action;
        } else {
            // console.log("***NO MATCH***", action, matchers, sanitizedMessage);
        }
    }
    return "";
};
