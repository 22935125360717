import { useEffect, useState } from "react";
import { fetchData } from "../helpers/dataLoading";

/**
 * Retrieves all introductions in the shared pool
 */
export function useGetSharedPoolIntroductions() {
    const [sharedPoolIntroductions, setData] = useState<
        Contracts.DBSharedPoolIntroduction[]
    >([]);
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        setLoading(true);
        const data = await fetchData("sharedPoolIntroductions");
        setData(data);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
        return () => {};
    }, []);

    return { loading, sharedPoolIntroductions };
}
