import React, { useState, Dispatch } from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, Theme, CircularProgress } from "@material-ui/core";
import { useGetRestaurants } from "../../hooks/useGetRestaurants";
import Button from "@material-ui/core/Button";
import { addUserToRestaurant } from "../../firebase/firestore";

interface Props {
    label?: string;
    userId: string;
    filter?: string[];
}

export default function SelectRestaurant({
    label = "Pick restaurant",
    userId,
    filter = [],
}: Props) {
    const classes = useStyles();
    const restaurants = useGetRestaurants();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [
        selectedRestaurant,
        setSelectedRestaurant,
    ] = useState<Contracts.DBRestaurantShape | null>(null);

    //@ts-ignore
    const handleChange = (event: any, newValue: Contracts.DBRestaurantShape) => {
        setSelectedRestaurant(newValue);
    };
    const userFiltered = restaurants
        .filter(r => !filter.includes(r.id))
        .filter(restaurant => restaurant.title);
    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <Autocomplete
                    id="restaurants-select"
                    options={userFiltered}
                    //@ts-ignore
                    value={selectedRestaurant}
                    getOptionLabel={(option: any) => option.title}
                    //@ts-ignore
                    onChange={handleChange}
                    renderInput={(params: any) => (
                        <TextField {...params} label={label} variant="outlined" />
                    )}
                />
            </FormControl>
            <Button
                variant="outlined"
                disabled={isLoading || !selectedRestaurant}
                onClick={async () => {
                    setIsLoading(true);
                    if (selectedRestaurant) {
                        const done = await addUserToRestaurant(
                            selectedRestaurant.id,
                            userId,
                        );
                        if (done) setSelectedRestaurant(null);
                    }
                    setIsLoading(false);
                }}>
                {isLoading && (
                    <CircularProgress style={{ position: "absolute" }} size={24} />
                )}
                Save
            </Button>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        margin: 0,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
