import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
    ListItemSecondaryAction,
    Popover,
    ListItemAvatar,
    Avatar,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import ChooseRestaurant from "./ChooseRestaurant";
import { updateRestaurantGroupIdAndName } from "../../firebase/firestore";

interface Props {
    groupId: string;
    groupName: string;
    restaurants: Contracts.DBRestaurantShape[];
    allRestaurants: Contracts.DBRestaurantShape[];
}

function Item({
    id,
    title,
    groupName,
    logo,
    removeRestaurant,
}: {
    id: string;
    groupName: string;
    title: string;
    logo: string;
    removeRestaurant: (id: string) => void;
}) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const popId = open ? `simple-popover-${id}` : undefined;
    return (
        <ListItem button>
            <ListItemAvatar>
                <Avatar alt="Remy Sharp" src={logo} />
            </ListItemAvatar>
            <ListItemText primary={title} />
            <ListItemSecondaryAction>
                <Button aria-describedby={popId} onClick={handleClick}>
                    <DeleteIcon />
                </Button>
            </ListItemSecondaryAction>
            <Popover
                id={popId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}>
                <div style={{ margin: 20 }}>
                    <p>
                        Are you sure you want to remove {title} from {groupName}?
                    </p>
                    <Button
                        aria-describedby={id}
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                            removeRestaurant(id);
                            handleClose();
                        }}>
                        Yes
                    </Button>
                </div>
            </Popover>
        </ListItem>
    );
}

export default function EditRestaurantsInGroup({
    groupId,
    groupName,
    restaurants,
    allRestaurants,
}: Props) {
    const classes = useStyles();

    const removeRestaurant = async (restaurantId: string) => {
        const done = await updateRestaurantGroupIdAndName({
            restaurantId,
            groupId: null,
            groupName: null,
        });
        if (!done) return alert("Failed to remove restaurant from group");
    };

    const addRestaurant = async (restaurantId: string): Promise<boolean> => {
        const done = await updateRestaurantGroupIdAndName({
            restaurantId,
            groupId,
            groupName,
        });
        if (!done) alert("Failed to remove user from restaurant");
        return Boolean(done);
    };
    return (
        <div>
            <List component="nav" aria-label="secondary mailbox folders">
                <ChooseRestaurant
                    label={`Add restaurant to ${groupName}`}
                    restaurants={allRestaurants}
                    filter={restaurants.map(({ id }) => id)}
                    onSelectRestaurant={addRestaurant}
                />

                {restaurants?.length === 0 && <div>No restaurants in {groupName}</div>}
                {restaurants.map(({ id, title, logo }) => {
                    return (
                        <Item
                            key={id}
                            id={id}
                            title={title}
                            groupName={groupName}
                            logo={logo}
                            removeRestaurant={() => removeRestaurant(id)}
                        />
                    );
                })}
            </List>
        </div>
    );
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            margin: 20,
            // maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
    }),
);
