import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Select from "@material-ui/core/Select";
import { MenuItem } from "@material-ui/core";
import { supportedLanguages } from "../../helpers/languages";
import { firestore } from "../../firebase/configure";
import { FirestoreCollections } from "../../enums/FirebaseCollections";
import { Intl } from "../../enums/Intl";

function validateForm(language: Intl) {
    if (!language) return false;
    return true;
}

export default function AddLanguageForm({ positionId, languages, handleClose }) {
    const [isLoading, setisLoading] = useState(false);
    const [language, setlanguage] = useState(Intl.en_US);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setlanguage(e.target.value as Intl);
    };

    const submitLanguage = async () => {
        setisLoading(true);
        let isValid = validateForm(language);
        if (languages.includes(language)) {
            isValid = window.confirm(
                `Are you sure you want to overwrite this language: ${language}`,
            );
        }
        if (isValid) {
            console.log(isValid, positionId, language);
            await firestore
                .collection(FirestoreCollections.POSITIONS)
                .doc(positionId)
                .set({ [language]: [] }, { merge: true });
            handleClose();
        }
        setisLoading(false);
    };
    return (
        <>
            <DialogContent>
                <DialogContentText>Adding to {positionId}</DialogContentText>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={language}
                    // @ts-ignore (weird material UI stuff here ??)
                    onChange={handleChange}>
                    {supportedLanguages.map(language => (
                        <MenuItem key={language.id} value={language.id}>
                            {language.label}
                        </MenuItem>
                    ))}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={submitLanguage} color="primary" disabled={isLoading}>
                    Add
                </Button>
            </DialogActions>
        </>
    );
}
