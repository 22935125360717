export default process.env.NODE_ENV === "production"
    ? {
          FIREBASE_APP_API_KEY: "AIzaSyD9Tc9kvOizNgEr_50A5r0QTYqk1JYZS6I",
          FIREBASE_APP_AUTH_DOMAIN: "all-anna.firebaseapp.com",
          FIREBASE_APP_DATABASE_URL:
              "https://all-anna-default-rtdb.europe-west1.firebasedatabase.app",
          FIREBASE_APP_PRODJECT_ID: "all-anna",
          FIREBASE_APP_STORAGE_BUCKET: "all-anna.appspot.com",
          FIREBASE_APP_MESSAGING_SENDER_ID: "457277308274",
          FIREBASE_APP_ID: "1:457277308274:web:68320dbab9433a866e71eb",
          API_BASE_URL: "https://europe-west1-all-anna.cloudfunctions.net",
      }
    : {
          FIREBASE_APP_API_KEY: "AIzaSyD9Tc9kvOizNgEr_50A5r0QTYqk1JYZS6I",
          FIREBASE_APP_AUTH_DOMAIN: "all-anna.firebaseapp.com",
          FIREBASE_APP_DATABASE_URL:
              "https://all-anna-default-rtdb.europe-west1.firebasedatabase.app",
          FIREBASE_APP_PRODJECT_ID: "all-anna",
          FIREBASE_APP_STORAGE_BUCKET: "all-anna.appspot.com",
          FIREBASE_APP_MESSAGING_SENDER_ID: "457277308274",
          FIREBASE_APP_ID: "1:457277308274:web:68320dbab9433a866e71eb",
          API_BASE_URL: "https://europe-west1-all-anna.cloudfunctions.net",
      };
