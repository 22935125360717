import React from "react";
import { Handle, Position } from "react-flow-renderer";
import AccountTreeIcon from "@material-ui/icons/AccountTree";

const BasicNodeAction = ({
    children,
    style,
    onClick,
}: {
    children: any;
    style?: any;
    onClick: () => void;
}) => (
    <button
        style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            bottom: 0,
            right: 0,
            height: 20,
            width: 20,
            borderWidth: 0,
            borderTopWidth: 1,
            borderLeftWidth: 1,
            borderStyle: "solid",
            boxSizing: "border-box",
            ...style,
        }}
        onClick={e => {
            e.stopPropagation();
            onClick && onClick();
        }}>
        {children}
    </button>
);

const BasicNode = ({
    data: { node, logic },
    isStaticNode,
    onAddClick,
    onDeleteClick,
    onLogicClick,
}) => {
    return (
        <React.Fragment>
            <Handle type="target" position={Position.Top} />

            <div
                style={{
                    position: "relative",
                    height: "100%",
                    width: "100%",
                    padding: 10,
                    borderRadius: 3,
                    fontSize: 12,
                    color: "#222",
                    textAlign: "center",
                    borderWidth: 1,
                    background: isStaticNode ? "rgb(239, 239, 239)" : "#fff",
                    borderStyle: "solid",
                    borderColor: "#1a192b",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}>
                {node.title || node.ref}

                {!isStaticNode && (
                    <React.Fragment>
                        <BasicNodeAction
                            style={{
                                left: 0,
                                backgroundColor: "rgb(38, 38, 39)",
                                color: "white",
                                borderWidth: 0,
                                borderRadius: 0,
                                borderRightWidth: 1,
                                borderLeftWidth: 0,
                            }}
                            onClick={() => onLogicClick(node, logic)}>
                            <AccountTreeIcon style={{ fontSize: 12 }} />
                        </BasicNodeAction>

                        <BasicNodeAction
                            style={{
                                right: 20,
                                backgroundColor: "#ff4d4f",
                                borderWidth: 0,
                                borderRadius: 0,
                            }}
                            onClick={() => onDeleteClick(node)}>
                            -
                        </BasicNodeAction>

                        <BasicNodeAction
                            style={{
                                borderRadius: 0,
                                borderBottomRightRadius: 3,
                            }}
                            onClick={() => onAddClick(node)}>
                            +
                        </BasicNodeAction>
                    </React.Fragment>
                )}
            </div>

            <Handle type="source" position={Position.Bottom} />
        </React.Fragment>
    );
};

export default BasicNode;
