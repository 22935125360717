// export enum ApplicationTypeValue {
//     Administrative = "Administrative",
//     Baker = "Baker",
//     Bakery = "Bakery",
//     Bread = "Bread",
//     EventCommunication = "Events & Communications",
//     Host = "Host",
//     Pastry = "Pastry",
//     Brewery = "Brewery",
//     Dishwasher = "Dishwasher",
//     FrontofHouse = "Front of House",
//     InternshipBoh = "Internship - Kitchen",
//     InternshipFoh = "Internship - Front of House",
//     internshipBakery = "Internship - Bakery",
//     internshipBread = "Internship - Bread",
//     internshipPastry = "Internship - Pastry",
//     Kitchen = "Kitchen",
//     CrewMember = "Crew Member",
//     Management = "Management",
//     Under18 = "U18",
//     Favourite = "favourite",
// }

// export const ApplicationTypes = {
//     administrative: [ApplicationTypeValue.Administrative],
//     boh: [
//         ApplicationTypeValue.Kitchen,
//         ApplicationTypeValue.Baker,
//         ApplicationTypeValue.Bakery,
//         ApplicationTypeValue.Dishwasher,
//     ],
//     eventCommunication: [ApplicationTypeValue.EventCommunication],
//     host: [ApplicationTypeValue.Host],
//     bakery: [ApplicationTypeValue.Bakery],
//     bread: [ApplicationTypeValue.Bread],
//     pastry: [ApplicationTypeValue.Pastry],
//     crewMember: [ApplicationTypeValue.CrewMember],
//     brewery: [ApplicationTypeValue.Brewery],
//     foh: [ApplicationTypeValue.FrontofHouse],
//     internshipBoh: [ApplicationTypeValue.InternshipBoh],
//     internshipFoh: [ApplicationTypeValue.InternshipFoh],
//     internshipBakery: [ApplicationTypeValue.internshipBakery],
//     internshipBread: [ApplicationTypeValue.internshipBread],
//     internshipPastry: [ApplicationTypeValue.internshipPastry],
//     management: [ApplicationTypeValue.Management],
//     under18: [ApplicationTypeValue.Under18],
// };

export enum BookingType {
    TRIAL_FOH = "TRIAL_FOH",
    TRIAL_UNDER_18 = "TRIAL_UNDER_18",
    TRIAL_BREWERY = "TRIAL_BREWERY",
    TRIAL_BOH = "TRIAL_BOH",
    TRIAL_EVENT_COMMUNICATION = "TRIAL_EVENT_COMMUNICATION",
    TRIAL_HOST = "TRIAL_HOST",
    TRIAL_BAKERY = "TRIAL_BAKERY",
    TRIAL_BREAD = "TRIAL_BREAD",
    TRIAL_PASTRY = "TRIAL_PASTRY",
    TRIAL_CREW_MEMBER = "TRIAL_CREW_MEMBER",
    TRIAL_MANAGEMENT = "TRIAL_MANAGEMENT",
    TRIAL_ADMINISTRATIVE = "TRIAL_ADMINISTRATIVE",
    TRIAL_INTERNSHIP_FOH = "TRIAL_INTERNSHIP_FOH",
    TRIAL_INTERNSHIP_BOH = "TRIAL_INTERNSHIP_BOH",
    TRIAL_INTERNSHIP_BAKERY = "TRIAL_INTERNSHIP_BAKERY",
    TRIAL_INTERNSHIP_BREAD = "TRIAL_INTERNSHIP_BREAD",
    TRIAL_INTERNSHIP_PASTRY = "TRIAL_INTERNSHIP_PASTRY",
    INTERVIEW = "INTERVIEW",
}

export enum RejectReason {
    SNOOZED = "SNOOZED",
    NO_FIT = "NO_FIT",
    NOT_AVAILABLE = "NOT_AVAILABLE",
    NO_SHOW = "NO_SHOW",
    NOT_INTERESTED = "NOT_INTERESTED",
}

export enum ApplicationState {
    APPLIED = "APPLIED",
    REVIEWED = "REVIEWED",

    INTERESTED = "INTERESTED", // contacted via. SMS/email from APPLIED/REVIEWED state

    INTERVIEW_PENDING = "INTERVIEW_PENDING",
    INTERVIEW_SCHEDULED = "INTERVIEW_SCHEDULED",
    INTERVIEW_CANCELED = "INTERVIEW_CANCELED",
    INTERVIEWED = "INTERVIEWED",

    TRIAL_PENDING = "TRIAL_PENDING",
    TRIAL_SCHEDULED = "TRIAL_SCHEDULED",
    TRIAL_CANCELED = "TRIAL_CANCELED",
    TRIALED = "TRIALED",

    CONTRACT_PENDING = "CONTRACT_PENDING",
    CONTRACT_CANCELED = "CONTRACT_CANCELED",
    CONTRACT_CONFIRMED = "CONTRACT_CONFIRMED",

    HIRED = "HIRED",

    REJECTED = "REJECTED",
}

export enum TypeformWorkHours {
    FULL_TIME = "Full-time (37.5h weekly)",
    PART_TIME_12_HOURS = "Part-time (max 12h)",
    PART_TIME_OR_FULL_TIME = "Part-time or Full-time",
    PART_TIME = "Part-time (15-20h weekly)",
    INTERNSHIP = "Internship",
    U18 = "U18",
}

export type Gender = "male" | "female" | null;

export enum ActivityMessageSource {
    INTERNAL = "INTERNAL",
    EXTERNAL = "EXTERNAL",
    SYSTEM = "SYSTEM",
}

export enum ActivityType {
    ACTION_APPLIED = "ACTION_APPLIED",
    ACTION_REVIEWED = "ACTION_REVIEWED",

    ACTION_INTERVIEW_PENDING = "ACTION_INTERVIEW_PENDING",
    ACTION_INTERVIEW_CONFIRMATION = "ACTION_INTERVIEW_CONFIRMATION",
    ACTION_INTERVIEW_SCHEDULED = "ACTION_INTERVIEW_SCHEDULED",
    ACTION_INTERVIEW_RESCHEDULED = "ACTION_INTERVIEW_RESCHEDULED",
    ACTION_INTERVIEW_CANCELED = "ACTION_INTERVIEW_CANCELED",
    ACTION_INTERVIEWED = "ACTION_INTERVIEWED",

    ACTION_TRIAL_PENDING = "ACTION_TRIAL_PENDING",
    ACTION_TRIAL_CONFIRMATION = "ACTION_TRIAL_CONFIRMATION",
    ACTION_TRIAL_SCHEDULED = "ACTION_TRIAL_SCHEDULED",
    ACTION_TRIAL_RESCHEDULED = "ACTION_TRIAL_RESCHEDULED",
    ACTION_TRIAL_CANCELED = "ACTION_TRIAL_CANCELED",
    ACTION_TRIALED = "ACTION_TRIALED",

    ACTION_CONTRACT_PENDING = "ACTION_CONTRACT_PENDING",
    ACTION_CONTRACT_CANCELED = "ACTION_CONTRACT_CANCELED",
    ACTION_CONTRACT_CONFIRMED = "ACTION_CONTRACT_CONFIRMED",

    ACTION_HIRED = "ACTION_HIRED",

    ACTION_REJECTED = "ACTION_REJECTED",
    ACTION_USER_MESSAGE = "ACTION_USER_MESSAGE",

    // TODO: Migrate to the above
    ACTION_HIRE = "ACTION_HIRE",
    ACTION_INTERVIEW = "ACTION_INTERVIEW",
    ACTION_TRIAL = "ACTION_TRIAL",
    ACTION_REJECT = "ACTION_REJECT",
    USER_MESSAGE = "USER_MESSAGE",
}

export enum VisaType {
    EU = "EU", // EU/DK Citizen - Unlimited
    FULL = "FULL", // Unrestricted Full Visa - 37.5 Hours
    WORK_HOLIDAY = "WORK_HOLIDAY", // Work Holiday Visa - 37.5
    RESTRICTED = "RESTRICTED", // Restricted Visa 15 Hours
    NO_VISA = "NO_VISA", // No Visa
}

export enum SchedulingType {
    Interview = "interview",
    Trial = "trial",
}

export enum AnalyticsType {
    Motivation = "motivation",
    SelfIntro = "selfIntro",
    WorkExperience = "workExperience",
}

export enum RestaurantServiceStyle {
    Table = "Table",
    Counter = "Counter",
    Both = "Table & Counter",
}

export enum RestaurantPriceLevel {
    Low = "Low",
    Medium = "Medium",
    High = "High",
}

export type RestaurantQualityLevel = 1 | 2 | 3 | 4 | 5;

export enum RestaurantType {
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    Unknown = "Unknown",
}

export const restaurantTypes = Object.keys(RestaurantType);

export enum ImageUploadType {
    RESTAURANT_LOGO = "RESTAURANT_LOGO",
}
