import { ImageUploadType } from "../types/fixedTypes";
import * as uuid from "uuid";
import imageCompression from "browser-image-compression";
import { getFileExtension } from "../helpers/files";
import { storage } from "../firebase/configure";

const TAG = "Files";

export type FileUploadSuccess = {
    readonly isSuccess: true;
    readonly url: string;
};

export type FileUploadError = {
    readonly isSuccess: false;
};

export type FileUploadResult = FileUploadSuccess | FileUploadError;

function getImagePath(type: Contracts.ImageUploadType): string {
    if (type === ImageUploadType.RESTAURANT_LOGO) {
        return `restaurants/defaults`;
    }
    throw Error("Invalid type or metadata provided.");
}

class Files {
    /**
     * Uploads a compressed image file to Firebase Storage
     * @param imageFile The image to be compressed and uploaded to Firebase Storage
     * @param type The type of the image to be uploaded
     * @param metadata The metadata to associate with the file
     */
    async uploadImageWithCompression(
        imageFile: File,
        type: Contracts.ImageUploadType,
        _metadata?: Record<string, string>,
    ): Promise<FileUploadResult> {
        try {
            const generatedFileName = uuid.v1();
            const storageRef = storage.ref(
                `${getImagePath(type)}/${generatedFileName}.${getFileExtension(
                    imageFile.name,
                )}`,
            );

            const compressedImageFile = await imageCompression(imageFile, {
                maxSizeMB: 1,
                maxWidthOrHeight: 1024,
                useWebWorker: true,
            });

            await storageRef.put(compressedImageFile, {
                contentType: "image/*",
                // customMetadata: metadata,
            });

            const downloadUrl = await storageRef.getDownloadURL();
            return { isSuccess: true, url: downloadUrl };
        } catch (error) {
            console.error(`${TAG}: Failed to upload the image file. ${error.message}`);
            return { isSuccess: false };
        }
    }

    /**
     * Removes a file from Firebase Storage
     * @param fileDownloadPath The path to the file on Firebase Storage
     */
    async remove(fileDownloadPath: string) {
        try {
            const storageRef = storage.refFromURL(fileDownloadPath);
            await storageRef.delete();
            return { isSuccess: true };
        } catch (error) {
            console.error(`${TAG}: Failed to remove the pdf file. ${error.message}`);
            return { isSuccess: false };
        }
    }
}

export default new Files();
