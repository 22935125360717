import { useEffect, useState } from "react";
import { fetchData } from "../helpers/dataLoading";

async function getHires() {
    return await fetchData("hiresPrUser");
}
async function getInterviewInvites() {
    return await fetchData("interviewInvitesPrUser");
}
async function getInterviewsScheduled() {
    return await fetchData("interviewsScheduledPrUser");
}
// async function getMessagesReceived() {
//     return await fetchData("messagesReceivedPrRestaurant");
// }
async function getMessagesSent() {
    return await fetchData("messagesSentPrUser");
}
async function getRejections() {
    return await fetchData("rejectionsPrUser");
}
async function getTrialInvites() {
    return await fetchData("trialInvitesPrUser");
}
async function getTrialsScheduled() {
    return await fetchData("trialsScheduledPrUser");
}

export function useGetUsersStats(): any | {} {
    const [stats, setStates] = useState<{}>({});
    const [loading, setLoading] = useState<boolean>(true);
    const loadData = async () => {
        const [
            hires,
            interviewInvites,
            interviewsScheduled,
            messagesSent,
            rejections,
            trialInvites,
            trialsScheduled,
        ] = await Promise.all([
            getHires(),
            getInterviewInvites(),
            getInterviewsScheduled(),
            getMessagesSent(),
            getRejections(),
            getTrialInvites(),
            getTrialsScheduled(),
        ]);

        console.log({
            hires,
            interviewInvites,
            interviewsScheduled,
            messagesSent,
            rejections,
            trialInvites,
            trialsScheduled,
        });

        setStates({
            hires,
            interviewInvites,
            interviewsScheduled,
            messagesSent,
            rejections,
            trialInvites,
            trialsScheduled,
        });

        setLoading(false);
    };
    useEffect(() => {
        if (loading) {
            loadData();
        }
        return () => {};
    }, []);

    return { loading, stats };
}
