import {
    AppBar,
    Avatar,
    Breadcrumbs,
    CircularProgress,
    createStyles,
    Link,
    makeStyles,
    Typography,
} from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Color } from "../../enums/Colors";
import { useGetRestaurant } from "../../hooks/useGetRestaurant";
import { Mosaic, MosaicWindow } from "react-mosaic-component";
import RestaurantLabels from "./RestaurantLabels";
import { useIsMobile } from "../../helpers/Responsive";
import RestaurantMembers from "./RestaurantMembers";
import { updateRestaurantLabels } from "../../firebase/firestore";
import { getRestaurantTypeForLabels } from "../../helpers/restaurant";
import { useRestaurantLogo } from "../../hooks/useRestaurantLogo";
import RestaurantPositionContainer from "./RestaurantPosition";
import RestaurantDetails from "./RestaurantDetails";

enum RestaurantView {
    Details = "Details",
    Members = "Members",
    Labels = "Labels",
    Positions = "Positions",
}

export default function Restaurant() {
    const params = useParams<{ restaurantId: string }>();
    const history = useHistory();
    const classes = useStyles();
    const isMobile = useIsMobile();

    const { loading, restaurant } = useGetRestaurant(params.restaurantId);
    const { picker, upload, logoUploading } = useRestaurantLogo(params.restaurantId);

    useEffect(() => {
        const action = async () => {
            if (picker.logo) {
                await upload(picker.logo.file, true);
            }
        };

        action();
    }, [picker.logo]);

    const views = useMemo(
        () => ({
            [RestaurantView.Labels]: (
                <RestaurantLabels
                    labels={restaurant.labels}
                    onSave={labels =>
                        updateRestaurantLabels(
                            restaurant.id,
                            labels,
                            getRestaurantTypeForLabels(labels),
                        )
                    }
                />
            ),
            [RestaurantView.Members]: <RestaurantMembers {...restaurant} />,
            [RestaurantView.Details]: <RestaurantDetails {...restaurant} />,
        }),
        [restaurant],
    );

    const viewsDirection = isMobile ? "column" : "row";

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <AppBar position="static" className={classes.appBar}>
                <Breadcrumbs>
                    <Link className={classes.back} onClick={() => history.goBack()}>
                        Overview
                    </Link>
                    <Typography className={classes.header}>
                        <div {...picker.getRootProps()} className={"dropzone"}>
                            <input
                                {...picker.getInputProps()}
                                className={classes.logoInput}
                                disabled={logoUploading}
                            />
                            <Avatar
                                className={classes.logo}
                                src={logoUploading ? undefined : restaurant.logo}>
                                <CircularProgress size={20} />
                            </Avatar>
                        </div>
                        <h4 className={classes.title}>{restaurant.title}</h4>
                    </Typography>
                </Breadcrumbs>
            </AppBar>

            <div id="restaurantGrid" className={classes.container}>
                <Mosaic<string>
                    renderTile={(id, path) => (
                        <MosaicWindow<string> path={path} title={id}>
                            {views[id]}
                        </MosaicWindow>
                    )}
                    initialValue={{
                        direction: viewsDirection,
                        first: RestaurantView.Members,
                        second: {
                            direction: viewsDirection,
                            first: RestaurantView.Labels,
                            second: RestaurantView.Details,
                            splitPercentage: 40,
                        },
                        splitPercentage: 30,
                    }}
                />
                <h1>Positions:</h1>
                <RestaurantPositionContainer />
            </div>
        </>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        back: {
            cursor: "pointer",
            color: "#f4f4f5 !important",
        },
        logo: {
            cursor: "pointer",
        },
        logoInput: {
            ":focus": {
                outline: "none",
            },
        },
        appBar: {
            padding: 10,
            backgroundColor: Color.primary,
            boxShadow: "none",
        },
        header: {
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        title: {
            margin: 0,
            marginLeft: 10,
        },
        container: {
            margin: 16,
        },
        divider: {
            margin: 20,
        },
    }),
);
