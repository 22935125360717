import React from "react";
import {
    FormControl,
    Typography,
    TextField,
    Button,
    Box,
    Select,
    InputLabel,
    MenuItem,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import * as uuid from "uuid";

const fieldTypes = [
    { key: "welcome", label: "Welcome Screen", extraFields: ["logo"] },
    { key: "applicantName", label: "Applicant Name" },
    { key: "availability", label: "Shifts and Days" },
    { key: "date", label: "Date", extraValidations: ["minToday"] },
    {
        key: "dropdown",
        label: "Dropdown",
        extraFields: ["options", "allow_other_choice"],
    },
    { key: "email", label: "Email" },
    { key: "emailPhone", label: "Email + Phone" },
    { key: "file_upload", label: "File Upload" },
    { key: "hoursExpiration", label: "Hours + Visa Expiration" },
    { key: "long_text", label: "Long Text" },
    { key: "phone_number", label: "Phone" },
    { key: "select", label: "Select", extraFields: ["options", "allow_other_choice"] },
    {
        key: "select_multiple",
        label: "Select Multiple",
        extraFields: ["options", "allow_other_choice"],
    },
    { key: "sharedTalentPool", label: "Shared Talent Pool" },
    { key: "short_text", label: "Text" },
    { key: "workExperience", label: "Work Experience" },
];

const addOption = options => [...options, { ref: uuid.v1(), label: "" }];
const removeOption = (options, refToDelete) => options.filter(o => o.ref !== refToDelete);
const updateOption = (options, refToUpdate, newValues) =>
    options.map(o =>
        o.ref === refToUpdate
            ? {
                  ...o,
                  ...newValues,
              }
            : o,
    );

function Editor({ selectedNode, isStaticNode, onFieldUpdate }) {
    const { data: initialData } = selectedNode;

    const { register, control, handleSubmit, watch, getValues } = useForm({
        defaultValues: {
            ...initialData,
        },
    });

    const watchedFieldType = watch("type");
    const compTypeConfig = React.useMemo(
        () => fieldTypes.find(f => f.key === watchedFieldType),
        [watchedFieldType],
    );

    const onSubmit = formData => onFieldUpdate(initialData.ref, formData);
    const onError = (errors, e) => console.log(errors, e);

    const submitForm = () => {
        handleSubmit(onSubmit, onError)();
    };

    return (
        <div
            onClick={e => e.stopPropagation()}
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: 16,
            }}>
            <Typography variant="h5" gutterBottom>
                Edit {initialData?.ref} Data
            </Typography>

            <Box mt={4} mb={4} width="100%">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    Field Data
                </Typography>

                {!isStaticNode && (
                    <Controller
                        name="ref"
                        control={control}
                        defaultValue=""
                        render={({ onChange, value }) => (
                            <TextField
                                fullWidth
                                label="Field Reference"
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                )}
            </Box>

            <Box mb={4} width="100%">
                <Controller
                    name="title"
                    control={control}
                    defaultValue=""
                    render={({ onChange, value }) => (
                        <TextField
                            fullWidth
                            label="Title"
                            onChange={onChange}
                            value={value}
                            multiline
                        />
                    )}
                />
            </Box>

            <Box mb={4} width="100%">
                <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({ onChange, value }) => (
                        <TextField
                            fullWidth
                            label="Description"
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Box>

            {!isStaticNode && (
                <Box mb={4} width="100%">
                    <Controller
                        name="type"
                        control={control}
                        defaultValue="short_text"
                        render={({ onChange, value }) => (
                            <FormControl fullWidth>
                                <InputLabel id="field-type-select">Field Type</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="field-type-select"
                                    value={value}
                                    onChange={onChange}>
                                    {fieldTypes.map(({ label, key }) => (
                                        <MenuItem key={key} value={key}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />
                </Box>
            )}

            {Boolean(compTypeConfig?.extraFields?.length) && (
                <Box mb={4} width="100%">
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        Component Options
                    </Typography>

                    {compTypeConfig?.extraFields?.includes("logo") && (
                        <Controller
                            name="logo"
                            control={control}
                            defaultValue={null}
                            render={({ onChange, value }) => (
                                <TextField
                                    inputMode="url"
                                    fullWidth
                                    label="Logo Url"
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                    )}

                    {compTypeConfig?.extraFields?.includes("allow_other_choice") && (
                        <Controller
                            name="properties.allow_other_choice"
                            control={control}
                            defaultValue={false}
                            render={({ onChange, value }) => (
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={value}
                                                onChange={e => onChange(e.target.checked)}
                                            />
                                        }
                                        label="Allow other/custom value"
                                    />
                                </FormControl>
                            )}
                        />
                    )}

                    {compTypeConfig?.extraFields?.includes("options") && (
                        <Controller
                            name="properties.options"
                            control={control}
                            defaultValue={[]}
                            //@ts-ignore
                            render={({ onChange, value }) => (
                                <FormControl fullWidth>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                        style={{ fontWeight: "bold" }}>
                                        Options
                                    </Typography>

                                    {value.map(opt => (
                                        <div
                                            key={opt.ref}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginBottom: 10,
                                            }}>
                                            <TextField
                                                style={{ width: "100%" }}
                                                label="Label"
                                                onChange={e =>
                                                    onChange(
                                                        updateOption(value, opt.ref, {
                                                            label: e.target.value,
                                                        }),
                                                    )
                                                }
                                                value={opt.label}
                                            />

                                            <Button
                                                style={{
                                                    marginLeft: 16,
                                                }}
                                                variant="contained"
                                                color="secondary"
                                                onClick={() =>
                                                    onChange(removeOption(value, opt.ref))
                                                }>
                                                <RemoveCircleOutlineIcon />
                                            </Button>
                                        </div>
                                    ))}

                                    <Button
                                        style={{
                                            marginTop: 16,
                                        }}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => onChange(addOption(value))}>
                                        <AddCircleOutlineIcon />
                                    </Button>
                                </FormControl>
                            )}
                        />
                    )}
                </Box>
            )}

            {!isStaticNode && (
                <Box mb={4} width="100%">
                    <Typography variant="h6" style={{ fontWeight: "bold" }} gutterBottom>
                        Validation
                    </Typography>

                    <Controller
                        name="validations.required"
                        control={control}
                        defaultValue={false}
                        render={({ onChange, value }) => (
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={value}
                                            onChange={e => onChange(e.target.checked)}
                                        />
                                    }
                                    label="required?"
                                />
                            </FormControl>
                        )}
                    />

                    {compTypeConfig?.extraValidations?.includes("minToday") && (
                        <Controller
                            name="validations.minToday"
                            control={control}
                            defaultValue={false}
                            render={({ onChange, value }) => (
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={value}
                                                onChange={e => onChange(e.target.checked)}
                                            />
                                        }
                                        label="Current date is minimum"
                                    />
                                </FormControl>
                            )}
                        />
                    )}
                </Box>
            )}

            <Button variant="contained" color="primary" onClick={submitForm}>
                UPDATE
            </Button>
        </div>
    );
}

export default Editor;
