import React, { useState, useEffect } from "react";
import { useGetFunnels } from "../../hooks/useGetFunnels";
import { auth } from "../../firebase/configure";
import SignInScreen from "../SignIn2";
import Card from "../Card";
import { Button } from "@material-ui/core";
import ActivityFeed from "./ActivityFeed";
import { getFromStorage, saveToStorage } from "../../helpers/localStorage";
import Badge from "../Badge";
import { restaurantMap } from "../../restaurantMap";

function markAsSeen(funnel: any) {
    const storedFunnelsMap = getFromStorage("funnelMap");
    const newFunnelMap = {
        ...storedFunnelsMap,
        [funnel.id]: {
            ...storedFunnelsMap[funnel.id],
            updateTime: funnel.updateTime.toDate().getTime(),
        },
    };
    saveToStorage("funnelMap", newFunnelMap);
}

export default function ChatContainer() {
    const [isAuth, setisAuth] = useState(false);
    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                setisAuth(true);
            } else {
                setisAuth(false);
            }
        });
        return () => {};
    }, []);
    if (!isAuth) {
        return <SignInScreen />;
    }
    return <Chats />;
}

function Chats() {
    const [lastDate, setLastDate] = useState(new Date());
    const funnels = useGetFunnels(lastDate);
    const [selectedFunnel, setSelectedFunnel] = useState<any>({});
    const [taggedFunnelMessages, setTaggedFunnelMessages] = useState<any>({});
    const [showTaggedMessages, setShowTaggedMessages] = useState<boolean>(false);

    const funnelMap = getFromStorage("funnelMap");
    console.log("SELECTED FUNNEL", selectedFunnel);
    if (taggedFunnelMessages.length > 0) {
        setShowTaggedMessages(true);
    }

    // make wrapper function to give child
    const wrapperSetParentState = (key: any, val: any) => {
        setTaggedFunnelMessages((prevState: any) => ({
            ...prevState,
            [key]: val,
        }));
    };

    return (
        <div>
            <div style={{ display: "flex" }}>
                <div style={{ flexDirection: "row", width: "25%" }}>
                    <div style={{ height: "90vh", overflowY: "scroll" }}>
                        {funnels &&
                            funnels.map((funnel: any) => {
                                const showBadge =
                                    !funnelMap[funnel.id] ||
                                    funnelMap[funnel.id].updateTime !==
                                        funnel.updateTime.toDate().getTime();
                                const restaurantTitle =
                                    // @ts-ignore
                                    restaurantMap[funnel.restaurantId] &&
                                    // @ts-ignore
                                    restaurantMap[funnel.restaurantId].title;
                                return (
                                    <div style={{ position: "relative" }}>
                                        <Card
                                            key={funnel.id}
                                            firstName={funnel.firstName}
                                            lastName={funnel.lastName}
                                            position={funnel.position}
                                            restaurantTitle={restaurantTitle}
                                            image={funnel.image}
                                            updateTime={funnel.updateTime}
                                            state={funnel.state}
                                            applicantId={funnel.applicantId}
                                            interviewerId={funnel.interviewerId}
                                            onClick={() => {
                                                setSelectedFunnel(funnel);
                                                markAsSeen(funnel);
                                            }}
                                            isSelected={selectedFunnel.id === funnel.id}
                                        />
                                        {showBadge && <Badge />}
                                    </div>
                                );
                            })}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                const lastFunnel = funnels[funnels.length - 1];
                                console.log(lastFunnel);

                                setLastDate(lastFunnel.updateTime.toDate());
                            }}>
                            Load more
                        </Button>
                    </div>
                </div>
                <div
                    style={{
                        flexGrow: 1,
                        width: "70%",
                        maxHeight: "100vh",
                        display: "flex",
                    }}>
                    {selectedFunnel && selectedFunnel.id ? (
                        <ActivityFeed
                            funnelId={selectedFunnel.id}
                            funnel={selectedFunnel}
                            setTaggedFunnelMessages={wrapperSetParentState}
                        />
                    ) : (
                        <div
                            style={{
                                fontSize: 60,
                                fontWeight: "bold",
                                color: "#d8d8d8",
                            }}>
                            Pick a chat!
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
