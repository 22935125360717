import { User } from "../types/User";

/**
 * Sort users by either name, email or phone
 */
export const sortUsersByNameEmailPhone = (a: User, b: User) =>
    (getUserSortField(a) ?? "").toLowerCase() > (getUserSortField(b) ?? "").toLowerCase()
        ? 1
        : -1;

const getUserSortField = (user: User) => user.name || user.email || user.phoneNumber;

export function sortUsersByName(a: User, b: User) {
    return a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : -1;
}
export function sortUsersByEmail(a: User, b: User) {
    return a?.email?.toLowerCase() > b?.email?.toLowerCase() ? 1 : -1;
}
export function sortUsersByPhone(a: User, b: User) {
    return (a?.phoneNumber ?? "").toLowerCase() > (b?.phoneNumber ?? "").toLowerCase()
        ? 1
        : -1;
}
