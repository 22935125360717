import { useEffect, useState } from "react";
import funnelSearch from "../../helpers/search";

export default function useSearch(searchTerm: string) {
    const [initialLoading, setInitialLoading] = useState(true);
    const [results, setresults] = useState<any[]>([]);

    useEffect(() => {
        try {
            if (searchTerm?.length > 3) {
                funnelSearch({
                    searchTerm,
                    //@ts-ignore
                    // filters: {},
                    page: {
                        current: 1,
                        size: 10,
                    },
                })
                    .then(({ meta, data }) => {
                        console.log(meta, data);
                        setresults(data);
                        if (initialLoading) {
                            setInitialLoading(false);
                        }
                    })
                    .catch(error => console.error(error));
            }
        } catch (error) {
            console.log(error);
        }
        return () => {};
    }, [searchTerm]);
    return { initialLoading, results };
}
