import React, { useState } from "react";
import { Card, CardHeader, CardContent, Chip } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

import List from "@material-ui/core/List";
import { useGetUserReponsibilities } from "../../hooks/useGetUserReponsibilities";
import UserPositions from "../Onboarding/UserPositions";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";

export default function UserResponsibilities({ userId }: { userId: string }) {
    const userResponsibilities = useGetUserReponsibilities(userId);
    const [isEditing, setisEditing] = useState(false);
    const classes = useStyles();

    return (
        <div>
            <div style={{ textAlign: "right" }}>
                <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={() => setisEditing(!isEditing)}>
                    {isEditing ? <DoneIcon /> : <EditIcon />}
                </IconButton>
            </div>
            <div style={isEditing ? {} : { paddingLeft: 24, paddingRight: 24 }}>
                {!isEditing ? (
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={classes.root}>
                        {userResponsibilities?.responsibilityPreference?.map(
                            (position: string) => (
                                <Chip
                                    key={position}
                                    className={classes.chips}
                                    label={position}
                                />
                            ),
                        )}
                    </List>
                ) : (
                    <UserPositions userId={userId} />
                )}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContainer: {
            minHeight: 200,
        },
        headline: {
            marginTop: 10,
        },
        chips: {
            marginBottom: 6,
            marginRight: 6,
        },
        root: {
            width: "100%",
            // backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    }),
);
