import firebase from "firebase";
import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import logo from "../assets/hand-of-anna.png";

// Configure Firebase.

const config = {
    apiKey: "AIzaSyD9Tc9kvOizNgEr_50A5r0QTYqk1JYZS6I",
    authDomain: "all-anna.firebaseapp.com",
    databaseURL: "https://all-anna-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "all-anna",
    storageBucket: "all-anna.appspot.com",
    messagingSenderId: "457277308274",
    appId: "1:457277308274:web:68320dbab9433a866e71eb",
    measurementId: "G-53770DM70T",
};

// TODO: if you want to have a separation:
// const config = {
//     apiKey: "AIzaSyAToewBaun2PPyjSuYF6A3f_uQR4rqr-04",
//     authDomain: "anna-lytics.firebaseapp.com",
//     databaseURL: "https://anna-lytics.firebaseio.com",
//     projectId: "anna-lytics",
//     storageBucket: "anna-lytics.appspot.com",
//     messagingSenderId: "280974104028",
//     appId: "1:280974104028:web:a8e71c11b392571339ab19",
//     measurementId: "G-VDRZ1Y1S6T",
//     // ...
// };

firebase.initializeApp(config);

// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // signInSuccessUrl: "/signedIn",
    callbacks: {
        signInSuccessWithAuthResult: function () {
            return false;
        },
    },
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            // customParameters: {
            //     hd: "anna.co",
            // },
        },
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ],
};

class SignInScreen extends React.Component {
    state = {
        isAuth: false,
    };
    componentDidMount() {}
    render() {
        if (!this.state.isAuth) {
            return (
                <div style={{ textAlign: "center" }}>
                    <img src={logo} className="App-logo" alt="logo" />
                    <StyledFirebaseAuth
                        uiConfig={uiConfig}
                        firebaseAuth={firebase.auth()}
                    />
                </div>
            );
        }
        return this.props.children;
    }
}
export default SignInScreen;
