import React from "react";
import UserProfile from "../../components/UserProfile";
import { useParams } from "react-router-dom";
import { useGetUser } from "../../hooks/useGetUser";

export default function User() {
    const params = useParams();
    console.log({ params });
    // @ts-ignore
    const { loading, user } = useGetUser(params.userId);
    if (loading) {
        return <div>Loading...</div>;
    }
    return <UserProfile {...user} />;
}
