export enum ACTIONS {
  action_interview_pending = "action_interview_pending",
  action_rejected = "action_rejected"
}

export enum PREDICTED_ACTIONS {
  INTERVIEW_PENDING = "INTERVIEW_PENDING",
  // INTERVIEW_CONFIRMATION = "INTERVIEW_CONFIRMATION",
  TRIAL_PENDING = "TRIAL_PENDING",
  // TRIAL_CONFIRMATION = "TRIAL_CONFIRMATION",
  CONTRACT_PENDING = "CONTRACT_PENDING"
}