import { uniqBy, sum } from "lodash";
import React from "react";
import { useGetMonthlyRejectedApplicantsReport } from "../../hooks/useGetApplicantsReport";
import LineChart from "./LineChart";

const normalizedRejectReason = {
    SNOOZED: "SNOOZED",
    BULK_REJECT: "BULK_REJECT",
    NOT_AVAILABLE: "NO_FIT",
    NO_SHOW: "NO_FIT",
    NOT_INTERESTED: "NO_FIT",
};

export default function RejectedApplicantsReport() {
    const { loading, data } = useGetMonthlyRejectedApplicantsReport();

    if (loading) return null;
    const rejectReasonTypes = [
        ...new Set(
            uniqBy(data, "meta_data_reject_reason")
                //@ts-ignore
                .map(row => normalizedRejectReason[row.meta_data_reject_reason])
                .filter(v => v)
                .sort(),
        ),
    ];
    let dataGroupedByAxis = {};
    data.map(row => {
        const axis = `${row.year}/${row.month}`;
        const rejectReason = row?.meta_data_reject_reason
            ? normalizedRejectReason[row.meta_data_reject_reason] || "NO_FIT"
            : "NO_FIT";
        dataGroupedByAxis[axis] = {
            [rejectReason]: row.applicants,
            ...(dataGroupedByAxis[axis] || {}),
        };
    });
    let chartData = [["Month", "Total Rejected"].concat(rejectReasonTypes)];
    let total = 0;
    dataGroupedByAxis &&
        Object.keys(dataGroupedByAxis)
            .sort()
            .map(axis => {
                const rejectReasonValues = rejectReasonTypes.map(
                    reason => dataGroupedByAxis[axis][reason] || 0,
                );
                const monthCount = sum(rejectReasonValues);
                total = total + monthCount;
                //@ts-ignore
                chartData.push([axis, monthCount].concat(rejectReasonValues));
            });
    return (
        <LineChart
            data={chartData}
            title={`Total Applicants Rejected till date are ${total}`}
            subtitle="Grouped by Month"
        />
    );
}
