import React from "react";
import clsx from "clsx";
import { createStyles, lighten, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useGetRestauntStats } from "../../hooks/useGetRestaurantStats";
import groupBy from "lodash.groupby";
import { Avatar } from "@material-ui/core";

interface Data {
    restaurantId: string;
    applications: number;
    hires: number;
    interviewInvites: number;
    interviewsScheduled: number;
    messagesReceived: number;
    messagesSent: number;
    rejections: number;
    trialInvites: number;
    trialsScheduled: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: "restaurantId", numeric: false, disablePadding: false, label: "Restaurant" },
    {
        id: "applications",
        numeric: true,
        disablePadding: false,
        label: "# of Applications",
    },
    { id: "hires", numeric: true, disablePadding: false, label: "# of Hires" },
    {
        id: "interviewInvites",
        numeric: true,
        disablePadding: false,
        label: "# of Interview Invites",
    },
    {
        id: "interviewsScheduled",
        numeric: true,
        disablePadding: false,
        label: "# of Interviews Scheduled",
    },
    {
        id: "messagesReceived",
        numeric: true,
        disablePadding: false,
        label: "# of Messages Received",
    },
    {
        id: "messagesSent",
        numeric: true,
        disablePadding: false,
        label: "# of Messages Sent",
    },
    { id: "rejections", numeric: true, disablePadding: false, label: "# of Rejections" },
    {
        id: "trialInvites",
        numeric: true,
        disablePadding: false,
        label: "# of Trial Invites",
    },
    {
        id: "trialsScheduled",
        numeric: true,
        disablePadding: false,
        label: "# of Trials Scheduled",
    },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property: keyof Data) => (
        event: React.MouseEvent<unknown>,
    ) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "default"}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === "light"
                ? {
                      color: theme.palette.secondary.main,
                      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                  }
                : {
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.secondary.dark,
                  },
        title: {
            flex: "1 1 100%",
        },
    }),
);

interface EnhancedTableToolbarProps {
    numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}>
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="div">
                    Restaurant actions
                </Typography>
            )}
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton aria-label="filter list">
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        paper: {
            width: "100%",
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
    }),
);

const defaultKeys = {
    applications: 0,
    hires: 0,
    interviewInvites: 0,
    interviewsScheduled: 0,
    messagesReceived: 0,
    messagesSent: 0,
    rejections: 0,
    trialInvites: 0,
    trialsScheduled: 0,
};

function formatData(stats: any) {
    const formatted = Object.keys(stats).map(key => {
        return stats[key].map(item => ({
            restaurantId: item.restaurant_id,
            [key]: item.count,
        }));
    });

    const joined = [].concat(...formatted);
    const grouped = groupBy(joined, "restaurantId");
    const data = Object.keys(grouped).map(key => ({
        restaurantId: key,
        // @ts-ignore
        ...grouped[key].reduce((acc, i) => ({ ...acc, ...i }), {}),
    }));
    return data.map(item => ({
        ...defaultKeys,
        ...item,
    }));
}
interface Props {
    restaurantMap: {} | { [key: string]: Contracts.DBRestaurantShape };
}
export default function RestaurantActions({ restaurantMap }: Props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>("desc");
    const [orderBy, setOrderBy] = React.useState<keyof Data>("applications");
    const [selected, setSelected] = React.useState<string[]>([]);
    const [dense, setDense] = React.useState(true);
    const { loading, stats } = useGetRestauntStats();

    const formattedData = formatData(stats);

    if (loading) return <div>Loading...</div>;
    const handleRequestSort = (_: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = formattedData.map(n => n.restaurantId);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                        aria-label="enhanced table">
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={formattedData.length}
                        />
                        <TableBody>
                            {/* @ts-ignore */}
                            {stableSort(formattedData, getComparator(order, orderBy)).map(
                                (row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.restaurantId}>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row">
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                    }}>
                                                    <Avatar
                                                        style={{ height: 30, width: 30 }}
                                                        src={
                                                            restaurantMap[
                                                                row.restaurantId
                                                            ]?.logo
                                                        }
                                                    />
                                                    <div style={{ marginLeft: 10 }}>
                                                        {
                                                            restaurantMap[
                                                                row.restaurantId
                                                            ]?.title
                                                        }
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.applications || 0}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.hires || 0}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.interviewInvites || 0}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.interviewsScheduled || 0}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.messagesReceived || 0}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.messagesSent || 0}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.rejections || 0}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.trialInvites || 0}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.trialsScheduled}
                                            </TableCell>
                                        </TableRow>
                                    );
                                },
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </div>
    );
}
