import React from "react";
import { Theme, createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import { getActionStateColor } from "../lib/utils";
import Typography from "@material-ui/core/Typography";
import { formatDistanceToNow } from "date-fns";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            display: "flex",
            alignItems: "center",
        },
        details: {
            display: "flex",
            flexDirection: "column",
        },
        content: {
            flex: "1 0 auto",
        },
        cover: {
            margin: 20,
            height: 80,
            width: 80,
            filter: "blur(5px)",
            borderRadius: "50%",
        },
        controls: {
            display: "flex",
            alignItems: "center",
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
    }),
);

export default function MediaControlCard({
    firstName,
    lastName,
    position,
    // image,
    restaurantTitle,
    updateTime,
    state,
    // applicantId,
    // interviewerId,
    onClick,
    isSelected,
}: any) {
    const classes = useStyles();
    const theme = useTheme();
    const time = updateTime && updateTime.toDate();

    return (
        <Card
            className={classes.card + " cardHover"}
            variant="outlined"
            style={isSelected ? { backgroundColor: "#0079FF", color: "#fff" } : {}}
            onClick={onClick}>
            {/* <CardMedia
        className={classes.cover}
        image={image}
        title="Live from space album cover"
      /> */}
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Typography component="h5" variant="h6">
                        {firstName} {lastName}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        style={
                            isSelected
                                ? { backgroundColor: "#0079FF", color: "#fff" }
                                : {}
                        }>
                        {restaurantTitle}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        style={
                            isSelected
                                ? { backgroundColor: "#0079FF", color: "#fff" }
                                : {}
                        }>
                        Position - {position}
                    </Typography>
                    <CardActions>
                        <Button
                            size="small"
                            variant="contained"
                            color={getActionStateColor(state)}>
                            {state}
                        </Button>
                        <Button size="small" color="inherit">
                            Updated {formatDistanceToNow(time, { addSuffix: true })}
                        </Button>
                    </CardActions>
                </CardContent>
            </div>
        </Card>
    );
}
