import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { usePositions } from "../../hooks/usePositions";
import { Spinner } from "react-bootstrap";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // margin: "auto",
        },
        paper: {
            width: 300,
            height: 230,
            overflow: "auto",
        },
        button: {
            margin: theme.spacing(0.5, 0.5),
        },
    }),
);

function not(a: string[], b: string[]) {
    return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a: string[], b: string[]) {
    return a.filter(value => b.indexOf(value) !== -1);
}

export default function RestaurantPositions({ right, setRight }: any) {
    const classes = useStyles();
    const { isLoading, allOptions } = usePositions();

    const [checked, setChecked] = React.useState<string[]>([]);
    const [left, setLeft] = React.useState<string[]>([]);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    useEffect(() => {
        const positionsToChooseFrom = right
            ? allOptions.filter(p => !right?.includes(p))
            : allOptions;
        setLeft(positionsToChooseFrom);
        return () => {};
    }, [isLoading]);

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const customList = (items: string[], isRight: boolean = false) => (
        <Paper className={classes.paper}>
            <div style={{ textAlign: "center" }}>
                {isRight ? "Already in use" : "To choose from"}
            </div>
            <List dense component="div" role="list">
                {items
                    .sort((a: string, b: string) =>
                        a.toLowerCase() > b.toLowerCase() ? 1 : -1,
                    )
                    .map((value: string) => {
                        const labelId = `transfer-list-item-${value}-label`;

                        return (
                            <ListItem
                                key={value}
                                role="listitem"
                                button
                                onClick={handleToggle(value)}>
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ "aria-labelledby": labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={value} />
                            </ListItem>
                        );
                    })}
                <ListItem />
            </List>
        </Paper>
    );

    if (isLoading) {
        return (
            <div>
                <Spinner animation="grow" variant="success" />
                Loading...
            </div>
        );
    }

    return (
        <Grid
            container
            spacing={2}
            justify="center"
            alignItems="center"
            className={classes.root}>
            <Grid item>{customList(left)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleAllRight}
                        disabled={left.length === 0}
                        aria-label="move all right">
                        ≫
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right">
                        &gt;
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left">
                        &lt;
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleAllLeft}
                        disabled={right.length === 0}
                        aria-label="move all left">
                        ≪
                    </Button>
                </Grid>
            </Grid>
            <Grid item>{customList(right, true)}</Grid>
        </Grid>
    );
}
