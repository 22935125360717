import React from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import Chart from "react-google-charts";
import { useGetUserActions } from "../../hooks/useGetUserActions";

interface Action {
    [action: string]: string;
}

// TODO: replace with bigquery query for user actions
const actionsDummyData: Action = {
    rejected: "618",
    interview_pending: "105",
    interview_confirmation: "0",
    interview_rescheduled: "6",
    interview_canceled: "13",
    trial_pending: "2",
    trial_confirmation: "0",
    trial_rescheduled: "0",
    trial_canceled: "0",
    hired: "0",
    chat_message_sent: "163",
};

function convertObjectTo2dArray(object: Action) {
    return Object.keys(object).map((key: string) => [key, parseInt(object[key], 10)]);
}

export default function UserActions({ userId }: { userId: string }) {
    const classes = useStyles();
    const { actions, loading } = useGetUserActions(userId);
    const actionsPie = convertObjectTo2dArray(actions);
    if (loading) {
        return <div>Loading...</div>;
    }
    return (
        <Chart
            width={"100%"}
            height={"300px"}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[["state", "number"], ...actionsPie]}
            rootProps={{ "data-testid": "1" }}
        />
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        cardContainer: {
            minHeight: 200,
        },
    }),
);
