import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            maxWidth: 360,
            backgroundColor: theme.palette.background.default,
            position: "absolute",
            maxHeight: 600,
            overflow: "scroll",
        },
        inline: {
            display: "inline",
        },
    }),
);

export default function SearchResults({
    isOpen,
    results,
}: {
    isOpen: boolean;
    results: any[];
}) {
    const classes = useStyles();
    if (!isOpen) return null;
    return (
        <List component="nav" className={classes.root} aria-label="mailbox folders">
            {results.map(r => (
                <React.Fragment key={r.id}>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={r?.userMetaProfileImage} />
                        </ListItemAvatar>
                        <ListItemText
                            style={{ color: "black" }}
                            primary={`${r?.userMetaFirstName} ${r?.userMetaLastName}`}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary">
                                        {r?.userMetaPosition} ({r?.userMetaPositionDetail}
                                        ) -{" "}
                                    </Typography>
                                    {r?.userMetaWorkHours} {" - "} {r?.userMetaVisaType}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                </React.Fragment>
            ))}
        </List>
    );
}
