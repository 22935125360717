import React from "react";
import {
    makeStyles,
    withStyles,
    useTheme,
    Theme,
    createStyles,
} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import LastPageIcon from "@material-ui/icons/LastPage";
import { Box, Collapse, Link, Typography } from "@material-ui/core";
import { Image } from "react-bootstrap";
import { mediumLocalDateAndTimeFormat } from "../../helpers/dateTime";
import EditApplicantRestaurants from "../../components/SharedTalentPool/EditApplicantRestaurants";
import { formatBqDate, formatDateTime } from "../../lib/utils";

const useStyles1 = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
        },
    }),
);

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page">
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page">
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page">
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page">
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

const useStyles2 = makeStyles({
    table: {
        width: "100%",
    },
});

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }),
)(TableRow);

function Row({
    application,
    sharedPoolApplication,
    restaurants,
    restaurantsMap,
}: {
    application: Contracts.DBUserViewedApplication;
    sharedPoolApplication: Contracts.DBSharedPoolApplication;
    restaurants: Contracts.DBRestaurantShape[];
    restaurantsMap: {
        [key: string]: Contracts.DBRestaurantShape;
    };
}) {
    const [open, setOpen] = React.useState(false);
    const [restaurantIds, setRestaurantIds] = React.useState(
        sharedPoolApplication.restaurantIds?.map(restaurantId =>
            restaurantId.replaceAll('"', ""),
        ),
    );
    const applicantRestaurants = restaurantIds?.map(
        restaurantId => restaurantsMap[restaurantId],
    );
    let workExperience = application?.workExperience;
    if (application?.workExperiences?.length > 0) {
        console.log(application?.workExperiences);
        workExperience = application?.workExperiences
            .map(data => {
                const parsedData = JSON.parse(data);
                return `${parsedData.name}/${parsedData.role}/${
                    parsedData.duration?.years || 0
                } years ${parsedData.duration?.months || 0} months`;
            })
            .join("//");
    }

    return (
        <React.Fragment>
            <StyledTableRow>
                <StyledTableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell align="center">
                    <div>
                        <Image
                            width={64}
                            height={64}
                            src={application?.profilePicture}
                            roundedCircle
                        />
                    </div>
                    <div>
                        {application?.firstName}
                        {", "}
                        &nbsp;
                        {application?.lastName}
                    </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                    {sharedPoolApplication.rejectedByTitle}
                </StyledTableCell>
                <StyledTableCell align="center">
                    {sharedPoolApplication.rejectedByUserName}
                </StyledTableCell>
                <StyledTableCell align="right">
                    {applicantRestaurants.map(restaurant => restaurant?.title).join("/")}
                </StyledTableCell>
                <StyledTableCell align="right">
                    {mediumLocalDateAndTimeFormat(
                        formatDateTime(application?.createTime),
                    )}
                </StyledTableCell>
                <StyledTableCell align="right">
                    {mediumLocalDateAndTimeFormat(
                        formatBqDate(sharedPoolApplication.createTime?.value) ||
                            new Date(),
                    )}
                </StyledTableCell>
                <StyledTableCell align="right">
                    {application?.position} /
                    {application?.positionBoh || application?.positionFoh} /
                    {application?.workHours}
                </StyledTableCell>
                <StyledTableCell align="right">
                    {application?.workExperienceTotal}
                </StyledTableCell>
                <StyledTableCell align="right">{workExperience}</StyledTableCell>
                <StyledTableCell align="right">
                    {application?.languages
                        ?.map(lang => lang.replaceAll('"', ""))
                        .join(",")}
                </StyledTableCell>
                <StyledTableCell align="right">{application?.visaType}</StyledTableCell>
                <StyledTableCell align="right">
                    {(sharedPoolApplication.score || 0) * 100}
                </StyledTableCell>
                <StyledTableCell align="right">
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                            window.open(application?.cv, "_blank");
                        }}>
                        Download
                    </Link>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                    <EditApplicantRestaurants
                        restaurants={restaurants}
                        sharedPoolApplication={sharedPoolApplication}
                        setUpdatedRestaurantIds={setRestaurantIds}
                    />
                </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                More Info
                            </Typography>
                            <Table aria-label="applicant">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Email</StyledTableCell>
                                        <StyledTableCell>Self Intro</StyledTableCell>
                                        <StyledTableCell>Motivation</StyledTableCell>
                                        {application?.location && (
                                            <StyledTableCell>Location</StyledTableCell>
                                        )}
                                        {application?.wineKnowledge && (
                                            <StyledTableCell>
                                                Wine Knowledge
                                            </StyledTableCell>
                                        )}
                                        {application?.coffeeKnowledge && (
                                            <StyledTableCell>
                                                Coffee Knowledge
                                            </StyledTableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell align="right">
                                            {application?.email}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {application?.selfIntro}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {application?.motivation}
                                        </StyledTableCell>
                                        {application?.location && (
                                            <StyledTableCell align="right">
                                                {application?.location}
                                            </StyledTableCell>
                                        )}
                                        {application?.wineKnowledge && (
                                            <StyledTableCell align="right">
                                                {application?.wineKnowledge}
                                            </StyledTableCell>
                                        )}
                                        {application?.coffeeKnowledge && (
                                            <StyledTableCell align="right">
                                                {application?.coffeeKnowledge}
                                            </StyledTableCell>
                                        )}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </StyledTableCell>
            </StyledTableRow>
        </React.Fragment>
    );
}
export default function ApplicantsTable({
    restaurants,
    restaurantsMap,
    applicationsMap,
    sharedPoolApplications,
}) {
    const classes = useStyles2();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    if (sharedPoolApplications?.length <= 0) return <>No data...</>;
    const emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, sharedPoolApplications.length - page * rowsPerPage);

    const handleChangePage = (
        // @ts-ignore
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} stickyHeader aria-label="Shared Talent Pool">
                <TableHead>
                    <TableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>Applicant</StyledTableCell>
                        <StyledTableCell align="right">Applied To</StyledTableCell>
                        <StyledTableCell align="right">Rejected By</StyledTableCell>
                        <StyledTableCell align="right">Available for</StyledTableCell>
                        <StyledTableCell align="right">Applied Date</StyledTableCell>
                        <StyledTableCell align="right">Pool Entry</StyledTableCell>
                        <StyledTableCell align="right">Position Details</StyledTableCell>
                        <StyledTableCell align="right">Total Experience</StyledTableCell>
                        <StyledTableCell align="right">Work Experience</StyledTableCell>
                        <StyledTableCell align="right">Languages</StyledTableCell>
                        <StyledTableCell align="right">Visa&nbsp;Type</StyledTableCell>
                        <StyledTableCell align="right">
                            Score&nbsp;(Max. 100)
                        </StyledTableCell>
                        <StyledTableCell>CV</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? sharedPoolApplications.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage,
                          )
                        : sharedPoolApplications
                    ).map(sharedPoolApplication => (
                        <Row
                            key={sharedPoolApplication.id}
                            application={
                                applicationsMap[sharedPoolApplication.applicationId]
                            }
                            restaurants={restaurants}
                            restaurantsMap={restaurantsMap}
                            sharedPoolApplication={sharedPoolApplication}></Row>
                    ))}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <StyledTableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                            colSpan={3}
                            count={sharedPoolApplications.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { "aria-label": "rows per page" },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}
