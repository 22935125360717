import React, { useState } from "react";

import UserRestaurants from "../Onboarding/UserRestaurants";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";

export default function UserRestaurantsWidget({ userId }: { userId: string }) {
    const [isEditing, setisEditing] = useState(false);
    return (
        <div style={{ padding: 0 }}>
            <div style={{ textAlign: "right" }}>
                <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={() => setisEditing(!isEditing)}>
                    {isEditing ? <DoneIcon /> : <EditIcon />}
                </IconButton>
            </div>
            <UserRestaurants isEditing={isEditing} userId={userId} />
        </div>
    );
}
