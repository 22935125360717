import { useEffect, useState } from "react";
import { firestore } from "../firebase/configure";
import { FirestoreCollections } from "../enums/FirebaseCollections";
import keyBy from "lodash.keyby";
import { AnalyticsType } from "../types/fixedTypes";
import { ExcludedRestaurants, TestRestaurants } from "../config/filter";

/**
 *
 * Added the firebase logic like this untill
 * we have a better way to abstract it with sagas for similar
 * Just to keep it outside of the component
 *
 */

export function useGetRestaurants(
    filterTestRestaurants: boolean = false,
): With.Id<Contracts.DBRestaurantShape>[] {
    const [restaurants, setRestaurants] = useState<any | null>([]);
    useEffect(() => {
        const unsubscribe = firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .orderBy("title", "asc")
            .onSnapshot(
                query => {
                    const result = query.docs
                        .filter(
                            doc =>
                                !ExcludedRestaurants.includes(doc.id) &&
                                (filterTestRestaurants
                                    ? !TestRestaurants.includes(doc.id)
                                    : true),
                        )
                        .map(doc => {
                            return {
                                id: doc.id,
                                ...doc.data(),
                            };
                        });
                    console.log({ result });
                    setRestaurants(result);
                },
                err => {
                    console.log(err);
                },
            );
        return () => unsubscribe();
    }, []);
    return restaurants;
}

export function useGetRestaurantMotivationKeywords(
    restaurantId: string,
): { motivationKeywords: With.Id<Contracts.DBRestaurantKeywords> } {
    const [motivationKeywords, setMotivationKeywords] = useState<any | null>({});
    useEffect(() => {
        if (!restaurantId) {
            return () => {};
        }
        const unsubscribe = firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .doc(restaurantId)
            .collection(FirestoreCollections.ANALYTICS)
            .doc(AnalyticsType.Motivation)
            .onSnapshot(
                doc => {
                    const result = {
                        id: doc.id,
                        ...doc.data(),
                    };
                    setMotivationKeywords(result);
                },
                err => {
                    console.log(err);
                },
            );
        return () => unsubscribe();
    }, [restaurantId]);

    return { motivationKeywords };
}

export function useGetRestaurantSelfIntroKeywords(
    restaurantId: string,
): { selfIntroKeywords: With.Id<Contracts.DBRestaurantKeywords> } {
    const [selfIntroKeywords, setSelfIntroKeywords] = useState<any | null>({});
    useEffect(() => {
        if (!restaurantId) {
            return () => {};
        }
        const unsubscribe = firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .doc(restaurantId)
            .collection(FirestoreCollections.ANALYTICS)
            .doc(AnalyticsType.SelfIntro)
            .onSnapshot(
                doc => {
                    const result = {
                        id: doc.id,
                        ...doc.data(),
                    };
                    setSelfIntroKeywords(result);
                },
                err => {
                    console.log(err);
                },
            );
        return () => unsubscribe();
    }, [restaurantId]);

    return { selfIntroKeywords };
}

export function useGetRestaurantWorkExperienceKeywords(
    restaurantId: string,
): { workExperienceKeywords: With.Id<Contracts.DBRestaurantKeywords> } {
    const [workExperienceKeywords, setWorkExperienceKeywords] = useState<any | null>({});
    useEffect(() => {
        if (!restaurantId) {
            return () => {};
        }
        const unsubscribe = firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .doc(restaurantId)
            .collection(FirestoreCollections.ANALYTICS)
            .doc(AnalyticsType.WorkExperience)
            .onSnapshot(
                doc => {
                    const result = {
                        id: doc.id,
                        ...doc.data(),
                    };
                    setWorkExperienceKeywords(result);
                },
                err => {
                    console.log(err);
                },
            );
        return () => unsubscribe();
    }, [restaurantId]);

    return { workExperienceKeywords };
}

export function useGetRestaurantsMap(filterTestRestaurants: boolean = false) {
    const [restaurants, setRestaurants] = useState<
        {} | { [key: string]: Contracts.DBRestaurantShape }
    >({});
    useEffect(() => {
        const unsubscribe = firestore
            .collection(FirestoreCollections.RESTAURANTS)
            .orderBy("title", "asc")
            .onSnapshot(
                query => {
                    if (!query.empty && query.docs.length > 0) {
                        const result = query.docs
                            .filter(
                                doc =>
                                    !ExcludedRestaurants.includes(doc.id) &&
                                    (filterTestRestaurants
                                        ? !TestRestaurants.includes(doc.id)
                                        : true),
                            )
                            .map(doc => {
                                return {
                                    id: doc.id,
                                    ...doc.data(),
                                };
                            });
                        const restaurantMap = keyBy(result, "id");
                        setRestaurants(restaurantMap);
                    }
                },
                err => {
                    console.log(err);
                },
            );
        return () => unsubscribe();
    }, []);
    return restaurants;
}
