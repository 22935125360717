import React from "react";
import { useGetUsers } from "../../hooks/useGetUsers";
import { MemberItem } from "../../components/RestaurantCard";
import { List, ListSubheader } from "@material-ui/core";
import {
    sortUsersByEmail,
    sortUsersByName,
    sortUsersByPhone,
} from "../../helpers/sorting";
import { User } from "../../types/User";

function filterName(user: User) {
    return !!user.name;
}

function filterEmail(user: User) {
    return !!user.email && !user.name && !user.phoneNumber;
}

function filterPhone(user: User) {
    return !!user.phoneNumber && !user.email && !user.name;
}

export default function Users() {
    const users = useGetUsers();

    return (
        <>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader
                        component="div"
                        id="nested-list-subheader"
                        style={{ backgroundColor: "#fff" }}>
                        Name
                    </ListSubheader>
                }>
                {users
                    ?.filter(filterName)
                    .sort(sortUsersByName)
                    .map((user: any, index) => (
                        <MemberItem key={index} {...user} />
                    ))}
            </List>

            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader
                        component="div"
                        id="nested-list-subheader"
                        style={{ backgroundColor: "#fff" }}>
                        Email
                    </ListSubheader>
                }>
                {users
                    ?.filter(filterEmail)
                    .sort(sortUsersByEmail)
                    .map((user: any, index) => (
                        <MemberItem key={index} {...user} />
                    ))}
            </List>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader
                        component="div"
                        id="nested-list-subheader"
                        style={{ backgroundColor: "#fff" }}>
                        Phone
                    </ListSubheader>
                }>
                {users
                    ?.filter(filterPhone)
                    .sort(sortUsersByPhone)
                    .map((user: any, index) => (
                        <MemberItem key={index} {...user} />
                    ))}
            </List>
        </>
    );
}
