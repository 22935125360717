import React from "react";
import { Positions } from "../../types/Positions";
import { useRestaurantPositions } from "../../hooks/useRestaurantPositions";
import { useParams } from "react-router-dom";
import RestaurantPositions from "../../components/Onboarding/RestaurantPositions";
import Button from "@material-ui/core/Button";

function compareArrays(a1: string[], a2: string[]) {
    const a1Filtered = a1.filter((i: string) => !a2.includes(i));
    const a2Filtered = a2.filter((i: string) => !a1.includes(i));
    console.log({ a1Filtered, a2Filtered });

    return a1Filtered.length === 0 && a2Filtered.length === 0;
}

function RestaurantPositionList({
    positions,
    updatePositions,
}: {
    positions: Positions[];
    updatePositions: (positions: Positions[]) => void;
}) {
    const [values, setValues] = React.useState<Positions[]>(positions);
    console.log({ positions, values, diff: compareArrays(positions, values) });

    return (
        <>
            <div style={{ float: "right" }}>
                <Button
                    size="large"
                    variant="contained"
                    color={"primary"}
                    disabled={compareArrays(values, positions)}
                    onClick={() => updatePositions(values)}>
                    Save
                </Button>
            </div>
            <div>
                <RestaurantPositions
                    right={values}
                    setRight={(updatedPositions: Positions[]) =>
                        setValues(updatedPositions)
                    }
                />
            </div>
        </>
    );
}
export default function RestaurantPositionContainer() {
    const { restaurantId } = useParams<{ restaurantId: string }>();

    const { isLoading, restaurantPositions, updatePositions } = useRestaurantPositions(
        restaurantId,
    );

    if (isLoading) return <div>Loading...</div>;
    return (
        <div>
            <RestaurantPositionList
                positions={restaurantPositions}
                updatePositions={updatePositions}
            />
        </div>
    );
}
